import './home.scss';

import React, { useEffect } from 'react';

import { getEarlyBirdUrl, REDIRECT_URL } from 'app/shared/util/url-utils';
import { useAppSelector } from 'app/config/store';

export const Arbeitnehmer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const redirectURL = localStorage.getItem(REDIRECT_URL);
    if (redirectURL) {
      localStorage.removeItem(REDIRECT_URL);
      location.href = `${location.origin}${redirectURL}`;
    }
  });

  return (
    <div className="body-with-image">
      <div className="section-hero-std non-landing">
        <div className="padding-section-30"></div>
        <div className="container-hero-std centered w-container">
          <h1 className="heading-float-1">Jetzt kostenlos anmelden und attraktive Jobangebote erhalten</h1>
          <div className="padding-10"></div>
          <a href={getEarlyBirdUrl()} className="call-to-action-max w-button">
            Jetzt Profil anlegen
          </a>
        </div>
        <div className="padding-section-30"></div>
      </div>
      <div className="section-plain-white">
        <div className="padding-section-30"></div>
        <h2 className="heading-3">
          Es herrscht Fachkräftemangel und Ihre Qualifikationen sind extrem Gefragt <br />
        </h2>
        <div className="container-std w-container">
          <h1 className="heading-1">
            <strong></strong>Das müssen Sie nutzen
            <br />
          </h1>
          <div className="w-layout-grid grid-std">
            <div id="w-node-_3f00c53a-5245-388d-4941-4e5c8fda82b9-1e49818a" className="div-block-grid-std backround-green">
              <h2 className="heading-3-cc white">Gehalt erhöhen</h2>
              <img
                src="../../../content/images/Money_1Money.webp"
                loading="lazy"
                width="72"
                alt="Ein Symbol für eine Gehaltssteigerung, dargestellt durch einen Geldsack mit einem Euro-Symbol. Das Symbol symbolisiert eine Steigerung des Gehalts oder eine finanzielle Verbesserung."
                className="image-div-block"
              />
              <div className="padding-5"></div>
              <p id="w-node-ff3f12d4-2834-12bf-72fa-c1e0d44f0dae-1e49818a" className="paragraph-std white">
                Erhöhen Sie Ihr Gehalt! Wer etwas kann, das viele wollen, der sollte auch entsprechend gut bezahlt werden.
              </p>
            </div>
            <div id="w-node-_8ea5f6d3-3e27-cd7a-374d-34eefd386850-1e49818a" className="div-block-grid-std backround-green">
              <h2 className="heading-3 white">Passendes Unternehmen</h2>
              <img
                src="../../../content/images/handshake_1handshake.webp"
                loading="lazy"
                width="103"
                id="w-node-cfffc313-44b0-a0b2-68d6-f4df8afcebc5-1e49818a"
                alt="Ein Symbol für Einigung, dargestellt durch einen Handschlag zwischen zwei Händen. Das Symbol symbolisiert das Zustandekommen einer Vereinbarung."
                className="image-div-block"
              />
              <div className="padding-5"></div>
              <p id="w-node-ff3f12d4-2834-12bf-72fa-c1e0d44f0db0-1e49818a" className="paragraph-std white">
                Suchen Sie sich aus einer Vielzahl von Unternehmen das aus, welches am besten zu Ihren Bedürfnissen passt.
              </p>
            </div>
            <div id="w-node-_41a27e88-1438-3bcd-2085-3da00115e225-1e49818a" className="div-block-grid-std backround-green">
              <h2 className="heading-3 white">Marktwert kennen</h2>
              <img
                src="../../../content/images/award_1award.webp"
                loading="lazy"
                width="69"
                id="w-node-f9dde1f1-e1b0-7666-a6f5-d074d15de0fc-1e49818a"
                alt="Ein Symbol für das Kennen des Marktwertes, dargestellt durch einen Orden. Das Symbol symbolisiert das Wissen über den persönlichen Wert auf dem Arbeitsmarkt."
                className="image-div-block"
              />
              <div className="padding-5"></div>
              <p id="w-node-ff3f12d4-2834-12bf-72fa-c1e0d44f0db2-1e49818a" className="paragraph-std white">
                Vergleichen Sie verschiedene Angebote und verschaffen Sie sich maximale Transparenz über Ihren Marktwert.{' '}
              </p>
            </div>
            <div id="w-node-aab8d919-2f77-82e8-6d0a-4ced023fe4b0-1e49818a" className="div-block-grid-std backround-green">
              <h2 className="heading-3 white">Spaß bei der Arbeit</h2>
              <img
                src="../../../content/images/heart_1heart.webp"
                loading="lazy"
                width="80"
                id="w-node-fb0c30ab-41d5-4396-845e-85a629f060e7-1e49818a"
                alt="Ein Symbol für Spaß bei der Arbeit, dargestellt durch ein Herz. Das Symbol symbolisiert die Freude, Zufriedenheit und positive Stimmung bei der Ausübung einer Tätigkeit."
                className="image-div-block"
              />
              <div className="padding-5"></div>
              <p id="w-node-ff3f12d4-2834-12bf-72fa-c1e0d44f0db4-1e49818a" className="paragraph-std white">
                Wechseln Sie häufiger das Unternehmen! Ein neuer Anstrich tut gut und Sie behalten die Freude an Ihrer Arbeit.
              </p>
            </div>
          </div>
        </div>
        <div className="padding-section-30"></div>
      </div>
      <div className="section-ci-gradient">
        <div className="padding-section-30"></div>
        <div className="container-std w-container">
          <h1 className="heading-1 white">Bewerben war gestern</h1>
          <h2 className="heading-3 white">
            Mit Flendly erhalten Sie künftig Angebote von Unternehmen, ohne etwas dafür zu tun. Und das völlig<span> </span>
            <span className="text-span-4">kostenlos</span>.
          </h2>
          <div className="w-layout-grid grid-std">
            <div id="w-node-cecc23de-bd98-7beb-7135-630601647223-1e49818a" className="div-block-grid-std backround-ci">
              <h1 className="heading-1 white">1</h1>
              <h2 id="w-node-cecc23de-bd98-7beb-7135-630601647226-1e49818a" className="heading-4 white">
                Kostenlos
                <br />
                registrieren
              </h2>
              <p className="paragraph-std white">In wenigen Minuten Ihr persönliches Profil anlegen. Völlig kostenlos und unverbindlich.</p>
            </div>
            <div id="w-node-cecc23de-bd98-7beb-7135-63060164722c-1e49818a" className="div-block-grid-std backround-ci">
              <h1 className="heading-1 white">2</h1>
              <h2 id="w-node-cecc23de-bd98-7beb-7135-63060164722f-1e49818a" className="heading-4 white">
                Unternehmen
                <br />
                Ausschließen
              </h2>
              <p className="paragraph-std white">
                Alle Unternehmen ausschließen, von denen Sie nicht gefunden werden wollen, wie z.B. Ihr aktueller Arbeitgeber.{' '}
              </p>
            </div>
            <div id="w-node-cecc23de-bd98-7beb-7135-630601647235-1e49818a" className="div-block-grid-std backround-ci">
              <h1 className="heading-1 white">3</h1>
              <h2 id="w-node-cecc23de-bd98-7beb-7135-630601647238-1e49818a" className="heading-4 white">
                Angebot <br />
                erhalten
              </h2>
              <p className="paragraph-std white">
                Sie erhalten Angebote von Unternehmen, die Ihren Anforderungen (Gehalt, Ort…) entsprechen.{' '}
              </p>
            </div>
            <div id="w-node-cecc23de-bd98-7beb-7135-63060164723e-1e49818a" className="div-block-grid-std backround-ci">
              <h1 className="heading-1 white">4</h1>
              <h2 id="w-node-cecc23de-bd98-7beb-7135-630601647241-1e49818a" className="heading-4 white">
                Angebote
                <br />
                Anfragen<strong> </strong>
              </h2>
              <p className="paragraph-std white">
                Falls Sie ein Unternehmen finden, das Sie besonders interessiert, fragen Sie mit einem Klick ein Angebot an.
              </p>
            </div>
          </div>
          <div className="padding-section-30"></div>
          <a href={getEarlyBirdUrl()} className="call-to-action-max w-button">
            Jetzt Profil anlegen
          </a>
          <div className="padding-30"></div>
        </div>
      </div>
    </div>
  );
};

export default Arbeitnehmer;
