export enum MontageArbeitnehmer {
  ja = 'ja',
  abUndZu = 'ab und zu',
  imNotfall = 'im Notfall',
  nein = 'nein',
}
export enum MontageArbeitgeber {
  SEHR_OFT = 'SEHR OFT',
  HAEUFIG = 'HAEUFIG',
  SELTEN = 'SELTEN',
  NIE = 'NIE',
}
