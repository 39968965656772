import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from 'reactstrap';

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // in jeder Ihrer Seitenkomponenten
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    vorname: '',
    nachname: '',
    telefon: '',
    email: '',
    message: '',
  });

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    axios.post('/api/sendEmail', formData).then(response => {
      if (response.status === 200) {
        // toast.info('Ihre Email wurde erfolgreich versendet. Wir werden uns in Kürze bei Ihnen melden.');
        toggle();
      } else {
        toast.error('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.');
      }
    });
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <div className="notification-pop-ups">
          <h1 className="heading-3">Vielen Dank für Ihre Nachricht</h1>
          <div className="padding-10"></div>
          <h1 className="heading-float-3">Wir kümmern uns um Ihr Anliegen und werden uns in Kürze bei Ihnen melden.</h1>
          <div className="paragraph-std">Ihr Flendly Team</div>
          <div className="padding-10"></div>
          <a href="/" className="call-to-action-max w-button">
            okay
          </a>
          <div className="padding-10"></div>

          <div className="padding-10"></div>
        </div>
      </Modal>
      <section className="section">
        <div className="container-workflow w-container">
          <div className="padding-40"></div>
          <h2 className="heading-2">Kontaktanfrage</h2>
          <p className="paragraph-display-centered">Wir freuen uns von Ihnen zu hören! </p>
          <div className="padding-10"></div>
          <div className="w-form" onSubmit={handleSubmit}>
            <form
              id="email-form"
              name="email-form"
              data-name="email Form"
              method="get"
              data-wf-flow="ef58f1d3778cc34dc342f097"
              data-wf-page-id="650ddce7975549021e4981a7"
              data-wf-element-id="80e4fd9a-2f0b-51cd-8f65-4a10e8bdfed2"
            >
              <label htmlFor="vorname" className="field-label-std">
                Vorname
              </label>
              <input
                className="text-field-fix-widtth w-input"
                maxLength={256}
                name="vorname"
                data-name="vorname"
                placeholder=""
                type="text"
                id="vorname"
                required={true}
                onChange={handleChange}
                value={formData.vorname}
              />
              <label htmlFor="nachname" className="field-label-std">
                Nachname
              </label>
              <input
                className="text-field-fix-widtth w-input"
                maxLength={256}
                name="nachname"
                data-name="nachname"
                placeholder=""
                type="text"
                id="nachname"
                required={true}
                onChange={handleChange}
                value={formData.nachname}
              />
              <label htmlFor="email" className="field-label-std">
                Email
              </label>
              <input
                className="text-field-fix-widtth w-input"
                maxLength={256}
                name="email"
                data-name="email"
                placeholder=""
                type="email"
                id="email"
                onChange={handleChange}
                required={true}
              />
              <label htmlFor="telefon" className="field-label-std">
                Telefon (optional)
              </label>
              <input
                className="text-field-fix-widtth w-input"
                maxLength={256}
                name="telefon"
                data-name="telefon"
                placeholder=""
                type="tel"
                id="telefon"
                onChange={handleChange}
                value={formData.telefon}
              />
              <label htmlFor="message" className="field-label-std">
                Nachricht
              </label>
              <textarea
                id="message"
                name="message"
                maxLength={5000}
                data-name="message"
                placeholder="Ihre Nachricht"
                className="text-field-fix-widtth w-input"
                onChange={handleChange}
                value={formData.message}
                required={true}
                rows={5}
              ></textarea>
              <div className="padding-10"></div>
              <input type="submit" data-wait="Please wait..." className="call-to-action-max w-button" value="Absenden" />
              <div className="padding-20"></div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
