import React, { useEffect } from 'react';
import { getRegisterUrl } from 'app/shared/util/url-utils';

export const EarlyBirdArbeitnehmer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="section-workflow-steps-copy">
        <div className="container-std w-container">
          <div className="padding-15"></div>
        </div>
      </div>
      <div className="section-image">
        <div className="padding-section-100-copy"></div>
        <div className="container-std w-container">
          <h2 className="heading-float-2">Herzlich willkommen bei Flendly!</h2>
          <div className="padding-10"></div>
          <div className="div-block-27">
            <div className="div-block-28">
              <h1 className="heading-float-2-c2">
                Registrieren Sie sich noch vor unserem offiziellen Produkt Launch am 26.09. und profitieren Sie für 90 Tage von einer
                Premium Platzierung Ihres Profils auf unserer Suchfunktion! <br />
              </h1>
            </div>
          </div>
          <div className="padding-10"></div>
          <a href={getRegisterUrl()} className="call-to-action-max w-button">
            Pre-Launch-registrierung
          </a>
        </div>
        <div className="padding-section-100"></div>
      </div>
    </div>
  );
};
