import React, { useEffect, useState } from 'react';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { Card, CardBody, Modal, ModalBody } from 'reactstrap';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { useAppSelector } from 'app/config/store';
import { GenericAuswahl } from 'app/shared/components/generic-auswahl';
import FlendlyValidateField from 'app/modules/assets/FlendlyValidateField';
import { fetchBerufe } from 'app/shared/util/beruf.reducer';
import { toast } from 'react-toastify';

interface MultiSelectProps {
  onAuswahlChange: (value: any[]) => void;
  initialValue: any[];
  formEnabled?: boolean;
}

export const BerufAuswahlMultiSelect: React.FC<MultiSelectProps> = ({ onAuswahlChange, initialValue, formEnabled = false }) => {
  const account = useAppSelector(state => state.authentication.account);
  const [colEntries, setColEntries] = useState<any>([]);
  const [currentBeruf, setCurrentBeruf] = useState<any>();
  const [selectedBeruf, setSelectedBeruf] = useState<string>(null);
  const [selectAusbildungsstand, setSelectAusbildungsstand] = useState<any>([]);
  const [selectBerufserfahrung, setSelectBerufserfahrung] = useState<any>([]);
  const [selectZusatzqualifikationen, setSelectZusatzqualifikationen] = useState<string>('');

  const [berufe, setBerufe] = useState<any[]>(initialValue);
  const [allBerufe, setAllBerufe] = useState<any[]>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [berufe]);

  useEffect(() => {
    setform(formEnabled);
    setBerufe(initialValue);
  }, [initialValue]);

  const useBerufe = (): string[] | null => {
    const [berufeList, setBerufeList] = useState<string[] | null>(() => {
      const cachedBerufe = localStorage.getItem('berufeList');
      return cachedBerufe ? JSON.parse(cachedBerufe) : null;
    });

    useEffect(() => {
      if (!berufeList) {
        fetchBerufe().then(data => {
          setBerufeList(data);
          localStorage.setItem('berufeList', JSON.stringify(data));
        });
      }
    }, [berufeList]);

    return berufeList;
  };

  const fetchedBerufe = useBerufe();

  useEffect(() => {
    if (fetchedBerufe) {
      setAllBerufe(fetchedBerufe);
    }
  }, [fetchedBerufe]);

  function addBeruf(beruf: string, ausbidlungsstand: string, berufserfahrung: string, zusatzqualifikationen: string) {
    if (ausbidlungsstand.length > 0 && berufserfahrung.length > 0 && beruf == null) {
      toast.error('Bitte wählen Sie einen Beruf durch Klicken auf einen der Vorschläge aus.');
    } else if (ausbidlungsstand.length > 0 && berufserfahrung.length > 0 && beruf !== null) {
      const berufAuswahl = [];
      berufAuswahl.push(beruf);

      const entity = {
        userID: account.id,
        auswahlBeruf: berufAuswahl,
        auswahlAusbildungsstand: ausbidlungsstand,
        auswahlBerufserfahrung: berufserfahrung,
        auswahlZusatzqualifikationen: zusatzqualifikationen,
      };

      setBerufe([...berufe, entity]);

      onAuswahlChange([...berufe, entity]);

      resetForm();
      setModal(true);
    }
  }

  function resetForm() {
    setCurrentBeruf(null);
    setSelectAusbildungsstand(null);
    setSelectBerufserfahrung(null);
    setSelectZusatzqualifikationen(null);
    setColEntries(null);
    setSelectedBeruf(null);
  }

  function handleTyping(value: string) {
    value = value.toUpperCase();
    setCurrentBeruf(value);
    setSelectedBeruf(null);

    if (value.length === 0) {
      setColEntries(null);
    } else {
      const filteredBerufe = Object.entries(allBerufe)
        .filter(([, val]) => val.name.toLowerCase().startsWith(value.toLowerCase()) || val.name.toLowerCase().includes(value.toLowerCase()))
        .map(([, val]) => val.name)
        .slice(0, 5);

      setColEntries(filteredBerufe);
    }
  }

  function handleBerufCLicked(value) {
    setColEntries(null);
    setCurrentBeruf(value.toUpperCase());
    setSelectedBeruf(value);
    addBeruf(value, selectAusbildungsstand, selectBerufserfahrung, selectZusatzqualifikationen);
  }

  function handleRemoveClicked(beruf: any) {
    setBerufe(berufe.filter(item => item !== beruf));
    onAuswahlChange(berufe.filter(item => item !== beruf));
    setform(true);
  }

  function handleAusbildungsstandChange(value) {
    setSelectAusbildungsstand(value);
    addBeruf(selectedBeruf, value, selectBerufserfahrung, selectZusatzqualifikationen);
  }

  function handleBerufserfahrungChange(value) {
    setSelectBerufserfahrung(value);
    addBeruf(selectedBeruf, selectAusbildungsstand, value, selectZusatzqualifikationen);
  }

  const [modal, setModal] = useState(false);
  const [form, setform] = useState(true);

  const toggle = () => setModal(!modal);

  function handleAddMoreBerufe() {
    resetForm();
    setModal(false);
  }

  function handleContinueInterview() {
    setModal(false);
    setform(false);
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} backdrop="static">
        <ModalBody className="notification-pop-ups padding-5">
          <div>
            <h1 className="heading-3">Möchten Sie weitere Berufe angeben in denen Sie arbeiten können oder möchten?</h1>
            <div className="padding-10"></div>
            <div className="paragraph-std">
              Klicken Sie auf hinzufügen, um einen weiteren Beruf zu ergänzen oder auf fortsetzen, um zum nächsten Schritt zu gelangen.
            </div>
            <div className="padding-30"></div>

            <div className="flendly-button-bar">
              {/* TODO: the following in-line style is a temporary solution, needs to be adjusted with major css refactoring. */}
              <button
                className="button-workflow-forward w-node-c34aae07-d4ae-83b6-6df1-74d77b4e246a-1e4981b7 w-button"
                style={{ width: 'auto' }}
                onClick={() => handleAddMoreBerufe()}
              >
                Hinzufügen
              </button>
              <button className="call-to-action-max w-button" onClick={() => handleContinueInterview()}>
                Fortsetzen
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <div className="paragraph-workflow">
        {berufe.map((beruf, index) => (
          <Card className="flendly-card-list-item" key={index}>
            <CardBody
              style={{
                backgroundColor: '#f5f7fa',
              }}
            >
              <div className="flex-content-cust">
                <div className="col-11">
                  <div id="w-node-_5541b32b-e4d1-5e5b-603b-a5e6d34b6778-8d3b9927" style={{ width: '100%' }} className="beruf-selected">
                    <div className="text-block-search-profile">
                      <strong>{beruf.auswahlBeruf} </strong> <br />
                      {Ausbildungsstand[beruf.auswahlAusbildungsstand]} | {Berufserfahrung[beruf.auswahlBerufserfahrung]}
                      <br />
                      {beruf.auswahlZusatzqualifikationen !== '' ? beruf.auswahlZusatzqualifikationen : ''}
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: '30px' }}>
                  <a
                    id="w-node-a1bd1d02-9b8d-f561-886c-98bfb039018a-8d3b9927"
                    onClick={() => handleRemoveClicked(beruf)}
                    className="btn-bar-card"
                  >
                    <img
                      src="../../../content/images/delete2.PNG"
                      height={20}
                      width={20}
                      loading="lazy"
                      alt="Ein Symbol eines Papierkorbs, das das Löschen von Inhalten symbolisiert. Das Symbol zeigt einen stilisierten Papierkorb, der darauf hinweist, dass der Inhalt endgültig entfernt wird."
                      className="image-15"
                    />
                  </a>
                </div>
              </div>
            </CardBody>
          </Card>
        ))}

        <div className="padding-10"></div>
      </div>

      {form && (
        <div>
          <p className="paragraph-workflow">
            <strong>In welchem Beruf möchten Sie arbeiten?</strong>
            <div className="padding-10"></div>
          </p>

          <FlendlyValidateField
            placeholder="Bitte geben Sie Ihren Beruf ein"
            label="Beruf"
            inputClass="text-field-45px w-input"
            type="text"
            name="berufe"
            id="beruf-auswahl"
            onChange={event => handleTyping(event.target.value)}
            value={currentBeruf}
            wrapperClass="paragraph-workflow paragraph-display-start"
          ></FlendlyValidateField>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {colEntries && colEntries.length > 0 && (
              <div className="paragraph-workflow">
                {colEntries &&
                  colEntries.map(value => (
                    <div key={value}>
                      <div
                        key={value}
                        className={currentBeruf === value ? 'click-button-medium highlighted' : 'click-button-medium'}
                        onClick={() => handleBerufCLicked(value)}
                      >
                        {value}
                      </div>
                      <div className="padding-3"></div>
                    </div>
                  ))}
              </div>
            )}
          </div>

          <div className="padding-10"></div>

          <FlendlyValidateField
            label="Zusatzqualifikationen (optional)"
            placeholder="Zusatzqualifikation wie z.B. Solartechnik"
            id="beruf-zusatzqualifikationen"
            name="zusatzqualifikationen"
            data-cy="zusatzqualifikationen"
            value={selectZusatzqualifikationen}
            onChange={event => setSelectZusatzqualifikationen(event.target.value)}
            type="text"
            headline="Bitte geben Sie Ihre Zusatzqualifikationen an"
            wrapperClass="paragraph-workflow paragraph-display-start"
          />

          <GenericAuswahl
            label="Ausbildungsstand"
            minSelect={1}
            maxSelect={1}
            initialValue={null}
            onAuswahlChange={value => handleAusbildungsstandChange(value)}
            col={Ausbildungsstand}
            title={'Bitte geben Sie Ihren Ausbildungsstand an'}
          />

          <GenericAuswahl
            label="Berufserfahrung"
            minSelect={1}
            maxSelect={1}
            initialValue={null}
            onAuswahlChange={value => handleBerufserfahrungChange(value)}
            col={Berufserfahrung}
            title={'Bitte geben Sie Ihre Berufserfahrung an'}
          />
        </div>
      )}

      {!form && (
        <div>
          <p className="paragraph-workflow">
            <div className="padding-10"></div>
            <strong>Weitere Berufe hinzufügen </strong>
            <img
              onClick={() => setform(true)}
              src="../../../content/images/plus_1plus.png"
              loading="lazy"
              alt="Ein Symbol für Hinzufügen, dargestellt durch ein Pluszeichen."
              className="image-16 hover-pointer"
            />
          </p>
        </div>
      )}
    </div>
  );
};
