import * as React from 'react';
import { useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary, styled } from '@mui/material';
import { getEarlyBirdUrl } from 'app/shared/util/url-utils';
import { Theme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const FlendlyAccordion = styled(Accordion)(({ theme }: { theme: Theme }) => ({
  width: 'auto',
  display: 'grid', //Add this line

  margin: theme.spacing(1),
  // rest of the stylesx

  '&.MuiAccordion-root': {
    flexGrow: 1, // Add this line
    display: 'grid', //Add this line
    width: 'auto',
    backgroundColor: '#dbe3ed',
    borderRadius: '9px',
    alignSelf: 'flex-start',
    marginBottom: '5px',
    position: 'static',
    overflow: 'hidden',
  },
  '&.MuiAccordion-rounded': {
    borderRadius: '9px',
  },
}));

const FlendlyAccordionSummary = styled(AccordionSummary)(({ theme }: { theme: Theme }) => ({
  fontWeight: 'bold', // Make text bold
  fontSize: '1.2em', // Make text larger
}));

export default function AccordionUsage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-workflow-copy w-container">
      <div className="padding-40"></div>
      <h2
        className="heading-2"
        style={{
          margin: '8px',
          padding: '0px',
        }}
      >
        FAQ
      </h2>

      <h2
        className="heading-3-copy"
        style={{
          margin: '8px',
        }}
      >
        Arbeitnehmer
      </h2>
      <div className="padding-5"></div>

      <FlendlyAccordion>
        <FlendlyAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          Was unterscheidet Flendly von anderen Jobplattformen?{' '}
        </FlendlyAccordionSummary>
        <AccordionDetails>
          Flendly ist die erste Jobplattform, auf der sich Unternehmen bei Ihnen als Arbeitnehmer bewerben. Wir drehen den Bewerbungsprozess
          also um. Lehnen Sie sich zurück und warten Sie auf passende Angebote inkl. Gehaltsangabe. Zudem sind unsere Prozesse rein auf die
          Bedürfnisse des Handwerks und der Industrie ausgerichtet und daher wesentlich effizienter als bei anderen, meist auf Akademiker
          ausgerichteten Plattformen.
        </AccordionDetails>
      </FlendlyAccordion>
      <FlendlyAccordion>
        <FlendlyAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
          Wie funktioniert Flendly?
        </FlendlyAccordionSummary>
        <AccordionDetails>
          <Link to={getEarlyBirdUrl()} className="link-4">
            Registrieren
          </Link>{' '}
          Sie sich innerhalbweniger Minuten auf unserer Plattform. Anschließend werden Sie passenden Unternehmen als potentieller Kandidat
          angezeigt und erhalten Bewerbungen. Alternativ können Sie natürlich auch selbst Unternehmen suchen und diese mit einem einzigen
          Mausklick dazu auffordern, Ihnen eine Bewerbung zu senden.
        </AccordionDetails>
      </FlendlyAccordion>
      <FlendlyAccordion>
        <FlendlyAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
          Wie kann ich Flendly nutzen?{' '}
        </FlendlyAccordionSummary>
        <AccordionDetails>
          <a href={getEarlyBirdUrl()} className="link-4">
            Registrieren
          </a>
          Sie sich innerhalb weniger Minuten über unseren{' '}
          <a href={getEarlyBirdUrl()} className="link-4">
            Registrierungsprozess{' '}
          </a>
          und nutzen Sie anschließend direkt alle Funktionalitäten unserer Plattform - völlig kostenlos.
        </AccordionDetails>
      </FlendlyAccordion>

      <FlendlyAccordion>
        <FlendlyAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          Was kostet Flendly?
        </FlendlyAccordionSummary>
        <AccordionDetails>
          Für Sie als Arbeitnehmer ist die Nutzung der Plattform komplett kostenlos. Sie können sich jederzeit auf Angebote bewerben und
          erhalten diese auch direkt von Unternehmen. Es fallen keine Kosten an.
        </AccordionDetails>
      </FlendlyAccordion>
      <FlendlyAccordion>
        <FlendlyAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
          Wie kann ich meinem Profil weitere Berufe hinzufügen?
        </FlendlyAccordionSummary>
        <AccordionDetails>
          In Ihrem persönlichen Nutzerprofil können Sie jederzeit Berufe, für die Sie Angebote erhalten wollen, hinzufügen oder entfernen.
          Darüber hinaus finden Sie hier alle im Registrierungsprozess gemachten Angaben wie Kontaktdaten, Mindestgehalt usw. und können
          diese jederzeit ändern.
        </AccordionDetails>
      </FlendlyAccordion>

      <div className="padding-20"></div>
      <h2
        className="heading-3-copy"
        style={{
          margin: '8px',
        }}
      >
        Unternehmen
      </h2>
      <div className="padding-5"></div>
      <FlendlyAccordion>
        <FlendlyAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          Was unterscheidet Flendly von anderen Jobplattformen?
        </FlendlyAccordionSummary>
        <AccordionDetails>
          Flendly ist die erste Jobplattform, auf der Sie sich als Unternehmen aktiv bei Arbeitnehmern bewerben können. Dadurch ist Ihre
          Mitarbeitersuche kein passives Warten auf Bewerbungen mehr, sondern eine aktive Suche, in der Sie alle Hebel in der Hand haben, um
          Kandidaten zu finden und von sich zu überzeugen. Zudem sind unsere Prozesse rein auf die Bedürfnisse des Handwerks und der
          Industrie ausgerichtet und daher wesentlich effizienter als bei anderen, meist auf Akademiker ausgerichteten Plattformen.
        </AccordionDetails>
      </FlendlyAccordion>
      <FlendlyAccordion>
        <FlendlyAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          Wie funktioniert Flendly?
        </FlendlyAccordionSummary>
        <AccordionDetails>
          <Link to={getEarlyBirdUrl()} className="link-4">
            Registrieren
          </Link>{' '}
          Sie sich innerhalb weniger Minuten auf unserer Plattform. Anschließend können Sie sich direkt auf die Suche nach potentiellen
          Kandidaten machen und mit nur einem Klick Angebote versenden. Alternativ können Sie natürlich auch auf Bewerbungen passender
          Kandidaten warten und diese mit einem Klick zum Bewerbungsgespräch einladen.
        </AccordionDetails>
      </FlendlyAccordion>
      <FlendlyAccordion>
        <FlendlyAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          Wie kann ich Flendly nutzen?
        </FlendlyAccordionSummary>
        <AccordionDetails>
          <Link to={getEarlyBirdUrl()} className="link-4">
            Registrieren
          </Link>{' '}
          Sie sich innerhalb weniger Minuten über unseren{' '}
          <Link to={getEarlyBirdUrl()} className="link-4">
            Registrierungsprozess{' '}
          </Link>
          und nutzen Sie anschließend direkt alle Funktionalitäten unserer Plattform - völlig kostenlos.
        </AccordionDetails>
      </FlendlyAccordion>
      <FlendlyAccordion>
        <FlendlyAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          Was kostet Flendly?
        </FlendlyAccordionSummary>
        <AccordionDetails>
          Für Sie als Arbeitgeber ist die Nutzung der Plattform erstmal komplett kostenlos. Bei Vermittlung eines Arbeitnehmers, d.h. sobald
          ein über Flendly vermittelter Arbeitnehmer tatsächlich von Ihnen eingestellt wird, fällt eine Vermittlungsgebühr von 499€ an.
        </AccordionDetails>
      </FlendlyAccordion>
      <FlendlyAccordion>
        <FlendlyAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          Wie kann ich meine Unternehmensdaten anpassen?
        </FlendlyAccordionSummary>
        <AccordionDetails>
          In Ihrem Unternehmensprofil können Sie jederzeit alle im{' '}
          <Link to={getEarlyBirdUrl()} className="link-4">
            Registrierungsprozess{' '}
          </Link>{' '}
          gemachten Angaben wie Kontaktdaten, Ansprechpartner usw. anpassen.
        </AccordionDetails>
      </FlendlyAccordion>
      <div className="padding-20"></div>
    </div>
  );
}
