import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IUser } from 'app/shared/model/user.model';
import { toast } from 'react-toastify';
import { updateUserPassword } from 'app/shared/reducers/authentication';
import FlendlyValidateField from 'app/modules/assets/FlendlyValidateField';
import { useNavigate } from 'react-router-dom';

export const SicherheitArbeitnehmer = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const account: IUser = useAppSelector(state => state.authentication.account);

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

  function handleSubmit() {
    if (newPassword !== newPasswordRepeat) {
      toast.error('Passwörter stimmen nicht überein');
    } else {
      dispatch(updateUserPassword({ user: account, currentPassword: password, newPassword: newPassword }))
        .then(value => {
          if (value['error']) {
            console.log(value['error']);
            toast.error('Fehler beim Speichern des Passworts');
          } else {
            navigate('/suche/arbeitnehmer');
          }
        })
        .catch(() => {
          toast.error('Fehler beim Speichern des Passworts');
        });
    }
  }

  return (
    <div>
      <div className="section-workflow-steps">
        <div className="container-18 w-container">
          <h1 className="heading-2-left">Sicherheit</h1>
          <p className="paragraph-display">
            <strong>Passwort ändern</strong>
          </p>
          <p>Erstellen Sie ein neues Passwort mit mindestens acht Zeichen und ein Sonderzeichen haben.</p>
          <div className="padding-5"></div>
          <div className="form-block-2 w-form">
            <div
              id="email-form"
              data-name="Email Form"
              data-wf-page-id="650ddce7975549021e4981b2"
              data-wf-element-id="29ae4824-7384-9aa9-a050-d40d81c4e119"
            >
              <FlendlyValidateField
                onChange={e => setPassword(e.target.value)}
                value={password}
                label={'Aktuelles Passwort'}
                name="name"
                data-name="Name"
                placeholder=""
                type="text"
                id="name"
              />
              <FlendlyValidateField
                label={'Neues Passwort'}
                onChange={e => setNewPassword(e.target.value)}
                value={newPassword}
                name="name-3"
                data-name="Name 3"
                placeholder=""
                type="text"
                id="name-3"
              />
              <FlendlyValidateField
                label={'Wiederholung neues Passwort'}
                onChange={e => setNewPasswordRepeat(e.target.value)}
                value={newPasswordRepeat}
                name="name-2"
                data-name="Name 2"
                placeholder=""
                type="text"
                id="name-2"
              />
              <div className="padding-10"></div>
              <div className="padding-5"></div>
              <div onClick={handleSubmit} data-wait="Please wait..." className="cta-grey-workflow-save w-button">
                Passwort speichern
              </div>
            </div>
          </div>
          <div className="padding-30"></div>
        </div>
      </div>
    </div>
  );
};

export default SicherheitArbeitnehmer;
