import React, { useEffect, useState } from 'react';
import { Arbeitsweise, PersönlicheEigenschaften } from 'app/shared/model/enumerations/arbeitnehmerEigenschaften';
import { toast } from 'react-toastify';

export const ArbeitnehmerEigenschaftenAuswahl = ({ onAuswahlChange, initialValue, minSelect = 1, maxSelect = 9, title }) => {
  const [selectValue, setSelectValue] = useState([]);

  useEffect(() => {
    if (initialValue) {
      setSelectValue(initialValue);
    }
  }, [initialValue]);

  const getKeyByValue = (col, value: string) => {
    return Object.keys(col).find(key => col[key] === value);
  };

  function handleSelectValue(col, selected) {
    selected = getKeyByValue(col, selected);
    if (maxSelect === 1) {
      // Wenn maxSelect genau 1 ist, dann immer nur einen Wert auswählen
      setSelectValue([selected]);
      onAuswahlChange(selected);
    } else {
      // Ansonsten die Anzahl der ausgewählten Werte überprüfen
      if (selectValue.includes(selected)) {
        // Wert ist bereits ausgewählt, entfernen
        setSelectValue(selectValue.filter(item => item !== selected));
        onAuswahlChange(selectValue.filter(item => item !== selected));
      } else {
        // Wert ist nicht ausgewählt, überprüfen ob maxSelect erreicht ist
        if (maxSelect > 0 && selectValue.length < maxSelect) {
          setSelectValue([...selectValue, selected]);
          onAuswahlChange([...selectValue, selected]);
        } else {
          toast.info(`Sie können maximal ${maxSelect} Eigenschaften auswählen.`);
        }
      }
    }
  }

  return (
    <div id="employer_reg_03" className="div-block-workflow">
      <div className="wrapper-100">
        <p className="paragraph-workflow">
          <strong>{title}</strong>
        </p>

        <div className="padding-10"></div>
        <div className="text-block-26">Persönlichkeit</div>
        <div className="padding-5"></div>

        <div className="w-layout-grid grid-skills-10">
          {Object.entries(PersönlicheEigenschaften).map(([key, value]) => (
            <div
              style={{
                alignSelf: 'center',
              }}
              key={key}
              className={`click-button-medium ${selectValue.includes(key) ? 'highlighted' : ''}`}
              onClick={() => handleSelectValue(PersönlicheEigenschaften, value)}
            >
              {value}
            </div>
          ))}
        </div>
        <div className="padding-10"></div>

        <div className="text-block-26">Arbeitsweise</div>
        <div className="padding-5"></div>

        <div className="w-layout-grid grid-skills-10">
          {Object.entries(Arbeitsweise).map(([key, value]) => (
            <div
              style={{
                alignSelf: 'center',
              }}
              key={key}
              className={`click-button-medium ${selectValue.includes(key) ? 'highlighted' : ''}`}
              onClick={() => handleSelectValue(Arbeitsweise, value)}
            >
              {value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
