import React, { useEffect, useState } from 'react';
import { INotification } from 'app/shared/model/notification.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { getArbeitgeber as getArbeitgeber } from 'app/shared/reducers/arbeitgeber.reducer';
import { getArbeitnehmerSuche } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { Link, useNavigate } from 'react-router-dom';
import { IRecommendation } from 'app/shared/model/recommendation.model';
import { getRecommendationsById } from 'app/modules/suche/recommendation/recommendation.reducer';
import { markNotificationAsDone } from 'app/shared/reducers/notification.reducer';

interface NotificationProps {
  notification: INotification;
  closeModal: () => void;
}

export const AnfrageRejected: React.FC<NotificationProps> = ({ notification, closeModal }) => {
  const dispatch = useAppDispatch();

  let navigate = useNavigate();

  const account = useAppSelector(state => state.authentication.account);
  const recommendation: IRecommendation = useAppSelector(state => state.recommendation.entity);

  const arbeitgeber: IArbeitgeber = useAppSelector(state => state.arbeitgeber.entity);
  const arbeitnehmersuche: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);

  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (notification) {
      let recommendationId = notification.keyValueMap['recommendationId'];
      setMessage(notification.keyValueMap['reason']);
      dispatch(getRecommendationsById(recommendationId));
    }
  }, [notification]);

  useEffect(() => {
    if (recommendation.arbeitgeberId && recommendation.arbeitnehmerSucheId) {
      dispatch(getArbeitgeber(recommendation.arbeitgeberId));
      dispatch(getArbeitnehmerSuche(recommendation.arbeitnehmerSucheId));
    }
  }, [recommendation]);

  function loaded() {
    return arbeitgeber.userID && arbeitnehmersuche.userID;
  }

  function handleContinue() {
    closeModal();
    dispatch(markNotificationAsDone(notification.id));
    navigate('/suche/arbeitnehmer');
  }

  return (
    <div>
      {loaded() && (
        <div className="notification-pop-ups-feedback">
          <h1 className="heading-3">Abgelehnte Anfrage</h1>
          <div className="paragraph-std">
            {arbeitgeber.unternehmensname} hat Ihre Anfrage für eine Anstellung als {arbeitnehmersuche.auswahlBeruf}
            leider abgelehnt.
          </div>
          <div className="padding-10"></div>
          <div className="field-label-std">Begründung</div>
          <div className="text-block-left">{message}</div>
          <div className="padding-20"></div>
          <div onClick={handleContinue} className="call-to-action-max w-button">
            weitersuchen
          </div>
          <div className="padding-10"></div>
        </div>
      )}
    </div>
  );
};
