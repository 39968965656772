import React from 'react';
import { INotification } from 'app/shared/model/notification.model';
import { useAppDispatch } from 'app/config/store';
import { useNavigate } from 'react-router-dom';
import { markNotificationAsDone } from 'app/shared/reducers/notification.reducer';

interface NotificationProps {
  notification: INotification;
  closeModal: () => void;
}

export const EmailChanged: React.FC<NotificationProps> = ({ notification, closeModal }) => {
  const dispatch = useAppDispatch();

  let navigate = useNavigate();

  function handleContinue() {
    closeModal();
    dispatch(markNotificationAsDone(notification.id));
    navigate('/suche/arbeitnehmer');
  }

  return (
    <div>
      <div className="notification-pop-ups-feedback">
        <h1 className="heading-3">Ihre Email wurde geändert.</h1>
        <div className="paragraph-std">
          Sie müssen ihre neue Email bestätigen, bevor Sie weitermachen können. Bitte überprüfen Sie Ihre Email und folgen Sie dem Link.
        </div>
        <div className="padding-10"></div>
        <div onClick={handleContinue} className="call-to-action-max w-button">
          Ausloggen und Email bestätigen
        </div>
        <div className="padding-10"></div>
      </div>
    </div>
  );
};
