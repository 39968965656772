import React, { useEffect, useRef, useState } from 'react';
import { INotification } from 'app/shared/model/notification.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { toast } from 'react-toastify';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { getAngebot } from 'app/shared/reducers/angebot.reducer';
import { getArbeitgeber as getArbeitgeber } from 'app/shared/reducers/arbeitgeber.reducer';
import { getArbeitnehmerSuche as getarbeitnehmersuche } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { IAngebot } from 'app/shared/model/angebot.model';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { getArbeitnehmer } from 'app/shared/reducers/arbeitnehmer.reducer';
import { markNotificationAsDone } from 'app/shared/reducers/notification.reducer';

interface NotificationProps {
  notification: INotification;
  closeModal: () => void;
}

export const AngebotAccepted: React.FC<NotificationProps> = ({ notification, closeModal }) => {
  const dispatch = useAppDispatch();

  const angebot: IAngebot = useAppSelector(state => state.angebot.entity);

  const arbeitgeber: IArbeitgeber = useAppSelector(state => state.arbeitgeber.entity);
  const arbeitnehmer: IArbeitnehmer = useAppSelector(state => state.arbeitnehmer.entity);
  const arbeitnehmersuche: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);

  useEffect(() => {
    if (notification) {
      console.log(notification);

      let angebotId = notification.keyValueMap['angebotId'];
      if (angebotId && angebotId !== 'undefined') {
        dispatch(getAngebot(angebotId));
      } else {
        console.log('angebotId is not defined');
        toast.error('angebotId is not defined');
      }
    }
  }, [notification]);

  useEffect(() => {
    if (angebot.arbeitgeberId) {
      dispatch(getArbeitgeber(angebot.arbeitgeberId));
      dispatch(getArbeitnehmer(angebot.arbeitnehmerId));
      dispatch(getarbeitnehmersuche(angebot.arbeitnehmerSucheId));
    }
  }, [angebot]);

  function loaded() {
    return arbeitgeber.userID && arbeitnehmersuche.userID;
  }

  function handleClose() {
    closeModal();
    dispatch(markNotificationAsDone(notification.id));
  }

  return (
    <div>
      {loaded() && (
        <div>
          <div className="notification-pop-ups-5">
            <h1 className="heading-3">
              Herzlichen Glückwunsch!
              <strong>
                <br />
              </strong>
            </h1>
            <h1 className="heading-float-3-copy">
              {arbeitnehmer.auswahlAnrede} {arbeitnehmer.vorname} {arbeitnehmer.name} hat Ihr Angebot für eine Anstellung als{' '}
              {arbeitnehmersuche.auswahlBeruf}
              angenommen.
            </h1>
            <div className="paragraph-std">
              Bitte nehmen Sie zeitnah Kontakt zu {arbeitnehmer.auswahlAnrede} {arbeitnehmer.name} auf um einen Bewerbungstermin zu
              vereinbaren.
            </div>
            <div className="padding-20-speziale"></div>
            <h1 className="heading-4">
              Kontakt {arbeitnehmer.auswahlAnrede} {arbeitnehmer.name}
            </h1>
            <div className="w-layout-grid grid-335">
              <div id="w-node-f344212d-b316-4ec7-500d-0d6e44b56d26-75dd6efc">
                <div className="label-text-blue">Name</div>
                <div className="card-field-min-170px">
                  {arbeitnehmer.auswahlAnrede} {arbeitnehmer.vorname} {arbeitnehmer.name}
                </div>
              </div>
              <div id="w-node-f080c007-9713-4462-c0a0-a159587bfdaa-75dd6efc">
                <div className="label-text-blue">
                  <strong>Telefon</strong>
                </div>
                <div className="card-field-min-170px">{arbeitnehmer.telefon}</div>
              </div>
              <div id="w-node-ba28c890-0862-f5df-e8ce-9bc0808a5c1e-75dd6efc">
                <div className="label-text-blue">Email</div>
                <div className="card-field-min-170px">no-email@local</div>
              </div>
            </div>
            <div className="padding-20"></div>
            <a onClick={handleClose} className="call-to-action-max w-button">
              schließen
            </a>
            <div className="padding-10"></div>
          </div>
        </div>
      )}
    </div>
  );
};
