import React, { FunctionComponent } from 'react';
import { ISprache } from 'app/shared/model/sprache.model';

interface IProgressBarProps {
  totalSteps: number;
  currentStep: number;
  cssClassSteps: string;
}

export const FlendlyProgressBar: FunctionComponent<IProgressBarProps> = ({ totalSteps, currentStep, cssClassSteps }) => {
  const getStepClass = stepNumber => {
    if (stepNumber < currentStep) {
      return 'div-block-grid-accent';
    } else if (stepNumber === currentStep) {
      return 'div-block-grid-accent-light';
    } else {
      return 'div-block-grid-dark-grey';
    }
  };

  return (
    <div className="section-workflow-progress-bar">
      <div className="container-workflow w-container">
        <div className="padding-20"></div>

        <div id="progress-bar" className={`w-layout-grid ${cssClassSteps}`}>
          {[...Array(totalSteps)].map((_, index) => (
            <div key={index} className={getStepClass(index)}></div>
          ))}
        </div>
        <div className="padding-20"></div>
      </div>
    </div>
  );
};
