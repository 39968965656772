export interface IAngebot {
  id?: string;
  arbeitgeberId: string;
  arbeitnehmerId: string;
  arbeitnehmerSucheId: string;
  suchkriterienId: string;
  gehalt: string;
  message: string;
  recommendationId: string;
  createdDate: number;
}

export const defaultValue: Readonly<IAngebot> = {
  arbeitgeberId: null,
  arbeitnehmerId: null,
  arbeitnehmerSucheId: null,
  gehalt: null,
  message: null,
  recommendationId: null,
  suchkriterienId: null,
  createdDate: null,
};
