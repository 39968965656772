import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';

const baseStyle: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#0fd7cd',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out',
};

const img: React.CSSProperties = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const thumbInner: React.CSSProperties = {
  display: 'flex',
  minWidth: 0,
  overflow: 'visible',
};

const wrapper: React.CSSProperties = {
  position: 'relative',
  display: 'inline-flex',
  marginBottom: '8px',
  marginRight: '8px',
};

const thumb: React.CSSProperties = {
  display: 'inline-flex',
  borderRadius: '2px',
  border: '1px solid #eaeaea',
  height: 'auto',
  padding: '4px',
  boxSizing: 'border-box',
};

const remove: React.CSSProperties = {
  color: 'red',
  cursor: 'pointer',
  position: 'absolute',
  right: '-15px',
  top: '-10px',
  borderRadius: '50%',
  backgroundColor: 'white',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

function DropzoneComponent({ initial, onFileUpload }) {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.onload = function (this: HTMLImageElement) {
          // Überprüfen Sie die Abmessungen des Bildes
          if (this.naturalWidth > 600 || this.naturalHeight > 600) {
            toast.error(`${file.name} Bild ist zu groß. Maximale Größe ist 500x500 Pixel.`);
          } else {
            // Das Bild akzeptieren und den Zustand aktualisieren
            const imageData = Object.assign(file, {
              preview: URL.createObjectURL(file),
            });
            setFiles(currentFiles => [...currentFiles, imageData]);
            // Rufen Sie die onFileUpload Funktion auf, um die ausgewählten Dateien weiterzuleiten
            onFileUpload(acceptedFiles);
          }
        };
        image.src = reader.result as string;
      };
      reader.readAsDataURL(file);
    });
  }, []);

  // Diese Funktion entfernt das angeklickte Bild
  const removeFile = file => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    onFileUpload(newFiles);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg, image/png': [],
    },
    maxSize: 3 * 1024 * 1024, // 3MB
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // In Ihrer Thumbs Funktion, fügen Sie das 'X' Icon hinzu und binden Sie die onClick Funktion an es
  const thumbs = files.map(file => (
    <div style={wrapper}>
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img src={file.preview} style={img} />
        </div>
      </div>
      <FaTimesCircle style={remove} onClick={removeFile(file)} />
    </div>
  ));

  // clean up
  // useEffect(
  //   () => () => {
  //     files.forEach(file => URL.revokeObjectURL(file.preview));
  //   },
  //   [files]
  // );

  useEffect(() => {
    if (initial) {
      setFiles([initial]);
    }
  }, []);

  return (
    <div
      style={{
        display: 'contents',
      }}
    >
      {files.length === 0 && (
        <label htmlFor="profile-description" className="field-label-std">
          Logo Upload (optional)
        </label>
      )}
      <section
        className="form-group-full"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {files.length === 0 && (
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <div>Ziehen Sie Ihr Logo hier hin.(max. 500x500 Pixel)</div>
          </div>
        )}

        <div className="padding-10" />
        <aside>{thumbs}</aside>
      </section>
    </div>
  );
}

export default DropzoneComponent;
