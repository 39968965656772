import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IAngebot } from 'app/shared/model/angebot.model';

const initialState: EntityState<IAngebot> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/angebot';

// Actions

export const getAngebot = createAsyncThunk(
  'angebot/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IAngebot>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createAngebot = createAsyncThunk(
  'angebot/create_entity',
  async (entity: IAngebot, thunkAPI) => {
    return await axios.post<IAngebot>(apiUrl + '/send', cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const acceptAngebot = createAsyncThunk(
  'angebot/accept_entity',
  async (entity: IAngebot, thunkAPI) => {
    return await axios.post<IAngebot>(`${apiUrl}/accept`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const rejectAngebot = createAsyncThunk(
  'angebot/reject_entity',
  async (entity: IAngebot, thunkAPI) => {
    return await axios.post<IAngebot>(`${apiUrl}/reject`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'angebot/update_entity',
  async (entity: IAngebot, thunkAPI) => {
    return await axios.put<IAngebot>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'angebot/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return await axios.delete<IAngebot>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const AngebotSlice = createEntitySlice({
  name: 'angebot',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAngebot.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = defaultValue;
      })
      .addMatcher(isFulfilled(createAngebot, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getAngebot), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createAngebot, updateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = AngebotSlice.actions;

// Reducer
export default AngebotSlice.reducer;
