import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IMatch } from 'app/shared/model/match.model';

const initialState: EntityState<IMatch> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/matches';

// Actions
export const getMatchesForArbeitgeber = createAsyncThunk(
  'match/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/arbeitgeber/${id}`;
    return axios.get<IMatch[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getMatchesForArbeitnehmer = createAsyncThunk(
  'match/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/arbeitnehmer/${id}`;
    return axios.get<IMatch>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getMatchById = createAsyncThunk(
  'match/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IMatch>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'match/update_entity',
  async (entity: IMatch, thunkAPI) => {
    return await axios.put<IMatch>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'match/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return await axios.delete<IMatch>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const MatchSlice = createEntitySlice({
  name: 'match',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getMatchById.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addMatcher(isFulfilled(updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getMatchesForArbeitgeber), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(getMatchesForArbeitnehmer), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getMatchesForArbeitnehmer, getMatchesForArbeitgeber, getMatchById), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(updateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = MatchSlice.actions;

// Reducer
export default MatchSlice.reducer;
