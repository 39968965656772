import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IRecommendation } from 'app/shared/model/recommendation.model';
import { cleanEntity } from 'app/shared/util/entity-utils';

const initialState: EntityState<IRecommendation> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/recommendations';

// Actions

export const getRecommendationsById = createAsyncThunk(
  'recommendation/fetch_entity_list_for_arbeitgeber',
  async (recommendationId: string) => {
    const requestUrl = `${apiUrl}/${recommendationId}`;
    return axios.get<IRecommendation>(requestUrl);
  }
);

export const getRecommendationsForArbeitgeber = createAsyncThunk(
  'recommendation/fetch_entity_list_for_arbeitgeber',
  async ({ arbeitnehmersucheID, includeDeclined }: { arbeitnehmersucheID: string; includeDeclined: boolean }) => {
    const requestUrl = `${apiUrl}/arbeitgeber/${arbeitnehmersucheID}?includeDeclined=${includeDeclined}`;
    return axios.get<IRecommendation[]>(requestUrl);
  }
);

export const getRecommendationsForArbeitnehmer = createAsyncThunk(
  'recommendation/fetch_entity_list_for_arbeitnehmer',
  async ({ userID, includeDeclined }: { userID: string; includeDeclined: boolean }) => {
    const requestUrl = `${apiUrl}/arbeitnehmer/${userID}/?includeDeclined=${includeDeclined}`;
    return axios.get<IRecommendation[]>(requestUrl);
  }
);

export const getRecommendationForArbeitgeberAndArbeitnehmer = createAsyncThunk(
  'recommendation/getRecommendationForArbeitgeberAndArbeitnehmer',
  async ({ arbeitnehmerId, arbeitgeberId }: { arbeitnehmerId: string; arbeitgeberId: string }) => {
    const requestUrl = `${apiUrl}?arbeitnehmerId=${arbeitnehmerId}&arbeitgeberId=${arbeitgeberId}`;
    return axios.get<IRecommendation>(requestUrl);
  }
);

export const updateRecommendation = createAsyncThunk(
  'recommendation/update_entity',
  async (entity: IRecommendation, thunkAPI) => {
    return await axios.put<IRecommendation>(`${apiUrl}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const recommendationSlice = createEntitySlice({
  name: 'recommendation',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getRecommendationsForArbeitgeber.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(getRecommendationsForArbeitnehmer.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(getRecommendationForArbeitgeberAndArbeitnehmer.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getRecommendationsById), (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getRecommendationsForArbeitnehmer, getRecommendationsForArbeitgeber), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = recommendationSlice.actions;

// Reducer
export default recommendationSlice.reducer;
