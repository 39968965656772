import axios from 'axios';
import { Storage } from 'react-jhipster';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from './reducer.utils';

import { AppThunk } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IUser } from 'app/shared/model/user.model';

export const initialState = {
  loading: false,
  isAuthenticated: false,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
  isRegistrationEnabled: false,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Actions

export const getSession = (): AppThunk => async (dispatch, getState) => {
  await dispatch(getAccount());

  const { account } = getState().authentication;
  if (account && account.langKey) {
    const langKey = Storage.session.get('locale', account.langKey);
    await dispatch(setLocale(langKey));
  }
};

export const updateAccount = createAsyncThunk(
  'account/update_account',
  async (entity: IUser, thunkAPI) => {
    const result = await axios.put<IUser>(`api/account/settings`, cleanEntity(entity));
    thunkAPI.dispatch(getAccount());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteAccount = createAsyncThunk(
  'account/delete_account',
  async (account: IUser, thunkAPI) => {
    const result = await axios.post<IUser>(`api/account/delete`, cleanEntity(account));
    thunkAPI.dispatch(getAccount());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateUserPassword = createAsyncThunk(
  'account/update_password',
  async (body: any, thunkAPI) => {
    const requestBody = {
      user: body['user'],
      currentPassword: body['currentPassword'],
      newPassword: body['newPassword'],
    };

    return await axios.post('api/account/update/password', requestBody);
  },
  { serializeError: serializeAxiosError }
);

export const updateEmail = createAsyncThunk(
  'account/update_email',
  async (user: IUser, thunkAPI) => {
    return await axios.post('api/account/update/email', cleanEntity(user));
  },
  { serializeError: serializeAxiosError }
);

export const getAccount = createAsyncThunk('authentication/get_account', async () => axios.get<any>('api/account'), {
  serializeError: serializeAxiosError,
});

export const logoutServer = createAsyncThunk('authentication/logout', async () => axios.post<any>('api/logout', {}), {
  serializeError: serializeAxiosError,
});

export const joinGroup =
  (userID: string, group: string): AppThunk =>
  (dispatch, getState) => {
    axios
      .put('api/account/join-group', null, {
        params: {
          userID,
          group,
        },
      })
      .then(response => {
        dispatch(getAccount());

        const { account } = getState().authentication;
      })
      .catch(error => {
        console.error(error);
      });
  };

export const logout: () => AppThunk = () => async dispatch => {
  await dispatch(logoutServer());
  // fetch new csrf token
  // dispatch(getSession());
};

export const clearAuthentication = messageKey => dispatch => {
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    authError(state, action) {
      return {
        ...state,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        errorMessage: action.error.message,
      }))
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
        const isRegistrationEnabled = action.payload && action.payload.data && action.payload.data.registrationFlow;
        return {
          ...state,
          isInRegistrationFlow: isRegistrationEnabled,
          isAuthenticated,
          loading: false,
          sessionHasBeenFetched: true,
          account: action.payload.data,
          hasRoleAssigned: action.payload.data.hasRoleAssigned,
        };
      })
      .addCase(logoutServer.fulfilled, (state, action) => ({
        ...initialState,
        logoutUrl: action.payload.data.logoutUrl,
      }))
      .addCase(getAccount.pending, state => {
        state.loading = true;
      });
  },
});

export const { authError, clearAuth } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
