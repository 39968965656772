import React, { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import FlendlyValidateField from 'app/modules/assets/FlendlyValidateField';
import { IZeitpunkt } from 'app/shared/model/zeitpunkt.model';

interface ZeitpunktAuswahlProps {
  onAuswahlChange: Function;
  initialValue: IZeitpunkt;
  title: string;
}

export const ZeitpunktAuswahl: FunctionComponent<ZeitpunktAuswahlProps> = ({ title, onAuswahlChange, initialValue }) => {
  const [selectZeitpunkt, setSelectZeitpunkt] = useState(null);
  const [selectCheckbox, setSelectCheckbox] = useState(false);
  const [selectType, setSelectType] = useState('');

  useEffect(() => {
    if (initialValue) {
      const initialType = initialValue.zeitpunkt === 'sofort' ? 'sofort' : initialValue.zeitpunkt === 'absprache' ? 'absprache' : 'ab';

      setSelectCheckbox(initialType === 'ab');
      setSelectType(initialType);
      setSelectZeitpunkt(initialValue.zeitpunkt);
    }
  }, [initialValue]);

  function handleSelectZeitpunkt(value, type) {
    setSelectType(type);

    if (type === 'ab') {
      if (new Date(value).getDay() !== new Date().getDay() && new Date(value) < new Date()) {
        toast.error('Das Datum muss in der Zukunft liegen.');
        return; // Verhindert das Setzen des Zustands, wenn das Datum nicht dem Format entspricht
      }

      setSelectCheckbox(true);
      setSelectZeitpunkt(value); // Aktualisiert den Zustand mit dem Datum als String

      const zeitpunkt = {
        zeitpunkt: value, // Setzt 'ab', 'sofort' oder 'absprache'
        zeitpunktDatum: true, // Setzt das Datum nur, wenn 'ab' ausgewählt ist
      };

      //check if the date is in the past

      onAuswahlChange(zeitpunkt);
    } else {
      setSelectCheckbox(false);
      setSelectZeitpunkt(value); // Setzt 'sofort' oder 'absprache' als String

      const zeitpunkt = {
        zeitpunkt: value, // Setzt 'ab', 'sofort' oder 'absprache'
        zeitpunktDatum: false, // Setzt das Datum nur, wenn 'ab' ausgewählt ist
      };

      onAuswahlChange(zeitpunkt);
    }
  }

  function handleSelectCheckbox() {
    onAuswahlChange(null);

    setSelectType('ab');
    setSelectCheckbox(!selectCheckbox);

    if (!selectCheckbox) {
      setSelectZeitpunkt('');
    }
  }

  return (
    <div id="employer_reg_16" className="div-block-workflow">
      <p className="paragraph-workflow">
        <strong>{title}</strong>
      </p>
      <div className="padding-10"></div>

      <div className="field-label-std">
        <strong>Zeitpunkt</strong>
      </div>
      <div id="3-single-select" data-fl-type="single-select" className="w-layout-grid grid-49-single-select">
        <div
          key="sofort"
          className={`click-button-medium ${selectType === 'sofort' ? 'highlighted' : ''}`}
          onClick={() => handleSelectZeitpunkt('sofort', 'sofort')}
        >
          ab sofort
        </div>
        <div
          key="absprache"
          className={`click-button-medium ${selectType === 'absprache' ? 'highlighted' : ''}`}
          onClick={() => handleSelectZeitpunkt('absprache', 'absprache')}
        >
          nach Absprache
        </div>
        <div key="ab" className={`click-button-medium ${selectType === 'ab' ? 'highlighted' : ''}`} onClick={() => handleSelectCheckbox()}>
          Ab ...
        </div>
      </div>

      <div className="padding-10"></div>
      {selectCheckbox && (
        <FlendlyValidateField
          label="Datum"
          id="arbeitnehmer-zeitpunkt"
          name="zeitpunkt"
          data-cy="zeitpunkt"
          type="date"
          onChange={event => handleSelectZeitpunkt(event.target.value, 'ab')}
          value={selectZeitpunkt}
        />
      )}
    </div>
  );
};
