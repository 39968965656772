export enum License {
  PKW_B = 'PKW (B)',
  PKW_BE = 'PKW + Anhänger (BE)',
  C1 = 'LKW 7,5 (C1)',
  C1E = 'LKW 7,5 + Anhänger (C1E)',
  C = 'LKW (C)',
  CE = 'LKW + Anhänger (CE)',
  AKTUELL_KEINEN = 'Aktuell keinen',
  KEINEN = 'Keinen',
}
