import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section className="section">
        <div className="container-std w-container">
          <div className="padding-40"></div>
          <h1 className="heading-1">Flendly Blog</h1>
          <div className="paragraph-std">
            Hier finden Sie nützliche Infos zu allen Themen rund um Bewerbung, Personal und Geschäftsführung: Tipps und Analysen mit denen
            Mitarbeiter neue Unternehmen und Unternehmen neue Mitarbeiter finden können.
          </div>
          <h1 className="heading-3">Themen für Arbeitnehmer</h1>
          <div className="padding-5"></div>
          <div className="w-layout-grid grid-3298">
            <Link to={`/blog/josuche-im-handwerk`} className="link-block-17 w-inline-block">
              <div className="text-block-29">
                Jobsuche im Handwerk und der Industrie: <br />
                Der Weg zum richtigen Job
              </div>
            </Link>
            <Link to={`/blog/bewerbungsgespräch`} className="link-block-17 w-inline-block">
              <div className="text-block-29">Worauf es ankommt, wenn´s drauf ankommt: Tipps fürs Bewerbungsgespräch</div>
            </Link>
            <Link to={`/blog/karriere-im-handwerk`} className="link-block-17 w-inline-block">
              <div className="text-block-29">Karriere im Handwerker und der Industrie: Worauf es ankommt</div>
            </Link>
            <Link to={`/blog/gehalt-verhandlung`} className="link-block-17 w-inline-block">
              <div className="text-block-29">
                Die Gehaltsverhandlung: <br />
                Schwieriges Thema?
              </div>
            </Link>
          </div>
          <div className="padding-30"></div>
          <h1 className="heading-3">Themen für Unternehmen</h1>
          <div className="padding-5"></div>

          <div className="w-layout-grid grid-3298">
            <Link to={`/blog/mitarbeiter-gewinnen`} className="link-block-17 w-inline-block">
              <div className="text-block-29">
                Effektive Mitarbeitergewinnung im Handwerk: <br />
                Wie Sie Fachkräfte finden &amp; binden
              </div>
            </Link>
            <Link to={`/blog/mitarbeiter-ueberzeugen`} className="link-block-17 w-inline-block">
              <div className="text-block-29">
                Bewerber von sich überzeugen: <br />
                Tipps für den Bewerbungsprozess
              </div>
            </Link>
            <Link to={`/blog/arbeitgeber-standort`} className="link-block-17 w-inline-block">
              <div className="text-block-29">
                Arbeitgeber am Standort Deutschland:
                <br />
                Zukunftsfähig oder Auslaufmodell?
              </div>
            </Link>
            <Link to={`/blog/digitalisierung-im-handwerk`} className="link-block-17 w-inline-block">
              <div className="text-block-29">
                Digitalisierung im Handwerk:
                <br />
                Chancen und Herausforderungen
              </div>
            </Link>
          </div>
          <div className="padding-40"></div>
        </div>
      </section>
    </div>
  );
};
