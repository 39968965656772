import React, { useState } from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';
import { ACCOUNT, MATCHES, SUCHE } from 'app/shared/layout/header/header-components';
import { useAppSelector } from 'app/config/store';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export const ArbeitnehmerEntitiesMenu = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <>
      <SUCHE navlink={'/suche/arbeitnehmer'} />
      <MATCHES navlink={'/matches/arbeitnehmer'} />

      <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
        <div className={'flendly-nav-item'}>
          <img
            src="../../../../content/images/profile.png"
            loading="lazy"
            width="27"
            alt="Ein stilisiertes EinstellungenArbeitnehmer-Symbol, bestehend aus vier im Quadrat angeordneten Puzzle Teilen. Das Symbol repräsentiert die Matches Übersicht ."
            className="image-icon-navbar"
          />
          <DropdownToggle nav caret>
            KONTO
          </DropdownToggle>
        </div>
        <DropdownMenu className={'dropdown-list w-dropdown-list'}>
          {/*<DropdownItem header>Header</DropdownItem>*/}
          {/*<DropdownItem disabled>Action</DropdownItem>*/}
          <DropdownItem>
            <Link to={'/profil/arbeitnehmer'} className="w-nav-link">
              MEIN PROFIL
            </Link>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            <Link to={'/arbeitnehmer/einstellungen'} className="w-nav-link">
              EINSTELLUNGEN
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to={'/arbeitnehmer/sicherheit'} className="w-nav-link">
              SICHHERHEIT
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export const UnternehmeneEntitiesMenu = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <>
      <>
        <SUCHE navlink={'/suche/arbeitgeber/profiles'} />
        <MATCHES navlink={'/matches/arbeitgeber'} />

        <Dropdown className=" flex-content-cust" isOpen={dropdownOpen} toggle={toggle}>
          <div className={'flendly-nav-item '}>
            <img
              src="../../content/images/profile.png"
              loading="lazy"
              width="27"
              alt="Ein stilisiertes EinstellungenArbeitnehmer-Symbol, bestehend aus vier im Quadrat angeordneten Puzzle Teilen. Das Symbol repräsentiert die Matches Übersicht ."
              className="image-icon-navbar"
            />
            <DropdownToggle nav caret className={'headline-0 w-nav-link'}>
              KONTO
            </DropdownToggle>
          </div>
          <DropdownMenu className={'dropdown-list w-dropdown-list'}>
            {/*<DropdownItem header>Header</DropdownItem>*/}
            {/*<DropdownItem disabled>Action</DropdownItem>*/}
            <DropdownItem>
              <Link to={'/profil/arbeitgeber'} className="w-nav-link">
                MEIN PROFIL
              </Link>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              <Link to={'/arbeitgeber/einstellungen'} className="w-nav-link">
                EINSTELLUNGEN
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link to={'/arbeitgeber/sicherheit'} className="w-nav-link">
                SICHHERHEIT
              </Link>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </>
    </>
  );
};

export const ArbeitgeberMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/arbeitnehmersuche">
        <Translate contentKey="global.menu.entities.arbeitnehmersuche"/>
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};
