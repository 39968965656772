import arbeitnehmer from 'app/shared/reducers/arbeitnehmer.reducer';
import suchkriterien from 'app/shared/reducers/suchkriterien.reducer';
import arbeitgeber from 'app/shared/reducers/arbeitgeber.reducer';
import match from 'app/shared/reducers/match.reducer';
import arbeitnehmersuche from 'app/shared/reducers/arbeitnehmersuche.reducer';
import recommendation from 'app/modules/suche/recommendation/recommendation.reducer';
import angebot from 'app/shared/reducers/angebot.reducer';
import notifications from 'app/shared/reducers/notification.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  arbeitnehmer,
  suchkriterien,
  arbeitgeber,
  match,
  arbeitnehmersuche,
  recommendation,
  angebot,
  notifications,
};

export default entitiesReducers;
