import React, { FunctionComponent, useEffect, useState } from 'react';
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import { ISprache } from 'app/shared/model/sprache.model';
import { toast } from 'react-toastify';

interface ISprachAuswahlProps {
  onAuswahlChange: any;
  initialValue: ISprache[];
  title: string;
}

export const SprachenAuswahl: FunctionComponent<ISprachAuswahlProps> = ({ onAuswahlChange, initialValue, title }) => {
  const [selectAllSprachen, setAllSelectSprachen] = useState([]);
  const [selectSpache, setSelectSprache] = useState('');
  const [selectNiveau, setSelectNiveau] = useState('');

  useEffect(() => {
    if (initialValue && initialValue.length > 0) {
      if (!arraysAreEqual(selectAllSprachen, initialValue)) {
        setAllSelectSprachen(initialValue);
        onAuswahlChange(initialValue);
      }
    } else {
      if (selectAllSprachen.length > 0) {
        setAllSelectSprachen([]);
        onAuswahlChange([]);
      }
    }
  }, [initialValue]);

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  const handleAddLanguage = (sSprache, sNiveau) => {
    if (sSprache === '' || sNiveau === '') {
      return;
    }

    const newLanguage = {
      sprache: sSprache,
      niveau: sNiveau,
    };

    let existingLanguage = selectAllSprachen.find(item => item.sprache === newLanguage.sprache);

    if (existingLanguage) {
      toast.info('Die Sprache wurde bereits hinzugefügt');
    } else {
      const neueAuswahl = selectAllSprachen.includes(newLanguage)
        ? selectAllSprachen.filter(item => item !== newLanguage)
        : [...selectAllSprachen, newLanguage];
      setAllSelectSprachen(neueAuswahl);
      onAuswahlChange(neueAuswahl);
    }

    // Setzt den Zustand der Dropdowns wieder auf die anfänglichen Werte
    setSelectSprache('');
    setSelectNiveau('');
  };

  const handleRemoveLanguage = language => {
    const neueAuswahl = selectAllSprachen.filter(item => item !== language);
    setAllSelectSprachen(neueAuswahl);
    onAuswahlChange(neueAuswahl);
  };

  function handleSpracheChange(event) {
    setSelectSprache(event.target.value);
    handleAddLanguage(event.target.value, selectNiveau);
  }

  function handleNiveauChange(event) {
    setSelectNiveau(event.target.value);
    handleAddLanguage(selectSpache, event.target.value);
  }

  return (
    <div id="employer_reg_10">
      <p className="paragraph-workflow">
        <strong>{title}</strong>
      </p>
      <div className="padding-10"></div>

      <div>
        <div data-fl-num-cols="2" data-fl-type="list" className="w-layout-grid grid-sprache">
          <FormGroup className="form-group-full">
            <Label for="sprachenSelect" className="field-label-std">
              Sprache
            </Label>
            <Input
              className="select-field-45px"
              id="sprachenSelect"
              name="select"
              type="select"
              onChange={handleSpracheChange}
              value={selectSpache}
              style={{
                color: selectSpache ? 'black' : 'gray',
              }}
            >
              <option value="">Sprache</option>
              <option value="Deutsch">Deutsch</option>
              <option value="Türkisch">Türkisch</option>
              <option value="Polnisch">Polnisch</option>
              <option value="Rumänisch">Rumänisch</option>
              <option value="Griechisch">Griechisch</option>
              <option value="Englisch">Englisch</option>
              <option value="Tschechisch">Tschechisch</option>
              <option value="Kroatisch">Kroatisch</option>
              <option value="Russisch">Russisch</option>
              <option value="Italienisch">Italienisch</option>
              <option value="Ungarisch">Ungarisch</option>
              <option value="Bulgarisch">Bulgarisch</option>
              <option value="Spanisch">Spanisch</option>
              <option value="Keine davon">Keine davon</option>
            </Input>
          </FormGroup>

          <FormGroup
            style={{
              width: '100%',
            }}
          >
            <Label for="niveauSelect" className="field-label-std">
              Niveau
            </Label>
            <Input
              id="niveauSelect"
              name="select"
              type="select"
              onChange={handleNiveauChange}
              value={selectNiveau}
              className="select-field-45px"
              style={{
                color: selectNiveau ? 'black' : 'gray',
              }}
            >
              <option value="">Sprachniveau</option>
              <option value="Grundkenntnisse">Grundkenntnisse</option>
              <option value="Anfänger">Anfänger</option>
              <option value="Fortgeschritten">Fortgeschritten</option>
              <option value="Fließend">Fließend</option>
              <option value="Muttersprache">Muttersprache</option>
            </Input>
          </FormGroup>
        </div>
      </div>

      {selectAllSprachen.map((sprachenAuswahl, index) => (
        <Card
          className="flendly-card-list-item"
          key={index}
          style={{
            width: '100%',
          }}
        >
          <CardBody
            style={{
              backgroundColor: '#f5f7fa',
            }}
          >
            <div className="row">
              <div className="col-11">
                <div id="w-node-_5541b32b-e4d1-5e5b-603b-a5e6d34b6778-8d3b9927" style={{ width: '100%' }} className="sprache-select">
                  <div className="text-block-search-profile">
                    {sprachenAuswahl.sprache} ({sprachenAuswahl.niveau})
                  </div>
                </div>
              </div>

              <div
                className="col-1 d-flex align-items-center justify-content-center"
                style={{
                  paddingLeft: '0px',
                  paddingRight: '0px',
                }}
              >
                <a
                  id="w-node-a1bd1d02-9b8d-f561-886c-98bfb039018a-8d3b9927"
                  onClick={() => handleRemoveLanguage(sprachenAuswahl)}
                  className="btn-bar-card"
                >
                  <img
                    src="../../../content/images/delete2.PNG"
                    height={20}
                    width={20}
                    loading="lazy"
                    alt="Ein Symbol eines Papierkorbs, das das Löschen von Inhalten symbolisiert. Das Symbol zeigt einen stilisierten Papierkorb, der darauf hinweist, dass der Inhalt endgültig entfernt wird."
                    className="image-15"
                  />
                </a>
              </div>
            </div>
          </CardBody>
        </Card>
      ))}
    </div>
  );
};
