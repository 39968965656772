import dayjs from 'dayjs';

import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? dayjs(date).toDate() : null);

export const displayDefaultDateTime = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const getAgeByDateOfBirth = (geburtsdatum: string | '') => {
  if (geburtsdatum !== '') {
    const today = new Date();
    const birthDate = new Date(geburtsdatum);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  } else {
    return geburtsdatum;
  }
};

export const getDateByTimestamp = (created: number) => {
  const date = new Date(created);
  return date.toLocaleDateString();
};
