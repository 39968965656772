import React from 'react';

interface FlendlyValidateFieldProps {
  inputClass?: string;
  labelClass?: string;
  label: string;
  id?: string;
  name: string;
  type?: string;
  placeholder?: string;
  value?;
  onChange: (value: any) => void;
  validate?: (value: any) => boolean | string;
  headline?: string;
  wrapperClass?: string;
}

export const FlendlyValidateField: React.FC<FlendlyValidateFieldProps> = ({
  inputClass = 'text-field-45px w-input',
  labelClass = 'field-label-std',
  label,
  id = 'form-field',
  name,
  type = 'text',
  placeholder = '',
  onChange,
  validate = null,
  value = '',
  wrapperClass = 'wrapper-460px',
  headline,
}) => {
  const [valid, setValid] = React.useState(true);

  const onValidateAndChange = val => {
    if (validate && typeof validate === 'function') {
      const validationResult = validate(val.target.value);

      if (typeof validationResult === 'string') {
        console.log('validationResult', validationResult);
        setValid(false);
      } else {
        setValid(true);
      }
    }
    onChange(val);
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {headline && (
        <div>
          <p className="paragraph-workflow">
            <strong>{headline}</strong>
          </p>
          <div className="padding-10"></div>
        </div>
      )}

      <div className={wrapperClass}>
        <label htmlFor={id} className={labelClass}>
          <strong>{label}</strong>
        </label>
        <input
          className={inputClass}
          maxLength={256}
          name={name}
          data-name={name}
          placeholder={placeholder}
          type={type}
          id={id}
          required={true}
          onChange={onValidateAndChange}
          value={value}
        />
        {!valid && <div className="error_message w-node-_34e9b4c4-4b8e-3a16-305b-7ccac5332ae2-1e4981b7">Please enter a valid {label}</div>}
      </div>
    </div>
  );
};

export default FlendlyValidateField;
