import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IAnfrage } from 'app/shared/model/anfrage.model';
import { Anfrage } from 'app/modules/notifications/anfrage';

const initialState: EntityState<IAnfrage> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/anfrage';

// Actions

export const getAnfrage = createAsyncThunk(
  'angebot/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IAnfrage>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const sendAnfrage = createAsyncThunk(
  'anfrage/create_entity',
  async (entity: IAnfrage, thunkAPI) => {
    return await axios.post<IAnfrage>(apiUrl + '/send', cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const acceptAnfrage = createAsyncThunk(
  'angebot/accept_entity',
  async (entity: IAnfrage, thunkAPI) => {
    return await axios.post<IAnfrage>(`${apiUrl}/accept`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const rejectAnfrage = createAsyncThunk(
  'angebot/reject_entity',
  async (entity: IAnfrage, thunkAPI) => {
    return await axios.post<IAnfrage>(`${apiUrl}/reject`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const AnfrageSlice = createEntitySlice({
  name: 'angebot',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAnfrage.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(sendAnfrage), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(sendAnfrage), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = AnfrageSlice.actions;

// Reducer
export default AnfrageSlice.reducer;
