import React from 'react';
import { Link } from 'react-router-dom';

export const BlogGehalt = () => {
  return (
    <div>
      <section className="section">
        <div className="container-14 w-container">
          <div className="padding-40"></div>
          <h1 className="heading-2 left">
            Die Gehaltsverhandlung: <br />
            Ein schwieriges Thema?
          </h1>
          <p className="paragraph-std left-copy">
            Gehaltsverhandlungen können in jedem Berufsfeld eine Herausforderung darstellen, doch im Handwerk und in der Fertigungsindustrie
            gibt es spezifische Faktoren, die sie besonders heikel machen können. Diese Branchen sind oft von traditionellen Strukturen,
            Tarifverträgen und einer starken Fokussierung auf praktische Fähigkeiten geprägt. Dieser Artikel bietet praktische Tipps und
            Überlegungen, die Ihnen helfen können, eine Gehaltsverhandlung erfolgreich zu meistern.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Kenntnis des Marktwertes</h2>
          <p className="paragraph-std left-copy">
            Bevor Sie in eine Gehaltsverhandlung gehen, ist es entscheidend, Ihren Marktwert zu kennen. Recherchieren Sie, was Fachkräfte
            mit Ihrer Qualifikation und Erfahrung in Ihrer Region im Handwerk und in der Fertigungsindustrie üblicherweise verdienen.
            Berücksichtigen Sie Faktoren wie Spezialisierung, Nachfrage und die wirtschaftliche Lage der Branche. <br />
            <br />
            Eine sehr gute Variante ist es auch sich bei einer Jobplattform wie <a href="/">Flendly</a> anzumelden. Flendly bietet Ihnen die
            Möglichkeit dort für Ihren aktuellen Arbeitgeber unsichtbar zu sein, d.h. Sie können auswählen welche Unternehmen Ihr Profil
            nicht sehen dürfen. Dadurch prüfen Sie Ihren Marktwert ohne das Risiko einzugehen, bei Ihrem aktuellen Arbeitgeber in eine
            unangenehme Situation zu kommen. <br />
            Sie werden dann schnell sehen, welche Angebote Sie von anderen Unternehmen erhalten und dadurch wissen, was Ihr Marktwert ist.
            Websites für Gehaltsvergleiche, Berufsverbände und Netzwerke können ebenfalls wertvolle Informationsquellen sein.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Verständnis für den Betrieb</h2>
          <p className="paragraph-std left-copy">
            Jeder Betrieb, ob Handwerksbetrieb oder Produktionsunternehmen, hat seine eigenen finanziellen Grenzen und Herausforderungen.
            Ein kleiner Handwerksbetrieb mag nicht die gleichen Gehälter bieten können wie ein großes Produktionsunternehmen. Verstehen Sie
            die finanzielle Situation und die Gehaltsstrukturen des Unternehmens, bei dem Sie sich bewerben oder beschäftigt sind, und
            passen Sie Ihre Erwartungen entsprechend an.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Vorbereitung und Timing</h2>
          <p className="paragraph-std left-copy">
            Die Vorbereitung ist der Schlüssel zu einer erfolgreichen Gehaltsverhandlung. Sammeln Sie Belege für Ihre Leistungen,
            Qualifikationen und zusätzlichen Verantwortlichkeiten, die Sie übernommen haben. Timing ist ebenfalls wichtig. Eine
            Gehaltsverhandlung ist beispielsweise nach einem erfolgreich abgeschlossenen Projekt oder bei der Übernahme neuer Aufgaben am
            wirkungsvollsten.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Klare Argumentation</h2>
          <p className="paragraph-std left-copy">
            Gehen Sie mit klaren und nachvollziehbaren Argumenten in die Verhandlung. Stellen Sie dar, wie Sie zum Erfolg des Unternehmens
            beitragen und welche einzigartigen Fähigkeiten Sie mitbringen. Vermeiden Sie es, Ihre Forderungen auf persönliche Bedürfnisse zu
            stützen. Stattdessen sollte der Fokus darauf liegen, was Sie leisten und wie Sie den Wert des Unternehmens steigern.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Flexibilität zeigen</h2>
          <p className="paragraph-std left-copy">
            Seien Sie offen für Kompromisse. Manchmal ist es nicht möglich, das gewünschte Gehalt sofort zu erreichen. In solchen Fällen
            können alternative Kompensationen wie zusätzliche Urlaubstage, Fortbildungsmöglichkeiten oder eine einmalige Prämie eine Lösung
            sein. Zeigen Sie sich flexibel und offen für kreative Lösungen.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Professionelles Auftreten</h2>
          <p className="paragraph-std left-copy">
            Führen Sie die Verhandlung professionell und respektvoll. Auch wenn das Thema Gehalt emotional aufgeladen sein kann, ist eine
            sachliche und ruhige Herangehensweise erfolgversprechender. Bedenken Sie, dass Ihr Gegenüber auch innerhalb bestimmter Grenzen
            agiert.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Bereitschaft, auch Nein zu sagen</h2>
          <p className="paragraph-std left-copy">
            Manchmal kommen die Verhandlungen zu keinem zufriedenstellenden Ergebnis. Es ist wichtig, zu wissen, wann es Zeit ist, sich
            zurückzuziehen oder sogar bereit zu sein, nach anderen Möglichkeiten Ausschau zu halten, falls Ihre Gehaltsvorstellungen und die
            Wertschätzung Ihrer Arbeit nicht in Einklang gebracht werden können.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Fazit</h2>
          <p className="paragraph-std left-copy">
            Gehaltsverhandlungen im Handwerk und in der Fertigungsindustrie müssen kein schwieriges Thema sein, wenn Sie gut vorbereitet
            sind, Ihren Marktwert kennen und eine klare, auf Fakten basierende Argumentation haben. Durch Verständnis für den Betrieb,
            professionelles Auftreten und Flexibilität können Sie Ihre Chancen auf eine erfolgreiche Verhandlung erhöhen.
          </p>
          <div className="padding-30"></div>
        </div>
      </section>
    </div>
  );
};

export const BlogJobsucheImHandwerk = () => {
  return (
    <div>
      <section className="section">
        <div className="container-14 w-container">
          <div className="padding-40"></div>
          <h1 className="heading-2 left">
            Jobsuche im Handwerk und der Industrie: <br />
            Der Weg zum richtigen Job
          </h1>
          <p className="paragraph-std left-copy">
            In der dynamischen Welt des Handwerks und der Industrie ist die Suche nach dem passenden Job eine Herausforderung, die spannend
            aber auch entmutigend sein kann. In der Zeit des Fachkräftemangels ist es in der Regel nicht schwer Jobs im Handwerk oder der
            Industrie zu finden, die Frage ist vielmehr wie man den richtigen findet. <br />
            In diesem Artikel teilen wir wertvolle Tipps und Tricks, die Ihre Jobsuche effizienter und vor allem zielgerichteter machen.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Kenne deine Stärken, noch mehr aber Deine Interessen</h2>
          <p className="paragraph-std left-copy">
            Ob Sie gerade erst Ihre Karriere beginnen, einen Wechsel anstreben oder nach einer längeren Pause wieder einsteigen möchten, der
            Schlüssel zum Erfolg liegt in der Herangehensweise. <br />
            Bevor Sie mit der Suche beginnen, ist es wichtig, eine klare Vorstellung von Ihren Interessen, Fähigkeiten und ggf.
            Zusatzqualifikationen zu haben. Im Handwerk und in der produzierenden Industrie gibt es eine breite Palette von Berufen, von
            traditionellen Handwerksberufen bis hin zu modernen Fertigungsjobs. In Zeiten des Fachkräftemangels können Sie es sich praktisch
            aussuchen was Sie machen möchten. Fragen Sie sich also zuallererst was Ihnen Spaß macht, was wollten Sie schon immer mal machen,
            selbst wenn es nicht zu 100% Ihrer Ausbildung entspricht. Danach prüfen Sie ob Ihre aktuelle Qualifikation einen Berufseinstieg
            leicht machen würde oder sehr schwer. Dazu bietet es sich an einfach zu einem entsprechenden Unternehmen Kontakt aufzunehmen.
            Fragen Sie nach, das Unternehmen wird sich über den potentiellen Bewerber freuen!
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Lebenslauf und Anschreiben anpassen</h2>
          <p className="paragraph-std left-copy">
            Ein schöner Lebenslauf und überzeugendes Anschreiben sind für viele Unternehmen sicherlich wichtig. Viel wichtiger sind
            heutzutage jedoch Sie selbst. Scheuen Sie sich nicht vor persönlichem Kontakt. Rufen Sie an, bieten Sie an zu einem
            unverbindlichen Gespräch vorbei zu kommen um sich zu präsentieren. Seien Sie pünktlich und freundlich. Bieten Sie ggf. an 1 oder
            2 Tage probezuarbeiten um sich gegenseitig kennenzulernen. 90% der Unternehmen suchen händeringend nach Mitarbeiten. Ich
            verspreche Ihnen, jedes Unternehmen wird sich über Ihre Proaktivität freuen, selbst ohne einen Lebenslauf.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Nutzen Sie spezialisierte Jobportale</h2>
          <p className="paragraph-std left-copy">
            Allgemeine Jobportale können nützlich sein, aber spezialisierte Plattformen für das Handwerk und die Industrie erhöhen Ihre
            Chancen und Möglichkeiten, auch den für Sie richtigen Job zu finden. <br />
            Bei <a href="arbeitnehmer.html">Flendly</a> haben wir eine Jobplattform gebaut, die sich zum einen rein auf Handwerk und
            Industrie spezialisiert hat und zum anderen Sie als Fachkraft in den Vordergrund stellt. Bei uns gibt es keinen Lebenslauf
            Upload oder ein Anschreiben. Wir fokussieren uns darauf was Sie machen wollen und was Ihnen in einem Unternehmen wichtig ist.
            Auf dieser Basis schlagen wir Ihnen passende Unternehmen vor.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Social Media und Online-Präsenz</h2>
          <p className="paragraph-std left-copy">
            In der heutigen digitalen Welt kann es von großem Vorteil sein, wenn Sie beispielsweise bei Facebook Ihren Beruf oder die
            entsprechende Branche angeben und ggf. passenden Gruppen beitreten. Weiß Facebook von Ihrem Beruf oder Ihren Interessen, werden
            Ihnen auch passende Inhalte wie Jobangebote etc. in den Werbebannern angezeigt. Selbst wenn Sie aktuell nicht wechseln wollen,
            kann es interessant sein, was es am Jobmarkt alles so gibt.
            <br />
            Bewerben Sie sich hin und wieder, selbst wenn Sie aktuell eigentlich zufrieden sind. So bekommen Sie interessante Einblicke und
            kennen stets Ihren persönlichen Marktwert. Vielleicht führt das am Ende sogar zu einem höheren Gehalt beim aktuellen
            Arbeitgeber.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Fazit</h2>
          <p className="paragraph-std left-copy">
            Die Jobsuche im Handwerk und in der produzierenden Industrie erfordert Geduld und eine strategische Vorgehensweise. Indem Sie
            diese Tipps und Tricks anwenden, können Sie Ihre Chancen verbessern, den Job zu finden, der nicht nur zu Ihren Fähigkeiten
            passt, sondern auch Ihre Leidenschaft weckt. Denken Sie daran, offen für neue Möglichkeiten zu sein und sich kontinuierlich
            weiterzuentwickeln, um in diesen sich schnell verändernden Branchen erfolgreich zu sein.
          </p>
          <div className="padding-30"></div>
        </div>
      </section>
    </div>
  );
};

export const BlogBewerbungsgespräch = () => {
  return (
    <div>
      <section className="section">
        <div className="container-14 w-container">
          <div className="padding-40"></div>
          <h1 className="heading-2 left">Worauf es ankommt, wenn´s drauf ankommt: Tipps fürs Bewerbungsgespräch</h1>
          <p className="paragraph-std left-copy">
            In den Zeiten von ausuferndem Fachkräftemangel haben Sie als Fachkraft aus Handwerk oder Industrie alle Trümpfe in Ihrer Hand.
            Sie können sich die Jobs und Arbeitgeber aussuchen, Sie müssen sich keine Mühe für die Bewerbung oder das Bewerbungsgespräch
            geben und erhalten den Job aller Voraussicht dennoch. <br />
            Warum es sich trotzdem lohnt viel Energie in eine Bewerbung zu stecken werde ich Ihnen im Folgenden erläutern.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Den Richtigen finden</h2>
          <p className="paragraph-std left-copy">
            Wie schon im Beitrag „
            <Link to={`/blog/josuche-im-handwerk`}>Jobsuche im Handwerk und der Industrie: Der Weg zum richtigen Job</Link>“ beschrieben
            sollten Sie sich bei der Jobsuche zuallererst fragen: was möchte ich eigentlich machen? Allzu oft hat man schon seit Jahren Lust
            auf etwas anderes. Ergründen Sie also zuerst Ihre Bedürfnisse. Der Fachkräftemangel öffnet Ihnen Türen die Sie sonst vielleicht
            nie hätten öffnen können. Nutzen Sie das! <br />
            Quereinsteiger sind mittlerweile oft genauso willkommen wie gelernte Mitarbeiter. Und wenn Ihr bisheriger Job, Ihre Hobbies oder
            sonstiges vielleicht eine gewisse Ähnlichkeit oder einen Überschnitt mit dem Wunschberuf hat, let´s go!
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Oberstes Gebot: Vollgas!</h2>
          <p className="paragraph-std left-copy">
            Wenn Sie erstmal wissen, was Sie wollen, dann gilt es loszulegen. Und egal ob Sie Quereinsteiger oder erfahrene Fachkraft sind:
            Geben Sie immer 100%!
            <br />
            Das oberste Ziel eines Bewerbungsprozesses ist nicht, dass Sie den Job bekommen, sondern immer, dass Sie den Job unter den
            bestmöglichen Konditionen erhalten. Und das wird nur dann der Fall sein, wenn der potentielle Arbeitgeber Sie auch für die
            bestmögliche Besetzung hält.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Kontaktaufnahme</h2>
          <p className="paragraph-std left-copy">
            Jeder Bewerbungsprozess beginnt mit der Kontaktaufnahme. Die meisten Menschen schreiben gerne Emails oder Briefe um Kontakt
            aufzunehmen. Sie tun das gerne, weil es einfach ist. Telefonate bedeuten für viele Menschen Stress, ein unangenehmes Gefühl
            jemand fremdes zu kontaktieren und um etwas zu bitten. <br />
            Überwinden Sie das! <br />
            Rufen Sie dort an, seien Sie freundlich und proaktiv und zeigen Sie, dass Sie Interesse an diesem Unternehmen haben. Bitten Sie
            um einen persönlichen Termin, um sich dem Unternehmen vorstellen zu können und keiner wird Ihnen diesen Wunsch abschlagen.
            <br />
            Und wenn Ihnen das schwerfällt, schauen Sie sich vor dem Telefonat eine Folge „Better Call Saul“. Der Schauspieler Bob Odenkirk
            verkörpert in seiner Rolle „Saul Goodman“ mit jeder Faser seines Körpers das Wort Proaktivität und Vollgas.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Nach der Terminvereinbarung ist vor der Terminvorbereitung</h2>
          <p className="paragraph-std left-copy">
            Eine gründliche Vorbereitung ist die halbe Miete. <br />
            Informieren Sie sich über das Unternehmen, seine Kultur, seine Produkte oder Dienstleistungen und aktuelle Entwicklungen in der
            Branche. Verstehen Sie die Anforderungen der Stelle genau und denken Sie darüber nach, wie Ihre Fähigkeiten und Erfahrungen dazu
            passen. Diese Recherche hilft Ihnen nicht nur, fundierte Antworten zu geben, sondern zeigt auch Ihr echtes Interesse an der
            Position und dem Unternehmen. Machen Sie sich bereits vor dem Gespräch eine Liste, warum Sie die beste Wahl für diese Stelle
            sind.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Die erste Minute zählt</h2>
          <p className="paragraph-std left-copy">
            Nicht nur bei der Partnersuche gilt: Der erste Eindruck ist entscheidend. <br />
            Achten Sie auf eine angemessene Kleidung, die der Unternehmenskultur entspricht, seien Sie pünktlich und bringen Sie eine
            positive Ausstrahlung mit. Ein fester Händedruck, ein freundliches Lächeln und Augenkontakt beim Grüßen setzen den Ton für ein
            positives Gespräch.
            <br />
            Achten Sie zudem auf Ihre Körpersprache. Eine offene Haltung ist dabei enorm wichtig. Vermeiden Sie nervöse Gesten oder zu viele
            Bewegungen und halten Sie Augenkontakt. Eine offene Körpersprache kann Ihre verbalen Aussagen unterstreichen und den
            Gesamteindruck verbessern.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Nicht nur Sie müssen überzeugen</h2>
          <p className="paragraph-std left-copy">
            Ein Bewerbungsgespräch ist eine Zwei-Wege-Straße. Es geht nicht nur darum, dass Sie einen guten Eindruck machen, sondern auch ob
            das Unternehmen einen guten Eindruck macht und zu Ihnen passt. <br />
            Darum stellen Sie Fragen. Durch das Stellen eigener Fragen demonstrieren Sie Interesse und bekommen gleichzeitig wertvolle
            Einblicke in das Unternehmen und die Position. Stellen Sie Fragen zur Unternehmenskultur, dem Team, Auftragslage usw.
            <br />
            Und wenn Sie mit dem Chef oder einer Führungskraft sprechen, achten Sie selbst auch auf dessen Auftreten. Ist Ihr
            Gesprächspartner symaptisch oder fällt es Ihnen schwer eine Wellenlänge zu finden? Was im Bewerbungsgespräch nicht gelingt,
            klappt später im Job nicht ungebdingt besser.
            <br />
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Authentizität</h2>
          <p className="paragraph-std left-copy">
            Bei aller Vorbereitung gilt dennoch immer: seien Sie authentisch! Versuchen Sie nicht, jemand zu sein, der Sie nicht sind, nur
            um den Job zu bekommen. Wenn Sie sich verstellen müssen um den Job zu erhalten werden Sie in diesem Unternehmen sicher auch
            nicht glücklich. <br />
            Letztendlich geht es doch darum, dass beide Seiten herausfinden, ob Sie wirklich gut zueinander passen. Zudem sind die meisten
            Menschen schlechte Schauspieler und Authentizität kommt daher meist wesentlich besser an. Natürlich sollten Sie stets
            professionell bleiben und Ihre Antworten und Verhaltensweisen den Rahmenbedingungen des Gesprächs anpassen.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Nach dem Gespräch</h2>
          <p className="paragraph-std left-copy">
            Senden Sie eine Dankes-E-Mail oder einen Brief, um sich für das Gespräch zu bedanken und Ihr fortwährendes Interesse an der
            Position zu bekräftigen. Dies kann nicht nur einen positiven letzten Eindruck hinterlassen, sondern auch Ihre Chancen erhöhen,
            in Erinnerung zu bleiben.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Proaktivität rettet (fast) alles</h2>
          <p className="paragraph-std left-copy">
            In einem Bewerbungsprozess kann also viel schiefgehen. Und wenn viel schiefgehen kann, dann passiert es manchmal eben auch, dass
            viel schiefgeht und man evtl. eine Absage erhält.
            <br />
            <br />
            Und da erinnere ich mich immer an die Geschichte meines Freundes Till. <br />
            Till hat sich auf seinen absoluten Traumjob beworben und war beim Bewerbungsgespräch dann so nervös, dass er es wirklich
            komplett vergeigt hat. 2 Tage später kam dann auch die erwartete Absage. Soweit so gut. <br />
            Für 99,9% aller Menschen wäre das Thema hier erledigt gewesen. Nicht für Till!
            <br />
            Er hat den Hörer in die Hand genommen, den Geschäftsführer des Unternehmens kontaktiert und angeboten, als Praktikant
            anzufangen, um so mit seiner Arbeit doch noch von sich zu überzeugen. Das ist der Spirit der jeden Arbeitgeber beeindruckt. Der
            Geschäftsführer war beeindruckt von diesem Engagement und hat zugestimmt. Nach 4 Wochen als Praktikant hatte Till seinen
            regulären Arbeitsvertrag und seinen Traumjob in der Tasche. So geht Proaktivität!
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Fazit</h2>
          <p className="paragraph-std left-copy">
            Ein erfolgreiches Bewerbungsgespräch erfordert Vorbereitung, Selbstbewusstsein und Authentizität. Indem Sie sich über das
            Unternehmen informieren, strategisch auf Fragen antworten, selbst Fragen stellen und eine positive Körpersprache zeigen, können
            Sie Ihre Chancen erheblich verbessern, nicht nur den gewünschten Job zu sichern, sondern auch für die bestmöglichen Konditionen
            zu arbeiten. Und sollte es trotz Vorbereitung und Proaktivität doch einmal nicht klappen, denken Sie daran, dass jedes Gespräch
            auch eine Lernmöglichkeit ist, also fragen Sie nach Feedback und nutzen Sie es um es beim nächsten Mal besser zu machen.
          </p>
          <div className="padding-10"></div>
          <div className="padding-30"></div>
        </div>
      </section>
    </div>
  );
};

export const BlogKarriereImHandwerk = () => {
  return (
    <div>
      <section className="section">
        <div className="container-14 w-container">
          <div className="padding-40"></div>
          <h1 className="heading-2 left">Karriere im Handwerk und der Industrie: Worauf es ankommt</h1>
          <p className="paragraph-std left-copy">
            Am Anfang jeder Karriere steht immer die Selbstreflektion: wer bin ich und was will ich. <br />
            Diese Frage ist heute wichtiger denn je. In der Welt von Social Media und co. bekommen wir jeden Tag unzählige Eindrücke von
            Menschen bei denen es unglaublich grandios läuft, die sich so sehr selbstverwirklicht haben und so glücklich sind, dass sie
            platzen könnten. In so einer Welt fällt es uns sehr schwer in Lebenssituationen zu verharren, die uns eigentlich gar keine
            Freude bereiten. Unsere Toleranzschwelle gegen Fruststration ist sehr gering geworden. <br />
            Daher meine Empfehlung an Sie: finden Sie zu allererst heraus was Ihnen Freude macht!
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Systematische Selbstreflexion </h2>
          <p className="paragraph-std left-copy">
            Sich selbst du reflektieren ist grundsätzlich komplex und vielschichtig. Für die Wahl der passenden Karriere gilt es aber im
            wesentlichen folgende 3 Fragestellungen zu beantworten.
          </p>
          <p className="paragraph-std left-kursiv">1. Was macht mir Freude?</p>
          <p className="paragraph-std left-copy">
            Welche Arbeit macht mir Spaß. Mag ich es eher nur mit den Händen zu arbeiten, eher nur im Büro oder eine Mischung aus beidem?
            <br />
            Wenn Sie diese Frage für sich beantwortet haben, dann kennen Sie die Richtung in die es gehen soll.
          </p>
          <p className="paragraph-std left-kursiv">2. Was sind meine Werte und Bedürfnisse?</p>
          <p className="paragraph-std left-copy">
            Lebe ich für meine Familie und Freizeit und Arbeit ist ein notwendiges Übel das nebenbei zu erfüllen ist oder ist die Arbeit
            auch Ihre Leidenschaft und Sie genießen es morgens aus dem Haus zu gehen und zu Ihrer Arbeit zu gehen? <br />
            Sicherlich ist das in Extremen gesprochen und die Lebensrealität der meisten Menschen ist eine Mischung. Dennoch kann fast jeder
            sagen in welche Richtung er eher tendiert.
            <br />
            Diese Frage ist essentiell für die Planung Ihrer Karriere. Gehören Sie eher zum Typ „Freizeit“, dann wählen Sie Ihre
            Karriereschritte entsprechend. Suchen Sie Position die für Sie ansprechend sind, die aber stets den Freizeitwert erhalten.
            Bedenken Sie jedoch: Diese Frage ist auch abhängig vom Lebensabschnitt und sollte auch immer viele Jahre in die Zukunft gedacht
            werden. Sind Sie Anfang 30 und haben 2 kleine Kinder ist Ihnen die Freizeit vielleicht sehr wichtig. Sind die Kinder dann in der
            Pubertät sind Sie vielleicht auch wieder sehr froh einen spannenden und Herausfordernden Job zu haben.
          </p>
          <p className="paragraph-std left-kursiv">3. Arbeitgeber vs. Arbeitnehmer: was bin ich?</p>
          <p className="paragraph-std left-copy">
            Eine der vielen wunderbaren Ding im Handwerk (und teilweise auch der Fertigungsindustrie) ist, dass man sich relativ leicht
            selbstständig machen kann. Sie brauchen keine zündende Geschäftsidee oder ein großes Team von Mitarbeitern um zu starten. Sie
            können einfach etwas machen, das schon Millionen Menschen vor Ihnen gemacht haben und dabei trotzdem unglaublich erfolgreich
            sein. Daher sollte die letzte Frage Ihrer Überlegungen immer sein, bin ich der Typ Mensch der gerne selbständig wäre? <br />
            Selbstständigkeit heißt eigenverantwortliches Arbeiten, freie(re) Zeiteinteilung und viel Gestaltungsspielraum.
            <br />
            Auf der anderen Seite heißt es aber auch höhere Verantwortung, oft mehr Arbeit und sicher meistens auch mehr Druck. <br />
            Am Ende ist es eine Abwägung aber auch ein inneres Gefühl, ob das für Sie passen könnte. Wichtig ist aber auch hier: Es muss
            keine Entscheidung fürs Leben sein. Wenn es Ihre Lebensphase erlaubt, starten Sie etwas und testen ob das was für Sie ist.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Weiterbildung, Schulung und Zertifizierung</h2>
          <p className="paragraph-std left-copy">
            Unabhängig ob Sie sich nun selbständig machen oder nicht. Ein, wenn nicht sogar das zentrale Thema für Ihre Karriere ist die
            Weiterentwicklung in Form von Weiterbildungen, Schulungen und Zertifizierungen. Lebenslanges Lernen ist der Schlüssel zum
            beruflichen Erfolg. In einer sich ständig wandelnden Arbeitswelt sind kontinuierliche Weiterbildung und die Anpassung an neue
            Technologien unerlässlich. Nutzen Sie Fortbildungen und Zertifizierungen, um Ihre Fachkenntnisse zu vertiefen und sich von der
            Konkurrenz abzuheben. Achten Sie auch bei der Auswahl Ihres Arbeitgebers stets darauf, dass Weiterbildung ein zentrales Thema im
            Unternehmen ist und Sie nicht den Anschluss verlieren.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Proaktivität – das „Problemlöser Prinzip“</h2>
          <p className="paragraph-std left-copy">
            Aber nicht nur Weiterbildungen bringen Sie voran. <br />
            Eine sehr gute Möglichkeit sich weiterzuentwickeln ist Proaktivität. Und Proaktivität beginnt immer mit einem Problem. Und
            dieses Problem müssen Sie erkennen! <br />
            Stellen Sie sich vor Sie fahren mit Ihrem Chef zu einer Baustelle. Auf der Fahrt erzählt er Ihnen wie es ihn nervt, dass letzten
            Monat schon wieder 3 Kunden die Rechnung nicht bezahlt haben und er andauernd säumigen Zahlern hinterherlaufen muss. Ihr Chef
            hat also folgendes Problem: Rechnungsstellung und Mahnwesen nerven ihn. <br />
            <br />
            Sie haben jetzt 2 Möglichkeiten: <br />
            Entweder Sie regen sich mit ihm auf und schimpfen über die Zahlungsmoral der Kunden um Ihren Chef zu bestärken ODER Sie bieten
            an ihn bei dem Thema zu unterstützen und erarbeiten sich die Option auch in andere Tätigkeitsbereich im Unternehmen
            einzusteigen. Wählen Sie zweiteres und machen Ihre Sache gut, dann sind Sie für Ihren Chef ab sofort der Problemlöser. <br />
            In jedem Fall wird er sich über Ihre Proaktivität freuen und künftig an Sie denken, wenn es darum geht Themen anzupacken.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Karriereplanung und Zielsetzung</h2>
          <p className="paragraph-std left-copy">
            Um voranzukommen braucht es im Leben immer Ziele, so auch im Beruf. Setzen Sie sich klare berufliche Ziele und erstellen Sie
            einen Plan, wie Sie diese erreichen können. Überlegen Sie, wo Sie in fünf oder zehn Jahren stehen möchten, und identifizieren
            Sie die Schritte, die notwendig sind, um dorthin zu gelangen. Seien Sie bereit, flexibel zu bleiben und Ihren Plan bei Bedarf
            anzupassen, um auf Veränderungen im Markt oder in Ihren persönlichen Interessen zu reagieren.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Feedback und Selbstreflexion</h2>
          <p className="paragraph-std left-copy">
            Seien Sie offen für konstruktives Feedback, sei es von Kollegen, Vorgesetzten oder Mentoren. <br />
            Nutzen Sie dieses Feedback, mehr noch, fordern Sie es aktiv ein um so Ihre beruflichen Fähigkeiten und Ihr persönliches
            Auftreten weiterzuentwickeln. Selbstreflexion ist ein mächtiges Werkzeug auf dem Weg zur Verbesserung und zur Erreichung Ihrer
            Karriereziele.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Fazit</h2>
          <p className="paragraph-std left-copy">
            Für die berufliche Karriere gibt es kein Pauschalrezept. Was für den einen erstrebenswert ist, ist für einen anderen ein Horror.
            Darum erforschen Sie zuallererst sich selbst und planen Sie darauf basierend Ihre Zukunft. Und planen Sie nicht zu kurzfristig,
            das Leben ist lang und man sollte nicht nur aus seine aktuellen Lebenssituation heraus denken und handeln.
            <br />
            <br />
            Und das wichtigste zum Schluss: für eine Karriere ist es nie zu früh und nie spät. In allen Lebensphasen haben Sie wertvolle
            Fähigkeiten die Sie nur richtig einsetzen müssen. Seien Sie proaktiv und motiviert, dann erhalten Sie Chancen die Sie wiederum
            motivieren.
          </p>
          <div className="padding-10"></div>
          <div className="padding-30"></div>
        </div>
      </section>
    </div>
  );
};

export const BlogMitarbeiterGewinnen = () => {
  return (
    <div>
      <section className="section">
        <div className="container-14 w-container">
          <div className="padding-40"></div>
          <h1 className="heading-2 left">Effektive Mitarbeitergewinnung im Handwerk: Wie Sie Fachkräfte finden und binden</h1>
          <p className="paragraph-std left-copy">
            Die Gewinnung von Fachkräften im Handwerk stellt viele Betriebe vor große Herausforderungen. Angesichts des spürbaren
            Fachkräftemangels müssen Handwerksunternehmen innovative Wege gehen, um qualifizierte Mitarbeiter zu finden und für sich zu
            gewinnen. Hier sind bewährte Strategien, die Ihre Mitarbeitergewinnung im Handwerk effektiver gestalten:
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Unternehmensattraktivität</h2>
          <p className="paragraph-std left-copy">
            ‍Die sicherlich effektivste und auch nachhaltigste Methode potenzielle Mitarbeiter für sich zu begeistern ist, das Unternehmen
            auf die Bedürfnisse der Mitarbeiter auszurichten. Im Gegensatz zu einem klassischen Bürojob ist es in Handwerksbetrieben aber
            bedeutend schwerer, passende Angebote zu machen. Home-Office, flexible Arbeitszeiten, Teilzeitangebote usw. sind in den meisten
            Handwerksbetrieben schlicht nicht möglich. <br />
            <br />
            Dennoch gibt es auch in Handwerksunternehmen Möglichkeiten, die Arbeitgeberattraktivität maßgeblich zu steigern. <br />
            Die vielleicht wichtigste möchte ich Ihnen nachfolgend vorstellen:
            <br />
            <br />
            Wer kennt es nicht!? Die Auftragsbücher sind voll bis zum Rand und jeden Tag klingelt das Telefon mit Kunden, die sich zum x-ten
            Mal beschweren, wann denn jetzt endlich mal jemand kommt. Diese Art der Projektierung und Auftragsteuerung verärgert nicht nur
            die Kunden, sondern ist auch der beste Weg sich als Arbeitgeber unattraktiv zu machen. Die Mitarbeiter sehen sich dauerhaft
            Zeitstress ausgesetzt und spüren bei jedem Ihrer Projekte den Ärger von frustrierten Kunden. <br />
            Das sorgt langfristig für eine unangenehme Arbeitsatmosphäre und wird es Ihnen schwermachen, Weiterempfehlungen für Ihr
            Unternehmen zu erhalten. Durch Interviews mit vielen Arbeitgebern, die sich von dieser Art der Auftragssteuerung verabschiedet
            haben, wissen wir, dass es sich absolut lohnt, hier alternative Wege zu gehen. <br />
            <br />
            Darum empfehle ich Ihnen:
          </p>
          <ul role="list" className="list">
            <li className="list-item">
              Halten Sie Ihre Auftrags-Pipeline gefüllt, aber nicht übervoll
              <br />
            </li>
            <li className="list-item">
              Sorgen Sie dafür, dass Aufträge nach Möglichkeit im vereinbarten Zeitrahmen bleiben
              <br />
            </li>
            <li className="list-item">
              Halten Sie die Anzahl der parallellaufenden Aufträge so gering wie möglich
              <br />
            </li>
            <li className="list-item">
              Stellen Sie sicher, dass die Kunden im Bedarfsfall einen klaren Ansprechpartner für Ihren Ärger haben und Ihre Mitarbeiter in
              Ruhe lassen
            </li>
          </ul>
          <p className="paragraph-std left-copy">
            Vielleicht geht Ihnen dadurch ein Auftrag weniger ins Auftragsbuch ein aber Ihre Kundenzufriedenheit wird steigen und Ihre
            Mitarbeiter werden ohne Stress und Angst zur nächsten Baustelle fahren. Diese Unternehmenskultur wird sich rumsprechen und von
            ihrem aktuellen Arbeitgeber entnervte Arbeitnehmer zu Ihnen treiben. Und das Beste: für viele Arbeitnehmer ist eine gute
            Arbeitsatmosphäre mehr wert als die letzten 5% mehr Gehalt.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Aktives Mitarbeiter-Recruitment</h2>
          <p className="paragraph-std left-copy">
            Wir alle wissen, ein Handwerksbetrieb ist kein Großkonzern. Die Margen sind oft knapp kalkuliert und Overhead Budgets wie
            Mitarbeiter-Recruiting sind limitiert und müssen so effizient wie möglich genutzt werden. <br />
            <br />
            Darum empfehle ich Ihnen, passive Recruiting Formate komplett zu meiden!
            <br />
            Was ist damit gemeint: Der klassische Weg neue Mitarbeiter zu finden war früher eine Stellenausschreibung in einer <br />
            Zeitung zu inserieren. Dies ist ein passives Recruiting Format. Sie bezahlen das Inserat, hoffen, dass es ausreichend oft
            gesehen wird und warten, bis sich jemand bei Ihnen bewirbt. <br />
            <br />
            Durch den Fachkräftemangel sind diese Formate leider höchst ineffizient geworden. Zum einen gibt es am Markt praktisch keine
            arbeitssuchenden Fachkräfte und zum anderen ist es für Arbeitnehmer kaum mehr nötig, sich aktiv um eine Anstellung zu bemühen,
            d.h. beispielsweise die Stellenausschreibungen durchzuschauen.
            <br />
            Setzen Sie daher auf aktive Formate! <br />
            <br />
            Das sicherlich beste ist, durch Mund-zu-Mund Propaganda weiterempfohlen zu werden. Wie das funktionieren kann habe ich oben
            beschrieben.
            <br />
            Eine weitere Möglichkeit ist die online Jobplattform von <a href="arbeitgeber.html">Flendly</a>. Flendly ist die einzige
            Jobplattform, die zu 100% auf aktives Recruitment ausgerichtet ist. Sie als Arbeitgeber suchen ganz simpel nach den passenden
            Kandidaten und machen dann aktiv Angebote, um den Kandidaten ganz individuell von sich zu überzeugen. Sie müssen nicht warten,
            bis ein Arbeitnehmer Sie findet, sondern werden selbst aktiv und überzeugen von sich. <br />
            <br />
            Eine andere Variante sind Werbeanzeigen, sogenannte Display-Werbung über Social Media Kanäle zu schalten. Wahrscheinlich kennen
            Sie es von sich selbst, wenigstens aber von Ihren Kindern oder Freunden. Die Deutschen verbringen im Durchschnitt täglich 3(!)
            Stunden auf Social Media Plattformen. Und auch der Ü40 Anteil der Social Media Nutzer steigt kontinuierlich an. <br />
            Nutzen Sie diese Entwicklung aus und machen Sie potentielle Bewerber während Ihrer Lieblingsbeschäftigung auf sich aufmerksam.{' '}
            <br />
            Der Nachteil dieses Marketingkanals ist jedoch, dass ein gewisses Know-How notwendig ist, um ihn zu gut nutzen zu können. Im
            besten Fall kennen Sie oder ein Bekannter sich damit aus oder haben Interesse, sich einzuarbeiten und können so Ihre ersten
            Gehversuche wagen. Im schlechtesten Fall müssen Sie mit einer Marketingagentur zusammenarbeiten und tragen nicht nur die Kosten
            für die Werbeanzeigen sondern auch für die Agentur.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Mitarbeiterempfehlungsprogramme etablieren</h2>
          <p className="paragraph-std left-copy">
            Aufbauend auf den oben genannten Maßnahmen ist die Einführung von Mitarbeiterempfehlungsprogrammen eine sehr charmante und oft
            effektive Maßnahme. <br />
            Motivieren Sie Ihr Team, geeignete Kandidaten aus Ihrem persönlichen Umfeld anzusprechen und für das Unternehmen zu gewinnen. Am
            besten lässt sich das über eine Einmalzahlung bei erfolgter Einstellung steuern. Dieser Ansatz hat gleich mehrere Vorteile.
            Durch die Empfehlung haben Sie als Unternehmen eine gesteigerte Glaubwürdigkeit beim Bewerber und können diesen mit einer
            höheren Wahrscheinlichkeit für sich gewinnen. <br />
            Zudem bezahlen Sie (genau wie bei <a href="arbeitgeber.html">Flendly</a>) nur dann, wenn Sie auch wirklich einen neuen
            Mitarbeiter eingestellt haben. <br />
            Optimale Kosteneffektivität! <br />
            Und der wichtigste Aspekt: falls Ihre Mitarbeiter keine Empfehlungen für Ihr Unternehmen bei Freunden und Bekannten aussprechen,
            können Sie sicher sein, dass Ihre Mitarbeiter nicht gerne bei Ihnen arbeiten und Sie schnellstmöglich etwas im Unternehmen
            ändern müssen!
          </p>
        </div>
        <div className="padding-30"></div>
      </section>
    </div>
  );
};
export const BlogMitarbeiterUeberzeugen = () => {
  return (
    <div>
      <section className="section">
        <div className="container-14 w-container">
          <div className="padding-40"></div>
          <h1 className="heading-2 left">
            Bewerber von sich überzeugen: <br />
            Tipps für den Bewerbungsprozess
          </h1>
          <p className="paragraph-std left-copy">
            Der Wettbewerb um qualifizierte Fachkräfte in Deutschland ist groß. Umso wichtiger ist es für Arbeitgeber, im Bewerbungsprozess
            zu überzeugen und sich als attraktiver Arbeitgeber zu präsentieren. Hier sind einige Tipps, wie Sie im Handwerk Bewerber für
            sich gewinnen können:
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Stellenausschreibung</h2>
          <p className="paragraph-std left-copy">
            Der erste Kontakt mit potenziellen Mitarbeitern erfolgt oft über die Stellenausschreibung. Seien Sie hier mutig und wagen Sie
            etwas. Formulieren Sie kreativ und stechen aus der Masse hervor. Anders sein ist heute wichtiger denn je, um die wenigen
            verfügbaren Bewerber am Markt auf sich aufmerksam zu machen. <br />
            Geben Sie nicht nur Auskunft über die zu erwartenden Aufgaben, sondern auch über die Unternehmenskultur und die Vorteile, die
            Sie als Arbeitgeber bieten. So können sich Bewerber bereits im Vorfeld mit Ihrem Unternehmen identifizieren.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Schnelles und effizientes Bewerbungsverfahren</h2>
          <p className="paragraph-std left-copy">
            Nichts schlimmer als lange Wartezeiten und komplizierte Bewerbungsverfahren. In der heutigen Welt muss alles schnell gehen.
            Gestalten Sie den Prozess so einfach und transparent wie möglich. Bestätigen Sie den Eingang von Bewerbungen zeitnah und
            kommunizieren Sie klar den weiteren Ablauf sowie die voraussichtlichen Zeitrahmen für Rückmeldungen.
          </p>
          <div className="padding-10">
            <h2 className="heading-float-3 left">Persönlicher Kontakt und Wertschätzung</h2>
          </div>
          <p className="paragraph-std left-copy">
            Im Handwerk zählt oft das Persönliche. Nehmen Sie sich Zeit für Ihre Bewerber und führen Sie Vorstellungsgespräche in einer
            angenehmen Atmosphäre. Zeigen Sie Interesse an den individuellen Fähigkeiten und der Persönlichkeit der Kandidaten. Eine
            wertschätzende Haltung fördert eine positive Wahrnehmung Ihres Unternehmens.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Authentische Einblicke in den Arbeitsalltag</h2>
          <p className="paragraph-std left-copy">
            Bieten Sie Bewerbern die Möglichkeit, einen realistischen Einblick in ihren möglichen neuen Arbeitsplatz zu erhalten. Das kann
            durch einen &quot;Schnuppertag&quot; oder die Einbeziehung von Mitarbeitern in den Bewerbungsprozess geschehen. So können
            potenzielle Mitarbeiter das Team und die Arbeitsumgebung kennenlernen, was die Entscheidung für Ihr Unternehmen positiv
            beeinflussen kann.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Fort- und Weiterbildungsmöglichkeiten aufzeigen</h2>
          <p className="paragraph-std left-copy">
            Karriereentwicklung ist auch im Handwerk ein wichtiger Faktor. Informieren Sie Bewerber über individuelle
            Weiterbildungsprogramme und Karrierechancen in Ihrem Unternehmen. Das zeigt, dass Sie in die Zukunft Ihrer Mitarbeiter
            investieren und deren berufliche Entwicklung fördern.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Faire Vergütung und Zusatzleistungen</h2>
          <p className="paragraph-std left-copy">
            Eine wettbewerbsfähige Bezahlung ist grundlegend, aber auch Zusatzleistungen wie betriebliche Altersvorsorge,
            Gesundheitsförderung oder Bonuszahlungen können ausschlaggebend sein. Unabhängig davon was Sie als Unternehmen anbieten können,
            stellen Sie sicher, dass Bewerber von Anfang an über die finanziellen und zusätzlichen Anreize informiert sind, die Ihr
            Unternehmen bietet.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Die 5 ist manchmal gerade</h2>
          <p className="paragraph-std left-copy">
            Die Zeiten in denen Bewerber im Anzug erscheinen und perfekte Bewerbungsunterlagen einreichen sind vorbei. Fokussieren Sie sich
            daher auf die Fähigkeiten des Bewerbers. Das Anschreiben enthält mehr als 20 Rechtschreibfehler und der Lebenslauf wurde
            vergessen? <br />
            Scheiß drauf! <br />
            Bei <a href="arbeitgeber.html">Flendly</a> haben wir uns von Lebensläufen und Anschreiben deshalb komplett verabschiedet.
            Wichtig ist was der Bewerber in seinem Job kann. Stellen Sie ihn mit einer passenden Probezeit ein und nutzen Sie diese
            Probezeit. Wenn er nicht hält, was er verspricht, dann kündigen Sie. Ein schlechtes Arbeitsverhältnis nutzt weder Ihnen noch dem
            Bewerber.
          </p>
          <div className="padding-30"></div>
        </div>
      </section>
    </div>
  );
};

export const BlogArbeitgeberStandort = () => {
  return (
    <div>
      <section className="section">
        <div className="container-14 w-container">
          <div className="padding-40"></div>
          <h1 className="heading-2 left">
            Arbeitgeber am Standort Deutschland: <br />
            Zukunftsfähig oder Auslaufmodell?
          </h1>
          <p className="paragraph-std left-copy">
            Deutschland gilt traditionell als Land der Ingenieure und Handwerker, mit einer starken Basis in Handwerk und
            Fertigungsindustrie. Doch für viele Arbeitgeber im Handwerk und in der Fertigungsindustrie stellen sich zunehmend Fragen zur
            Zukunftsfähigkeit des Standorts. Die zwischenzeitlich mehr oder weniger arbeitgeberfeindlichen Bedingungen in Deutschland werfen
            die Abwägung auf, ob es sinnvoll ist, weiterhin in Deutschland zu bleiben oder ob eine Verlagerung ins Ausland die bessere
            Option ist. Massive Steuerlasten, eine ausufernde Bürokratie und ein rigider Arbeitsmarkt werfen eine Frage auf: <br />
            Ist Deutschland als Standort noch zukunftsfähig oder bereits ein Auslaufmodell? <br />
            In diesem Artikel betrachten wir die Herausforderungen und Chancen, denen sich Arbeitgeber, speziell aus Handwerk und
            Fertigungsindustrie, gegenübersehen.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">
            Herausforderungen für Arbeitgeber im Handwerk und in der Fertigungsindustrie in Deutschland
          </h2>
          <p className="paragraph-std left-kursiv">Hohe Lohnkosten</p>
          <p className="paragraph-std left-copy">
            Deutschland hat im internationalen Vergleich sehr hohe Lohnkosten, die vor allem für Unternehmen im Handwerk und in der
            Fertigungsindustrie eine erhebliche Belastung darstellen können. Dies betrifft insbesondere Unternehmen, die im globalen
            Wettbewerb stehen und ihre Produkte zu wettbewerbsfähigen Preisen anbieten müssen.
          </p>
          <p className="paragraph-std left-kursiv">Regulatorische Hürden</p>
          <p className="paragraph-std left-copy">
            Der deutsche Bürokratie- und Regulierungszwang sind weltweit berühmt und gefürchtet. Endlose Genehmigungsverfahren, teils
            psychopatische Steuer- und Dokumentationsvorschriften sowie bücherweise Arbeitsschutzbestimmungen haben vor allem in den letzten
            Jahren eine Dimension angenommen, die die Handlungs- und vor allem Wettbewerbsfähigkeit von Unternehmen massiv einschränkt.
          </p>
          <p className="paragraph-std left-kursiv">Fachkräftemangel</p>
          <p className="paragraph-std left-copy">
            Ein akuter Mangel an qualifizierten Fachkräften, insbesondere im Handwerk und in technischen Berufen, stellt nicht nur eine der
            größten Herausforderungen dar, sondern ist in seiner Begründung auch extrem schwer zu bekämpfen. Die Gründe des
            Fachkräftemangels in Handwerk und Fertigungsindustrie sind teils vielschichtig, doch ein entscheidender Faktor liegt in der
            deutschen Bildungspolitik. Die zunehmende Durchlässigkeit des Schulsystems und die implizite Abwertung von Ausbildungsberufen
            haben zu einer Verschiebung geführt, die junge Menschen verstärkt in akademische Laufbahnen drängt. Diese Entwicklung wurde von
            der Politik nicht nur geduldet, sondern durch verschiedene Maßnahmen gefördert, was zu einem Ungleichgewicht geführt hat:
            Während Universitäten und Hochschulen einen Zustrom erleben, kämpfen Handwerksberufe und technische Ausbildungen mit
            Nachwuchssorgen.
            <br />
            Diese Entwicklung zeitnah einzudämmen ist schier unmöglich.
            <br />
            Wir bei Flendly sehen diese langfristige Problematik und setzen verstärkt auf die Anwerbung von passenden und gut ausgebildeten
            Fachkräften aus dem europäischen Ausland.{' '}
          </p>
          <p className="paragraph-std left-kursiv">Konkurrenz aus dem Ausland</p>
          <p className="paragraph-std left-copy">
            Globalisierung und freier Handel haben dazu geführt, dass Unternehmen aus Ländern mit niedrigeren Lohnkosten und weniger
            regulatorischen Hürden verstärkt auf den deutschen Markt drängen. Dies verschärft den Wettbewerb für heimische Betriebe.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Chancen für Arbeitgeber im Handwerk und in der Fertigungsindustrie in Deutschland</h2>
          <p className="paragraph-std left-copy">
            Neben den oben genannten Herausforderungen gibt es selbstverständlich auch Gründe die gegen ein abwandern sprechen. In der
            Hauptsache sind das die folgenden:
          </p>
          <p className="paragraph-std left-kursiv">Hochqualifizierte Arbeitskräfte</p>
          <p className="paragraph-std left-copy">
            Deutschland verfügt noch immer über eine gut ausgebildete Belegschaft und eine hohe Qualität in der Ausbildung von Fachkräften.
            Das wird auch in Zukunft noch so sein und ermöglicht weiterhin den Zugang zu hochqualifizierten Arbeitnehmern.{' '}
          </p>
          <p className="paragraph-std left-kursiv">Innovationskraft</p>
          <p className="paragraph-std left-copy">
            Die starke Forschungs- und Entwicklungslandschaft in Deutschland fördert Innovationen und technologischen Fortschritt. Vor allem
            die enge Verzahnung von Betrieben und Universitäten bieten vielfältige Möglichkeiten für Innovation und Weiterentwicklung.{' '}
          </p>
          <p className="paragraph-std left-kursiv">Qualität und Zuverlässigkeit</p>
          <p className="paragraph-std left-copy">
            Nicht nur deutsche Produkte genießen weltweit einen hervorragenden Ruf für Qualität, Zuverlässigkeit und Präzision, sondern auch
            die Arbeit als solche. Diese Mentalität ist nicht nur die Mentalität der Unternehmen sondern gehört auch zur DNA der
            Arbeitnehmer.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Die Frage lautet also: Bleiben oder Abwandern? </h2>
          <p className="paragraph-std left-copy">
            Die Entscheidung, ob ein Unternehmen im Handwerk oder der Fertigungsindustrie in Deutschland bleibt oder seinen Standort ins
            Ausland verlagert, ist komplex und muss sorgfältig abgewogen werden. <br />
            Zuallererst stellt sich natürlich immer die Frage, inwieweit eine Verlagerung überhaupt möglich ist. Für viele Unternehmer ist
            eine Verlagerung schlicht nicht und nur sehr schwer möglich. Für kleinere Unternehmen, speziell im Handwerk, ist eine
            Verlagerung ins Ausland faktisch mehr ein Auswandern. Und das ist in erster Instanz eine familiäre und keine unternehmerische
            Fragestellung. <br />
            Leider sind die besonders stark von den Nachteilen des Wirtschaftsstandorts Deutschland betroffenen Unternehmen auch diejenigen
            die am schwersten abwandern können, nämlich die kleinen. <br />
            Dennoch: Trotz der Herausforderungen bietet Deutschland weiterhin einige Vorteile. Eine Verlagerung ins Ausland wird sicher
            Kosteneinsparungen bringen, birgt jedoch auch Risiken und kann langfristig den Ruf und die Positionierung des Unternehmens
            beeinträchtigen. <br />
            Eine pauschale Empfehlung ist also nicht seriös auszusprechen und muss für jedes Unternehmen individuell abgewägt werden. Diese
            Abwägung sollte vor allem eine daten- und faktenbasierte sein um unternehmerische Risiken zu minimieren.
            <br />
          </p>
          <div className="padding-30"></div>
        </div>
      </section>
    </div>
  );
};
export const BlogDigitalisierungImHandwerk = () => {
  return (
    <div>
      <section className="section">
        <div className="container-14 w-container">
          <div className="padding-40"></div>
          <h1 className="heading-2 left">
            Digitalisierung im Handwerk: <br />
            Chancen und Herausforderungen
          </h1>
          <p className="paragraph-std left-copy">
            Die Digitalisierung verändert die Wirtschafts- und Arbeitswelt grundlegend – und macht auch vor dem Handwerk nicht halt.
            Traditionelle Handwerksberufe stehen vor der Herausforderung, sich an die neuen Gegebenheiten anzupassen, um wettbewerbsfähig zu
            bleiben und die sich bietenden Chancen zu nutzen. Dieser Artikel beleuchtet, wie die Digitalisierung das Handwerk beeinflusst,
            welche Möglichkeiten sich eröffnen und welche Herausforderungen zu meistern sind.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Chancen der Digitalisierung im Handwerk</h2>
          <p className="paragraph-std left-copy">
            Die Chancen für Betriebe durch die Digitalisierung sind vielfältig. Nicht alle Produkte und Angebote sind für jedes Unternehmen
            sinnvoll, daher ist es wichtig die individuellen Vor- und Nachteile im Auge zu behalten. <br />
            Wichtigstes Credo bei der Digitalisierung ist jedoch immer:{' '}
            <strong>
              <br />
              &quot;Wer einen schlechten Prozess digitalisiert hat danach einen schlechten digitalen Prozess.&quot;
            </strong>{' '}
            <br />
            Daher beginnt jede Digitalisierung stets mit dem Hinterfragen der eigenen Abläufe und Prozesse und wie diese ggf. auch durch
            eine Digitalisierung optimiert werden können.
            <br />
            Nachfolgend möchte ich einige Möglichkeiten und Bereiche vorstellen, wo mittels Digitalisierung teilweise erheblich Potentiale
            gehoben werden können.{' '}
          </p>
          <div className="padding-10"></div>
          <p className="paragraph-std left-kursiv">Effizienzsteigerung und Prozessoptimierung</p>
          <p className="paragraph-std left-copy">
            Digitale Werkzeuge und Softwarelösungen ermöglichen es Handwerksbetrieben, ihre Arbeitsprozesse zu optimieren. Von der digitalen
            Auftragsverwaltung über die Einsatzplanung bis hin zur Lagerhaltung können alle Geschäftsprozesse effizienter gestaltet werden.
            Dies spart Zeit und Kosten und erhöht die Kundenzufriedenheit.
          </p>
          <p className="paragraph-std left-kursiv">Neue Vertriebs- und Marketingkanäle</p>
          <p className="paragraph-std left-copy">
            Das Internet bietet Handwerksunternehmen neue Wege, ihre Dienstleistungen und Produkte zu vermarkten. Über soziale Medien,
            eigene Websites und Online-Marktplätze können sie eine größere Zielgruppe erreichen und ihre Sichtbarkeit erhöhen.
          </p>
          <p className="paragraph-std left-kursiv">Erweiterung des Serviceangebots</p>
          <p className="paragraph-std left-copy">
            Digitale Technologien eröffnen Handwerksbetrieben die Möglichkeit, ihr Angebot zu erweitern. Beispielsweise können sie durch den
            Einsatz von Augmented Reality (AR) oder Virtual Reality (VR) innovative Services anbieten, wie beispielsweise die virtuelle
            Vorschau von Renovierungsarbeiten, Bauprojekten oder Produkten.
          </p>
          <p className="paragraph-std left-kursiv">Kundenbindung und -interaktion</p>
          <p className="paragraph-std left-copy">
            Digitale Tools ermöglichen eine direktere und effektivere Kommunikation mit den Kunden. Durch Kundenmanagement-Software (CRM)
            können Handwerksbetriebe ihre Kundendaten besser verwalten und für individuellen Service nutzen.
          </p>
          <div className="padding-10"></div>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Herausforderungen der Digitalisierung</h2>
          <p className="paragraph-std left-kursiv">Investitionsbedarf</p>
          <p className="paragraph-std left-copy">
            Die Einführung digitaler Technologien erfordert zunächst finanzielle Investitionen, sei es für Software, Hardware oder die
            Schulung der Mitarbeiter. Für kleine Handwerksbetriebe kann dies durchaus eine Hürde darstellen.
          </p>
          <p className="paragraph-std left-kursiv">Fachkräftemangel und Qualifizierung</p>
          <p className="paragraph-std left-copy">
            Es mangelt oft an Fachkräften, die sowohl handwerkliches Know-how als auch digitale Kompetenzen besitzen. Die Weiterbildung
            bestehender Mitarbeiter und die Ausbildung neuer Fachkräfte sind essenziell, um die Digitalisierung erfolgreich zu meistern.
          </p>
          <p className="paragraph-std left-kursiv">Datenschutz und Datensicherheit</p>
          <p className="paragraph-std left-copy">
            Mit der zunehmenden Digitalisierung steigen auch die Anforderungen an den Datenschutz und die Datensicherheit. Handwerksbetriebe
            müssen sicherstellen, dass sie die gesetzlichen Vorgaben einhalten und ihre Daten vor unbefugtem Zugriff schützen.
          </p>
          <p className="paragraph-std left-kursiv">Widerstände gegen Veränderungen</p>
          <p className="paragraph-std left-copy">
            In manchen Handwerksbetrieben gibt es Vorbehalte gegenüber der Digitalisierung, oft bedingt durch Unkenntnis der Möglichkeiten
            oder Angst vor dem Verlust des persönlichen Kundenkontakts. Die Überwindung dieser Widerstände ist eine wichtige Aufgabe auf dem
            Weg zur digitalen Transformation.
          </p>
          <div className="padding-10"></div>
          <h2 className="heading-float-3 left">Fazit</h2>
          <p className="paragraph-std left-copy">
            Die Digitalisierung bietet Handwerksbetrieben zahlreiche Chancen, stellt sie aber auch vor Herausforderungen. Wer die
            Möglichkeiten der Digitalisierung nutzen möchte, muss bereit sein, in Technologien und die Qualifizierung der Mitarbeiter zu
            investieren und sich kontinuierlich weiterzuentwickeln. Trotz aller Herausforderungen ist die Digitalisierung eine Chance für
            das Handwerk, sich zukunftsfähig aufzustellen und neue Marktchancen zu erschließen. Der Schlüssel zum Erfolg liegt in der
            Bereitschaft, traditionelle Arbeitsweisen mit modernen digitalen Lösungen zu verknüpfen und so das Beste aus beiden Welten zu
            vereinen.
          </p>
          <div className="padding-30"></div>
        </div>
      </section>
    </div>
  );
};
