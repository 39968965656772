import dayjs from 'dayjs';

export interface IMatch {
  id?: string;
  arbeitnehmerId: string;
  arbeitgeberId: string;
  arbeitnehmerSucheId: string;
  suchkriterienId: string;
  angebotId: string;
  status: string;
  hired: boolean;
  payed: boolean;
  created: number;
  lastModifiedDate: number;
  beruf: string;
}

export const defaultValue: Readonly<IMatch> = {
  id: null,
  arbeitnehmerId: null,
  arbeitgeberId: null,
  arbeitnehmerSucheId: null,
  suchkriterienId: null,
  angebotId: null,
  status: null,
  hired: false,
  payed: false,
  created: dayjs().unix(),
  lastModifiedDate: dayjs().unix(),
  beruf: '',
};
