import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { IBeruf } from 'app/shared/model/beruf.model';
import { Arbeitnehmerskills } from 'app/shared/model/enumerations/arbeitnehmerskills.model';

export let NotificationType = {
  ANGEBOT: 'ANGEBOT',
  ANGEBOT_ACCEPTED: 'ANGEBOT_ACCEPTED',
  ANGEBOT_REJECTED: 'ANGEBOT_REJECTED',
  ANFRAGE: 'ANFRAGE',
  ANFRAGE_REJECTED: 'ANFRAGE_REJECTED',
  EMAIL_CHANGED: 'EMAIL_CHANGED',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
};

export interface INotification {
  id?: string;
  userID: string | null;
  type: string | '';
  message: string | '';
  keyValueMap: Map<string, string> | null;
  status?: string;
}

export const defaultValue: Readonly<INotification> = {
  userID: null,
  message: '',
  type: '',
  keyValueMap: null,
};
