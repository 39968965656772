import React from 'react';

export enum Kultur {
  FAMILIENFREUNDLICH = 'Familienfreundlich',
  ARBEITSATMOSPHÄRE = 'Arbeitsatmosphäre',
  TEAMZUSAMMENHALT = 'Teamzusammenhalt',
  ENTWICKLUNGSMOEGLICHKEITEN = 'Entwicklungsmöglichkeiten',
  ARBEITSPLATZSICHERHEIT = 'Arbeitsplatzsicherheit',
  WERTSCHÄTZUNG = 'Wertschätzung',
  EIGENVERANTWORTUNG = 'Eigenverantwortung',
  SCHULUNGENWEITERBILDUNG = 'Schulungen & Weiterbildung',
  FEEDBACKKULTUR = 'Feedback Kultur',
  MODERNEARBEITSAUSRÜSTUNG = 'Moderne Arbeitsausrüstung',
  MENTORINGPROGRAMME = 'Mentoring Programme',
  TEAMBUILDINGEVENTS = 'Team Building Events',
}
