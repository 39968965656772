export interface IAnfrage {
  id?: string;
  arbeitgeberId: string;
  arbeitnehmerId: string;
  arbeitnehmerSucheId: string;
  recommendationId: string;
  message?: string | null;
}

export const defaultValue: Readonly<IAnfrage> = {
  arbeitgeberId: null,
  arbeitnehmerId: null,
  arbeitnehmerSucheId: null,
  recommendationId: null,
};
