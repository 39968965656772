import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { MitarbeiterAnzahl, Quereinsteiger } from 'app/shared/model/enumerations/mitarbeiterAnzahl.model';
import { ArbeitnehmerEigenschaftenAuswahl } from 'app/shared/components/arbeitgeber/arbeitgeberAuswahl';
import { ValidatedField } from 'react-jhipster';
import { Domains } from 'app/shared/model/enumerations/domains.model';
import { FlendlyProgressBar } from 'app/modules/register/progressbar';
import { createFromInterview } from 'app/shared/reducers/arbeitgeber.reducer';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAccount } from 'app/shared/reducers/authentication';
import FlendlyValidateField from 'app/modules/assets/FlendlyValidateField';
import { GenericAuswahl } from 'app/shared/components/generic-auswahl';
import DropzoneComponent from 'app/shared/components/arbeitgeber/logo-upload';
import { ArbeitgeberEigenschaften } from 'app/shared/components/arbeitgeber-eigenschaften-auswahl';

// Define your steps as an array of strings
const steps = [
  'branchen', // 0
  'mitarbeiterzahl', // 1
  'queereinsteiger', // 2
  'arbeitnehmerEigenschaften', // 3
  'arbeitgeberEigenschaften', // 4,
  'beschreibung', // 5
  'logo', //, 6
  'unternehmensname', //, 7
  'webseite', //, 8
  'adresse', //, 9
];

/**
 * Represents the InitialInterviewArbeitgeber component.
 * It is responsible for handling the initial interview process for employers.
 *
 * @returns {Function} The InitialInterviewArbeitgeber component.
 */
export const InitialInterviewArbeitgeber = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const handleFileUpload = useCallback(files => {
    if (files.length > 0) {
      setUploadedFile(files[0]);
    } else {
      setUploadedFile(null);
    }
  }, []);

  const [arbeitgeberEntity, setArbeitgeberEntity] = useState({
    auswahlBranchen: [],
    auswahlMitarbeiterAnzahl: [],
    auswahlQuereinsteiger: [],
    auswahlArbeitnehmerEigenschaften: [],
    auswahlArbeitgeberEigenschaften: [],
    unternehmensname: '',
    webseite: '',
    beschreibung: '',
    logo: '',
    adresse: '',
    zusatz: '',
    plz: '',
    ort: '',
    ansprechpartner: '',
    email: '',
    telefon: '',
    kostenhinweis: false,
  });

  // react components
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // redux
  const updateSuccess = useAppSelector(state => state.arbeitgeber.updateSuccess);
  const account = useAppSelector(state => state.authentication.account);

  // local states
  const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [totalSteps, seTtotalSteps] = useState(10);

  // effects
  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    setIsCurrentStepValid(validateStep(steps[currentStep]));
  }, [currentStep, arbeitgeberEntity]);

  // Funktionen zum Aktualisieren des Arbeitgeber-Entity
  const updateEntity = (field, value) => {
    setArbeitgeberEntity({ ...arbeitgeberEntity, [field]: value });
  };

  function finishInterview() {
    if (isCurrentStepValid) {
      const entity = {
        ...arbeitgeberEntity,
        userID: account.id,
      };

      // Here 'entity' will be your Arbeitgeber object and 'uploadedFile' will be your file
      const data = {
        arbeitgeber: entity,
        ...(uploadedFile && { logo: uploadedFile }),
      };

      dispatch(createFromInterview(data))
        .then(() => dispatch(getAccount()))
        .then(() => navigate('/register/arbeitgeber/early-bird'))
        .catch(reason => {
          console.log(reason);
        });
    } else {
      toast.error(getValidationMessage());
    }
  }

  const handleClose = () => {
    navigate('/logout');
  };

  // Funktionen zum Vor- und Zurückgehen zwischen den Schritten und validieren der Schritte
  const getValidationMessage = () => {
    let validationMessage = '';
    switch (steps[currentStep]) {
      case 'branchen':
        validationMessage = 'Bitte wählen Sie mindestens eine Branche aus.';
        return validationMessage;
      case 'mitarbeiterzahl':
        validationMessage = 'Bitte wählen Sie eine Mitarbeiteranzahl aus.';
        return validationMessage;
      case 'queereinsteiger':
        validationMessage = 'Bitte wählen Sie aus, ob ein Quereinstieg möglich ist.';
        return validationMessage;
      case 'arbeitnehmerEigenschaften':
        validationMessage = 'Bitte wählen Sie mindestens eine Eigenschaft aus.';
        return validationMessage;
      case 'arbeitgeberEigenschaften':
        validationMessage = 'Bitte wählen Sie mindestens eine Eigenschaft aus.';
        return validationMessage;
      case 'unternehmensname':
        validationMessage = 'Bitte geben Sie einen Unternehmensnamen an.';
        return validationMessage;
      case 'adresse':
        validationMessage = 'Bitte geben Sie eine Adresse, eine Postleitzahl, einen Ort, einen Ansprechpartner und eine Telefonnummer an.';
        return validationMessage;
      default:
        return null;
    }
  };

  const goToPreviousStep = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  const goToNextStep = () => {
    if (isCurrentStepValid) {
      setCurrentStep(currentStep + 1);
      window.scrollTo(0, 0);
    } else {
      toast.error(getValidationMessage());
    }
  };

  const validateStep = stepName => {
    switch (stepName) {
      case 'branchen':
        return arbeitgeberEntity.auswahlBranchen.length > 0;
      case 'mitarbeiterzahl':
        return arbeitgeberEntity.auswahlMitarbeiterAnzahl.length > 0;
      case 'queereinsteiger':
        return arbeitgeberEntity.auswahlQuereinsteiger.length > 0;
      case 'arbeitnehmerEigenschaften':
        return arbeitgeberEntity.auswahlArbeitnehmerEigenschaften.length > 0;
      case 'arbeitgeberEigenschaften':
        return arbeitgeberEntity.auswahlArbeitgeberEigenschaften.length > 0;
      case 'beschreibung':
        return true;
      case 'logo':
        return true;
      case 'unternehmensname':
        return arbeitgeberEntity.unternehmensname.length > 0;
      case 'webseite':
        return true;
      case 'adresse':
        return (
          arbeitgeberEntity.adresse.length > 0 &&
          arbeitgeberEntity.plz.length > 0 &&
          arbeitgeberEntity.ort.length > 0 &&
          arbeitgeberEntity.ansprechpartner.length > 0 &&
          arbeitgeberEntity.telefon.length > 0
        );
      default:
        return false;
    }
  };

  // Anzeige des aktuellen Schrittes
  const renderCurrentStep = () => {
    switch (currentStep) {
      case steps.indexOf('branchen'):
        return (
          <GenericAuswahl
            label="Branchen"
            minSelect={1}
            maxSelect={99}
            initialValue={arbeitgeberEntity.auswahlBranchen}
            onAuswahlChange={value => updateEntity('auswahlBranchen', value)}
            col={Domains}
            title={'In welchen Branchen sind Sie tätig?'}
          />
        );
      case steps.indexOf('mitarbeiterzahl'):
        return (
          <GenericAuswahl
            label="Mitarbeiteranzahl"
            minSelect={1}
            maxSelect={1}
            initialValue={arbeitgeberEntity.auswahlMitarbeiterAnzahl}
            onAuswahlChange={value => updateEntity('auswahlMitarbeiterAnzahl', value)}
            col={MitarbeiterAnzahl}
            title={'Wie viele Mitarbeiter hat Ihr Unternehmen?'}
          />
        );
      case steps.indexOf('queereinsteiger'):
        return (
          <GenericAuswahl
            label="Quereinsteiger"
            initialValue={arbeitgeberEntity.auswahlQuereinsteiger}
            onAuswahlChange={value => updateEntity('auswahlQuereinsteiger', value)}
            col={Quereinsteiger}
            title={'Ist ein Quereinstieg in Ihrem Unternehmen möglich?'}
            minSelect={1}
            maxSelect={1}
          />
        );
      case steps.indexOf('arbeitnehmerEigenschaften'):
        return (
          <ArbeitnehmerEigenschaftenAuswahl
            title={'Was ist Ihnen bei einem Mitarbeiter wichtig? (max. 4 Punkte)'}
            minSelect={1}
            maxSelect={4}
            initialValue={arbeitgeberEntity.auswahlArbeitnehmerEigenschaften}
            onAuswahlChange={value => updateEntity('auswahlArbeitnehmerEigenschaften', value)}
          />
        );
      case steps.indexOf('arbeitgeberEigenschaften'):
        return (
          <ArbeitgeberEigenschaften
            minSelect={1}
            maxSelect={8}
            title={'Was zeichnet Sie besonders aus als Unternehmen? (max. 8 Punkte)'}
            initialValue={arbeitgeberEntity.auswahlArbeitgeberEigenschaften}
            onAuswahlChange={value => updateEntity('auswahlArbeitgeberEigenschaften', value)}
          />
        );
      case steps.indexOf('beschreibung'):
        return (
          <div>
            <p className="paragraph-workflow">
              <strong>Beschreiben Sie Ihr Unternehmen in wenigen Sätzen.</strong>
            </p>
            <div className="padding-10"></div>
            <label htmlFor="profile-description" className="field-label-std">
              Unternehmensbeschreibung (optional)
            </label>
            <textarea
              style={{ width: '100%' }}
              className="text-field-big w-input"
              maxLength={5000}
              name="field"
              data-name="beschreibung"
              data-wf-id='["feafc913-1024-7116-67d4-cbf63a12a860"]'
              key="beschreibung"
              value={arbeitgeberEntity.beschreibung}
              onChange={e => updateEntity('beschreibung', e.target.value)} // Änderung hier
              id="profile-description"
            ></textarea>
          </div>
        );
      case steps.indexOf('logo'):
        return (
          <div className="div-block-workflow">
            <p className="paragraph-workflow">
              <strong>Laden Sie hier Ihr Unternehmenslogo hoch.</strong>
            </p>
            <div className="padding-10"></div>

            <DropzoneComponent initial={uploadedFile} onFileUpload={handleFileUpload} />
          </div>
        );
      case steps.indexOf('unternehmensname'):
        return (
          <div className="div-block-workflow">
            <div className="wrapper-460px">
              {/* Kontaktdaten des unternehmens */}
              <FlendlyValidateField
                placeholder="z.B. Max Mustermann GmbH"
                label="Unternehmensname"
                id="arbeitgeber-unternehmensname"
                name="unternehmensname"
                value={arbeitgeberEntity.unternehmensname}
                onChange={value => updateEntity('unternehmensname', value.target.value)}
                headline={'Wie lautet der Name Ihres Unternehmens?'}
              />
            </div>
          </div>
        );
      case steps.indexOf('webseite'):
        return (
          <div className="div-block-workflow">
            <p className="paragraph-workflow">
              <strong>Geben Sie hier Ihre Unternehmenswebsite an</strong>
            </p>
            <div className="padding-10"></div>

            <div className="form-group-full">
              <ValidatedField
                label="Unternehmenswebsite (optional)"
                id="arbeitgeber-webseite"
                inputClass="text-field-45px w-input"
                labelClass="field-label-std"
                name="webseite"
                data-cy="webseite"
                style={{
                  color: arbeitgeberEntity.webseite ? 'black' : 'gray',
                }}
                onChange={value => updateEntity('webseite', value.target.value)}
                value={arbeitgeberEntity.webseite}
              />
            </div>
          </div>
        );
      case steps.indexOf('adresse'):
        return (
          <div className="div-block-workflow">
            <p className="paragraph-workflow">
              <strong>Wie lauten Ihre Kontaktdaten?</strong>
            </p>
            <div className="padding-10"></div>
            <div className="wrapper-460px">
              <FlendlyValidateField
                inputClass="text-field-45px w-input"
                labelClass="field-label-std"
                label="Straße und Hausnummer"
                id="arbeitgeber-adresse"
                name="adresse"
                data-cy="adresse"
                onChange={value => updateEntity('adresse', value.target.value)}
                value={arbeitgeberEntity.adresse}
              />
              <FlendlyValidateField
                inputClass="text-field-45px w-input"
                labelClass="field-label-std"
                onChange={value => updateEntity('zusatz', value.target.value)}
                label="Adresszusatz (optional)"
                value={arbeitgeberEntity.zusatz}
                id="arbeitgeber-zusatz"
                name="zusatz"
                data-cy="zusatz"
                type="text"
              />

              <div className="w-layout-grid grid-6">
                <ValidatedField
                  labelClass="field-label-std"
                  inputClass="text-field-45px w-input"
                  label="PLZ"
                  id="arbeitgeber-plz"
                  name="plz"
                  data-cy="plz"
                  style={{
                    color: arbeitgeberEntity.plz ? 'black' : 'gray',
                  }}
                  onChange={value => updateEntity('plz', value.target.value)}
                  value={arbeitgeberEntity.plz}
                />
                <ValidatedField
                  inputClass="text-field-45px w-input"
                  labelClass="field-label-std form-label"
                  label="Ort"
                  id="arbeitgeber-ort"
                  name="ort"
                  data-cy="ort"
                  value={arbeitgeberEntity.ort}
                  style={{
                    color: arbeitgeberEntity.ort ? 'black' : 'gray',
                  }}
                  onChange={value => updateEntity('ort', value.target.value)}
                />
              </div>
              <FlendlyValidateField
                inputClass="text-field-45px w-input"
                labelClass="field-label-std"
                label="Telefon"
                id="arbeitgeber-telefon"
                name="telefon"
                onChange={value => updateEntity('telefon', value.target.value)}
                value={arbeitgeberEntity.telefon}
                data-cy="telefon"
              />
              <FlendlyValidateField
                inputClass="text-field-45px w-input"
                labelClass="field-label-std"
                label="Ansprechpartner"
                id="arbeitgeber-anstprechpartner"
                name="ansprechpartner"
                value={arbeitgeberEntity.ansprechpartner}
                data-cy="ansprechpartner"
                onChange={value => updateEntity('ansprechpartner', value.target.value)}
              />
            </div>
          </div>
        );
      default:
        return <div>error</div>;
    }
  };

  return (
    <div>
      <div className="section-workflow-progress-bar">
        <div className="container-workflow w-container">
          <div className="padding-5"></div>
          <img
            src="../../../content/images/Logo_plain_letters_cuttet.png"
            loading="lazy"
            width="262"
            sizes="(max-width: 479px) 100vw, 262px"
            alt="Flendly Logo, das als Home Button fungiert. Beim Anklicken des Logos wird man zur Startseite geleitet."
            srcSet="../../../content/images/Logo_plain_letters_cuttet-p-500.png 500w, ../../../content/images/Logo_plain_letters_cuttet-p-800.png 800w, ../../../content/images/Logo_plain_letters_cuttet-p-1080.png 1080w, ../../../content/images/Logo_plain_letters_cuttet-p-1600.png 1600w, ../../../content/images/Logo_plain_letters_cuttet.png 1639w"
          />
        </div>

        <FlendlyProgressBar cssClassSteps={'grid-progress-bar-ag'} totalSteps={totalSteps} currentStep={currentStep} />
      </div>

      <div className="section-workflow-steps">
        <div className="container-std w-container">
          {currentStep === 0 && (
            <div>
              <h1 className="heading-2">
                Herzlich willkommen beim exklusiven <br />
                Early Bird Zugang von Flendly.
              </h1>
              <h1 className="heading-float-3">
                Melden Sie sich noch vor dem offiziellen Produkt-Launch am 26.09.2024 an und zahlen Sie für Ihre erste Einstellung über
                Flendly nur <span className="text-span-14">99€ statt 499€.</span>
              </h1>
              <div className="padding-10"></div>
            </div>
          )}
          <div className="w-form">
            <div className="card-edit-body">{renderCurrentStep()}</div>

            <div className="padding-30" />

            <div className={currentStep > 0 ? 'flendly-button-bar' : 'flendly-button-bar-end'}>
              {currentStep > 0 && (
                <button
                  className="button-workflow-forward w-node-c34aae07-d4ae-83b6-6df1-74d77b4e246a-1e4981b7 w-button"
                  onClick={goToPreviousStep}
                  disabled={currentStep === 0}
                >
                  Zurück
                </button>
              )}
              {currentStep === totalSteps - 1 && (
                <button className="call-to-action-max w-button" onClick={finishInterview}>
                  Registrierung abschließen
                </button>
              )}

              {/* Dirty hack with index...should be refactored someday */}
              {currentStep < totalSteps - 1 && (
                <div>
                  <div className="d-inline-block" data-toggle="popover" data-content="Disabled popover">
                    <button
                      type="button"
                      id="buttonweiter"
                      className="button-workflow-forward w-button"
                      onClick={goToNextStep}
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                    >
                      Weiter
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
