import React from 'react';

export enum Worklifebalance {
  URLAUB = '30 Tage Urlaub',
  ARBEITSTAGE = '4 Tage Woche',
  HOME_OFFICE = 'Home Office',
  AUSGEWOGENEARBEITSBELASTUNG = 'Ausgewogene Arbeitsbelastung',
  GEREGELTEARBEITSZEITEN = 'Geregelte Arbeitszeiten',
  GLEITZEIT = 'Gleitzeit',
  FLEXIBLEARBEITSZEITEN = 'Flexible Arbeitszeiten',
  TEILZEITARBEIT = 'Teilzeitarbeit',
  SONDERURLAUBSREGELUNG = 'Sonderurlaubsregelung',
  ELTERNTEILZEITMODELLE = 'Eltern-Teilzeit-Modelle',
}
