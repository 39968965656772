import './home.scss';

import React, { useEffect } from 'react';

import { getEarlyBirdUrl, REDIRECT_URL } from 'app/shared/util/url-utils';

export const Arbeitgeberseite = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const redirectURL = localStorage.getItem(REDIRECT_URL);
    if (redirectURL) {
      localStorage.removeItem(REDIRECT_URL);
      location.href = `${location.origin}${redirectURL}`;
    }
  });

  return (
    <div className="body-with-image">
      <div className="section-hero-std non-landing">
        <div className="padding-section-30"></div>
        <div className="container-hero-std centered w-container">
          <h1 className="heading-float-1">Jetzt kostenlos anmelden und neue Mitarbeiter finden</h1>
          <div className="padding-10"></div>
          <a href={getEarlyBirdUrl()} className="call-to-action-max w-button">
            Jetzt Profil anlegen
          </a>
        </div>
        <div className="padding-section-30"></div>
      </div>
      <div className="section-plain-white">
        <div className="padding-section-30"></div>
        <div className="container-std w-container">
          <h2 className="heading-2">
            Mitarbeitersuche in Zeiten des Fachkräftemangels macht keinen Spaß
            <br />
          </h2>
          <div className="padding-10"></div>
          <div className="w-layout-grid grid-std">
            <p id="w-node-_4175a02e-95b1-4cc0-8e1c-11c525efb1c2-1e498187" className="paragraph-backround">
              {' '}
              Stellenanzeigen und Headhunter kosten Sie viel Geld und führen dennoch meistens nicht zum Erfolg?
            </p>
            <p id="w-node-a88e14f4-21e8-8545-95e6-eadbb0eedbac-1e498187" className="paragraph-backround">
              Mitarbeitersuchen bedeutet großen Aufwand, viel Herzblut und am Ende steht oft nur die Enttäuschung?
            </p>
            <p id="w-node-db461461-d558-2517-f47b-2193987d61c0-1e498187" className="paragraph-backround">
              Unqualifizierte Bewerbungen ohne Aussagekraft und Transparenz verschwenden meist nur Ihre Zeit?
            </p>
            <p id="w-node-_246be292-0f1b-8684-c65e-b89da5172d4a-1e498187" className="paragraph-backround">
              Zeitarbeitsfirmen versprechen viel aber leider passt die vermittelte Leistung am Ende selten?
            </p>
          </div>
        </div>
        <div className="padding-section-30"></div>
      </div>
      <div className="section-ci-gradient">
        <div className="padding-section-30"></div>
        <div className="container-std w-container">
          <h1 className="heading-1 white">Flendly löst diese probleme </h1>
          <h2 className="heading-5 white">
            Unser Algorithmus liefert Ihnen genau die Kandidaten, die Ihren Anforderungen entsprechen und die zu Ihrem Unternehmen passen.
          </h2>
          <div className="w-layout-grid grid-std">
            <div id="w-node-f8c5ef9f-2240-dac5-84fe-efafa2858025-1e498187" className="div-block-grid-std backround-ci">
              <div className="padding-5"></div>
              <img
                src="../../../content/images/filter_1filter.webp"
                loading="lazy"
                height="62"
                width="69"
                alt="Ein Symbol für Filter, dargestellt durch ein Trichter-Icon. Das Symbol symbolisiert die Möglichkeit, die angezeigten Inhalte zu filtern oder zu sortieren."
                id="w-node-_67fcc80e-71cf-0104-5246-b6203c2d94f4-1e498187"
              />
              <p className="paragraph-std white _2">
                Sie haben must-have Anforderungen an Ihre Wunschkandidaten wie z.B. Gehaltshöhe oder Berufserfahrung und können effizient
                nach Ihren unternehmensindividuellen Bedarfen filtern.
              </p>
            </div>
            <div id="w-node-_602c41aa-ea30-e47f-36e9-3ece57d0a4a9-1e498187" className="div-block-grid-std backround-ci">
              <div className="padding-5"></div>
              <img
                src="../../../content/images/Stopwatch-2_1Stopwatch 2.webp"
                loading="lazy"
                width="55"
                height="65"
                alt="Ein Symbol für Effizienz, dargestellt durch eine Stoppuhr. Das Symbol symbolisiert hohe Geschwindigkeit bei der Mitarbeitersuche."
                id="w-node-dd075a4d-337c-b437-1592-3d209cea49d3-1e498187"
              />
              <p id="w-node-bcf80303-b5ce-907d-2f0a-542334ad9e4a-1e498187" className="paragraph-std white _4">
                Sie sehen direkt, was dem Kandidaten in einem Unternehmen wichtig ist und inwieweit das zu Ihnen passt. Sparen Sie sich die
                Zeit dies erst im Bewerbungsprozess herauszufinden.
              </p>
            </div>
            <div id="w-node-beb6cbfe-3fca-9ec2-cbe0-a65457e28da4-1e498187" className="div-block-grid-std backround-ci">
              <div className="padding-5"></div>
              <img
                src="../../../content/images/connected_1connected.webp"
                loading="lazy"
                height="65"
                id="w-node-_44e6219f-bc42-b912-463f-6ed8c0e4954f-1e498187"
                alt="Ein Symbol für den einfachen Zugang zu neuen Mitarbeitern, dargestellt durch Wifi-Wellen. "
              />
              <p id="w-node-f8e0fdef-9c38-af95-b1a1-c76d407a2b83-1e498187" className="paragraph-std white _5">
                Sie haben kostenlosen Zugang zu einem riesigen Kandidatenpool und können dort jederzeit aktiv nach passenden Profilen suchen
                und die Kandidaten miteinander vergleichen.
              </p>
            </div>
            <div id="w-node-fb9621f7-74b7-db41-2c78-f449b806887b-1e498187" className="div-block-grid-std backround-ci">
              <div className="padding-5"></div>
              <img
                src="../../../content/images/handshake_1handshake.webp"
                loading="lazy"
                width="82"
                height="65"
                alt="Ein Symbol für Einigung, dargestellt durch einen Handschlag zwischen zwei Händen. Das Symbol symbolisiert das Zustandekommen einer Vereinbarung."
                id="w-node-ed5e0919-0069-06a2-3da9-67dde853e78b-1e498187"
              />
              <p id="w-node-_772d08a0-0f04-fbd6-4951-529fa56ee7c1-1e498187" className="paragraph-std white _7">
                Transparenz über die Wünsche des Arbeitnehmers ermöglicht es Ihnen, Angebote hoch individuell zu gestalten und die
                Kandidaten so von Ihrem Unternehmen zu überzeugen.
              </p>
            </div>
          </div>
        </div>
        <div className="padding-section-30"></div>
      </div>
      <div className="section-plain-white">
        <div className="padding-section-30"></div>
        <div className="container-std w-container">
          <h1 className="heading-1">und so funktioniert&#x27;s</h1>
          <div className="w-layout-grid grid-std">
            <div id="w-node-a7bd07b1-126d-474e-36c4-6baf23d6b70b-1e498187" className="div-block-dark-grey">
              <h1 className="heading-1 white">1</h1>
              <h2 id="w-node-a7bd07b1-126d-474e-36c4-6baf23d6b70e-1e498187" className="heading-4 white">
                Unternehmen <br />
                Registrieren
              </h2>
              <p className="paragraph-std-white">Registrieren Sie sich mit Ihrem Unternehmen in wenigen Minuten auf unserer Plattform.</p>
            </div>
            <div id="w-node-a7bd07b1-126d-474e-36c4-6baf23d6b712-1e498187" className="div-block-dark-grey">
              <h1 className="heading-1 white">2</h1>
              <h2 id="w-node-a7bd07b1-126d-474e-36c4-6baf23d6b716-1e498187" className="heading-4 white">
                Suchprofile
                <br />
                erstellen
              </h2>
              <p className="paragraph-std-white">
                Unser Algorithmus sucht auf Basis Ihres definierten Suchprofils die zu Ihnen passenden Kandidaten.
              </p>
            </div>
            <div id="w-node-a7bd07b1-126d-474e-36c4-6baf23d6b71c-1e498187" className="div-block-dark-grey">
              <h1 className="heading-1 white">3</h1>
              <h2 id="w-node-a7bd07b1-126d-474e-36c4-6baf23d6b71f-1e498187" className="heading-4 white">
                Angebot <br />
                senden
              </h2>
              <p className="paragraph-std-white">
                Senden Sie interessanten Kandidaten mit nur einem Klick ein individuelles und unverbindliches Angebot.
              </p>
            </div>
            <div id="w-node-a7bd07b1-126d-474e-36c4-6baf23d6b725-1e498187" className="div-block-dark-grey">
              <h1 className="heading-1 white">4</h1>
              <h2 id="w-node-a7bd07b1-126d-474e-36c4-6baf23d6b728-1e498187" className="heading-4 white">
                Interview
                <br />
                führen
              </h2>
              <p className="paragraph-std-white">Nimmt der Kandidat Ihr Angebot an, starten Sie Ihren individuellen Bewerbungsprozess.</p>
            </div>
          </div>
          <div className="padding-20"></div>
          <a href={getEarlyBirdUrl()} className="call-to-action-max w-button">
            Jetzt Profil anlegen
          </a>
        </div>
        <div className="padding-section-30"></div>
      </div>
      <div className="section-image-copy">
        <div className="padding-section-30"></div>
        <div className="container-std w-container">
          <h2 className="heading-2 white">Was kostet Flendly</h2>
          <h1 className="heading-float-1">Erst einstellen, dann bezahlen!</h1>
          <p className="paragraph-std white kosten">
            Bei Flendly zahlen Sie nur dann, wenn wir Ihnen erfolgreich einen neuen Mitarbeiter vermittelt haben. Es existieren keinerlei
            Fixkosten. <br />‍<br />
            Sobald Sie über Flendly einen Mitarbeiter einstellen, fällt eine Vermittlungsprovision in Höhe von 499€ an.
          </p>
        </div>
        <div className="padding-section-30"></div>
      </div>
    </div>
  );
};

export default Arbeitgeberseite;
