export enum PersönlicheEigenschaften {
  TEAMFÄHIG = 'Teamfähig',
  AUFGESCHLOSSEN = 'Aufgeschlossen',
  FLEISSIG = 'Fleissig',
  MOTIVIERT = 'Motiviert',
  EMPATHISCH = 'Empathisch',
  FREUNDLICH = 'Freundlich',
  ZIELSTREBIG = 'Zielstrebig',
  VERANTWORTUNGSBEWUSST = 'Verantwortungsbewusst',
  LOYAL = 'Loyal',
  ANPASSUNGSFÄHIG = 'Anpassungsfähig',
}

export enum Arbeitsweise {
  ZUVERLÄSSSIG = 'Zuverlässsig',
  STRUKTURIERT = 'Strukturiertes Vorgehen',
  PROAKTIV = 'Proaktiv',
  BELASTBAR = 'Belastbar',
  KRITIKFÄHIG = 'Kritikfähig',
  EIGENVERANTWORTLICH = 'Eigenverantwortlich',
  PROBLEMLÖSUNGSORIENTIERT = 'Problemlösungsorientiert',
  SELBSTREFLEKTIERT = 'Selbstreflektiert',
  DURCHSETZUNGSSTARK = 'Gewissenhaft',
  GEWISSENHAFT = 'Durchsetzungsstark',
}
