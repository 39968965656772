export interface IRecommendation {
  id?: string;
  arbeitnehmerSucheId: string;
  arbeitgeberId: string;
  arbeitnehmerId: string;
  suchkriterienId: string;
  score: number;
  status: string;
}

export let IRecommendationStatus = {
  NEW: 'NEW',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
};

export const defaultValue: Readonly<IRecommendation> = {
  arbeitgeberId: null,
  arbeitnehmerId: null,
  score: null,
  arbeitnehmerSucheId: null,
  suchkriterienId: null,
  status: IRecommendationStatus.NEW,
};
