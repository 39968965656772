import React from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Spinner } from 'reactstrap';

export const Loading = () => {
  const logoutUrl = useAppSelector(state => state.authentication.logoutUrl);
  const dispatch = useAppDispatch();

  return (
    <div>
      <section className="section-2">
        <div className="container-std w-container">
          <div className="loading-page">
            <img src="../../../content/images/GIF_HD_1.5s.gif" loading="lazy" alt="" className="image-18" />
            <div className="heading-float-2-copy-copy">Seite wird geladen...</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Loading;
