import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getMatchById } from 'app/shared/reducers/match.reducer';
import { IMatch } from 'app/shared/model/match.model';
import { getAngebot } from 'app/shared/reducers/angebot.reducer';
import { IAngebot } from 'app/shared/model/angebot.model';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { getArbeitnehmerSuche } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { getArbeitgeber } from 'app/shared/reducers/arbeitgeber.reducer';
import { MitarbeiterAnzahl } from 'app/shared/model/enumerations/mitarbeiterAnzahl.model';
import { getDateByTimestamp } from 'app/shared/util/date-utils';
import { getEigenschaftByKey } from 'app/shared/util/entity-utils';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { Domains } from 'app/shared/model/enumerations/domains.model';

export const MatchDetailArbeitnehmer = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  const match: IMatch = useAppSelector(state => state.match.entity);
  const angebot: IAngebot = useAppSelector(state => state.angebot.entity);

  const arbeitgeber: IArbeitgeber = useAppSelector(state => state.arbeitgeber.entity);
  const arbeitnehmersuche: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);

  useEffect(() => {
    if (!match.id) {
      dispatch(getMatchById(id));
    }
  }, []);

  useEffect(() => {
    if (match.id) {
      dispatch(getAngebot(match.angebotId));
      dispatch(getArbeitgeber(match.arbeitgeberId));
      dispatch(getArbeitnehmerSuche(match.arbeitnehmerSucheId));
    }
  }, [match]);

  function loaded() {
    return match.id && angebot.id && arbeitgeber.id && arbeitnehmersuche.id;
  }

  return (
    <div>
      {loaded() ? (
        <section>
          <div className="container-std w-container">
            <div className="padding-20" />
            <div className="wrapper-735px">
              <div className="card-read-dark-grey-copy">
                <h1 className="card-heading">Erhaltenes Angebot</h1>
                <div className="padding-10"></div>
                <p className="paragraph-card-left">
                  Firma {arbeitgeber.unternehmensname} GmbH hat Ihnen am {getDateByTimestamp(angebot.createdDate)} ein unverbindliches
                  Angebot unterbreitet und Sie zum Bewerbungsgespräch eingeladen.
                </p>
                <div className="card-text-blue">Wir bieten ihnen (Monatsgehalt, netto)</div>
                <div className="text-block-9-copy">
                  <strong>{angebot.gehalt} €</strong>
                </div>
                <div className="card-text-blue">Darum sollten Sie bei uns arbeiten</div>
                <div className="paragraph-left-655px-copy">{angebot.message}</div>
                <div className="padding-20"></div>
                <div className="w-layout-grid grid-cancel-send-215px">
                  <a href="#" className="call-to-action-red-max-100 w-button">
                    Absage erhalten
                  </a>
                  <a href="#" className="call-to-action-max-100 w-button">
                    Zusage erhalten
                  </a>
                </div>
              </div>

              <div data-hover="false" data-delay="0" data-w-id="a3d2e75b-2523-9ca0-9afa-073ff127dd2b" className="card-read-dark-grey">
                <div className="accordion-toggle w-dropdown-toggle">
                  <h1 className="card-heading">Unternehmensprofil</h1>
                  <div className="accordion-arrow w-icon-dropdown-toggle"></div>
                </div>
                <div className="dropdown-accordion w-dropdown-list">
                  <div>
                    <img
                      className="image-card-anfrage-ag"
                      src="../../../content/images/Maier-GbR.png"
                      width="645"
                      height="242"
                      alt=""
                      sizes="(max-width: 479px) 35vw, 170px"
                      id="w-node-_322c09e5-fe59-6b54-1691-ed34ec8b095b-1e498185"
                      loading="lazy"
                      srcSet="../../../content/images/Maier-GbR-p-500.png 500w, ../../../content/images/Maier-GbR.png 645w"
                    />
                    <div className="company-card-text-first">Firma</div>
                    <div className="card-field-min-215px">{arbeitgeber.unternehmensname}</div>
                    <div className="div-block-21">
                      <div>
                        <div className="card-text-blue">Anzahl Mitarbeiter</div>
                        <div className="card-field-min-215px">{MitarbeiterAnzahl[arbeitgeber.auswahlMitarbeiterAnzahl[0]]} Mitarbeiter</div>
                      </div>
                      <div className="horizontal-padding-3"></div>
                      <div>
                        <div className="card-text-blue">Quereinstieg möglich</div>
                        <div className="_215px">
                          <div className="text-grid-white-backr">{arbeitgeber.auswahlQuereinsteiger}</div>
                        </div>
                      </div>
                    </div>
                    <div className="card-text-blue">Das zeichnet uns besonders aus</div>
                    <div className="w-layout-grid grid">
                      {arbeitgeber.auswahlArbeitgeberEigenschaften.map((eigenschaft, index) => (
                        <div key={index} id="w-node-_322c09e5-fe59-6b54-1691-ed34ec8b0975-1e498185" className="text-grid-white-backr">
                          {getEigenschaftByKey(eigenschaft)}
                        </div>
                      ))}
                    </div>

                    <div className="card-text-blue">Das sollten Sie über uns wissen</div>
                    <p className="paragraph-left-655px">{arbeitgeber.beschreibung}</p>
                    <div className="card-text-blue">Wir suchen</div>
                    <div className="w-layout-grid grid-215px">
                      <div id="w-node-_322c09e5-fe59-6b54-1691-ed34ec8b0986-1e498185" className="text-grid-white-backr">
                        {arbeitnehmersuche.auswahlBeruf}
                      </div>
                      <div id="w-node-_322c09e5-fe59-6b54-1691-ed34ec8b0986-1e498185" className="text-grid-white-backr">
                        {arbeitnehmersuche.auswahlAusbildungsstand.map(value => Ausbildungsstand[value]).join(', ')}
                      </div>
                      <div id="w-node-_322c09e5-fe59-6b54-1691-ed34ec8b0986-1e498185" className="text-grid-white-backr">
                        {arbeitnehmersuche.auswahlBerufserfahrung.map(value => Berufserfahrung[value]).join(', ')}
                      </div>
                    </div>
                    <div className="card-text-blue">Hier sind wir tätig</div>
                    <div className="w-layout-grid grid-branche">
                      {arbeitgeber.auswahlBranchen.map((branche, index) => (
                        <div className="text-grid-white-backr">{Domains[branche]}</div>
                      ))}
                    </div>
                    <div className="card-text-blue">Adresse</div>
                    <div className="w-layout-grid grid-adress">
                      <div id="w-node-_322c09e5-fe59-6b54-1691-ed34ec8b099a-1e498185" className="text-grid-white-backr left">
                        {arbeitgeber.adresse}
                      </div>
                      <div id="w-node-_322c09e5-fe59-6b54-1691-ed34ec8b099c-1e498185" className="text-grid-white-backr left">
                        {arbeitgeber.plz} {arbeitgeber.ort}
                      </div>
                    </div>
                    <div className="card-text-blue">Unternehmenswebsite</div>
                    <div className="card-field-min-215px">{arbeitgeber.webseite}</div>
                  </div>
                </div>
              </div>
              <div className="padding-20"></div>
            </div>
          </div>
          <div className="padding-20"></div>
        </section>
      ) : (
        <div>loading...</div>
      )}
    </div>
  );
};

export default MatchDetailArbeitnehmer;
