import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Worklifebalance } from 'app/shared/model/enumerations/worklifebalance.model';
import { Verguetung } from 'app/shared/model/enumerations/verguetung.model';
import { Kultur } from 'app/shared/model/enumerations/kultur.model';
import { Benefits } from 'app/shared/model/enumerations/benefits.model';

export const ArbeitgeberEigenschaften = ({ onAuswahlChange, initialValue, title, minSelect = 1, maxSelect = 8 }) => {
  const [selectEigenschaften, setSelectEigenschaften] = useState([]);

  useEffect(() => {
    if (initialValue && initialValue.length > 0) {
      setSelectEigenschaften(initialValue);
    }
  }, [initialValue]);

  const getKeyByValue = (col, value: string) => {
    return Object.keys(col).find(key => col[key] === value);
  };

  function handleSelectEigenschaft(col, selected) {
    selected = getKeyByValue(col, selected);

    if (maxSelect === 1) {
      // Wenn maxSelect genau 1 ist, dann immer nur einen Wert auswählen
      setSelectEigenschaften([selected]);
      onAuswahlChange(selected);
    } else {
      // Ansonsten die Anzahl der ausgewählten Werte überprüfen
      if (selectEigenschaften.includes(selected)) {
        // Wert ist bereits ausgewählt, entfernen
        setSelectEigenschaften(selectEigenschaften.filter(item => item !== selected));
        onAuswahlChange(selectEigenschaften.filter(item => item !== selected));
      } else {
        // Wert ist nicht ausgewählt, überprüfen ob maxSelect erreicht ist
        if (maxSelect > 0 && selectEigenschaften.length < maxSelect) {
          setSelectEigenschaften([...selectEigenschaften, selected]);
          onAuswahlChange([...selectEigenschaften, selected]);
        } else {
          toast.info(`Sie können maximal ${maxSelect} Eigenschaften auswählen.`);
        }
      }
    }
  }

  return (
    <div id="employer_reg_08" className="div-block-workflow">
      <div className="wrapper-100 padding-5">
        <p className="paragraph-workflow">
          <strong>{title}</strong>
        </p>
        <div className="padding-10"></div>

        <div className="text-block-26">Work-Life-Balance</div>

        <div className="padding-5"></div>

        <div className="w-layout-grid grid-skills-10">
          {Object.entries(Worklifebalance).map(([key, value]) => (
            <div
              key={key}
              className={`click-button-medium ${
                selectEigenschaften.includes(key) || selectEigenschaften.includes(value) ? 'highlighted' : ''
              }`}
              onClick={() => handleSelectEigenschaft(Worklifebalance, value)}
            >
              {value}
            </div>
          ))}
        </div>

        <div className="padding-10"></div>
        <div className="text-block-26">Vergütung</div>

        <div className="padding-5"></div>

        <div className="w-layout-grid grid-skills-10">
          {Object.entries(Verguetung).map(([key, value]) => (
            <div
              key={key}
              className={`click-button-medium ${
                selectEigenschaften.includes(key) || selectEigenschaften.includes(value) ? 'highlighted' : ''
              }`}
              onClick={() => handleSelectEigenschaft(Verguetung, value)}
            >
              {value}
            </div>
          ))}
        </div>

        <div className="padding-10"></div>
        <div className="text-block-26">Arbeit, Kultur &amp; Weiterbildung</div>
        <div className="padding-5"></div>

        <div className="w-layout-grid grid-skills-10">
          {Object.entries(Kultur).map(([key, value]) => (
            <div
              key={key}
              className={`click-button-medium ${
                selectEigenschaften.includes(key) || selectEigenschaften.includes(value) ? 'highlighted' : ''
              }`}
              onClick={() => handleSelectEigenschaft(Kultur, value)}
            >
              {value}
            </div>
          ))}
        </div>

        <div className="padding-10"></div>
        <div className="text-block-26">Weitere Benefits</div>
        <div className="padding-5"></div>

        <div className="w-layout-grid grid-skills-10">
          {Object.entries(Benefits).map(([key, value]) => (
            <div
              key={key}
              className={`click-button-medium ${
                selectEigenschaften.includes(key) || selectEigenschaften.includes(value) ? 'highlighted' : ''
              }`}
              onClick={() => handleSelectEigenschaft(Benefits, value)}
            >
              {value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
