import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity, getEntitiesByUserID } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { Quereinsteiger } from 'app/shared/model/enumerations/mitarbeiterAnzahl.model';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';

export const SuchProfileArbeitgeber = () => {
  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.authentication.account);
  const arbeitnehmersucheList: IArbeitnehmersuche[] = useAppSelector(state => state.arbeitnehmersuche.entities);

  useEffect(() => {
    dispatch(getEntitiesByUserID(account.id));
  }, []);

  return (
    <div>
      {arbeitnehmersucheList && arbeitnehmersucheList.length > 0 ? (
        <section>
          <div className="container-std w-container">
            <div className="padding-20"></div>
            <div className="padding-20"></div>
            <div className="text-block-25">
              <strong className="bold-text-6">Bitte wählen Sie das gewünschte Suchprofil</strong>
            </div>
            <div className="padding-10"></div>
            <div>
              {arbeitnehmersucheList.map((suchprofil, index) => {
                return (
                  <div key={index} className={'w-layout-grid grid-3281'}>
                    <div id="w-node-_5541b32b-e4d1-5e5b-603b-a5e6d34b6778-8d3b9927" className="search-profile">
                      <Link to={'/suche/arbeitgeber/' + suchprofil.id} className="link-block-15 w-inline-block"></Link>
                      <div className="heading-searchprofile">{suchprofil.auswahlBeruf}</div>
                      <div className="text-block-search-profile">
                        {suchprofil.auswahlAusbildungsstand.map((ausbildungsstand, index) => {
                          return <span key={index}>{Ausbildungsstand[ausbildungsstand]} </span>;
                        })}
                        <br /> Quereinsteiger: {Quereinsteiger[suchprofil.auswahlQuereinsteiger]}
                        <br /> {suchprofil.standort.arbeitsort} |{' '}
                        {suchprofil.auswahlArbeitsverhaeltnis.map(value => Arbeitsverhaeltnis[value])} |{' '}
                        {suchprofil.auswahlBerufserfahrung.map(value => Berufserfahrung[value])}
                        <br /> Gehalt: {suchprofil.gehalt} €
                      </div>
                    </div>
                    <Link
                      id="w-node-_731c0316-64f9-044a-b181-8fa53b33633b-8d3b9927"
                      to={'/suche/arbeitgeber/profiles/update/' + suchprofil.id}
                      className="w-inline-block"
                    >
                      <img
                        src="../../../../content/images/edit2.svg"
                        loading="lazy"
                        id="w-node-_62efc283-2f8a-058c-5911-e86e0b813170-8d3b9927"
                        alt="Ein stilisiertes Symbol für das Bearbeiten von Informationen, dargestellt durch ein Icon bestehend aus einem Stift auf Papier."
                        className="image-15"
                      />
                    </Link>
                    <div
                      id="w-node-_731c0316-64f9-044a-b181-8fa53b33633b-8d3b9927"
                      className="w-inline-block"
                      onClick={() => {
                        dispatch(deleteEntity(suchprofil.id));
                      }}
                    >
                      <img
                        src="../../../../content/images/delete2.PNG"
                        loading="lazy"
                        id="w-node-_62efc283-2f8a-058c-5911-e86e0b813170-8d3b9927"
                        alt="Ein stilisiertes Symbol für das Löschen von Informationen, dargestellt durch ein Icon bestehend aus einem Mülleimer."
                        className="image-15"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      ) : (
        <section>
          <div className="container-std w-container">
            <div className="padding-20"></div>
            <div className="padding-20"></div>
            <h1 className="heading-1">Herzlich Willkommen auf Ihrem Dashboard</h1>
            <div className="padding-20"></div>
            <div className="text-block-25-copy">
              Legen Sie mit einem Klick auf das grüne Plus Ihr erstes Suchprofil an und <br />
              finden Sie die passenden Kandidaten für Ihr Unternehmen!
              <br />
            </div>
            <div className="padding-20"></div>
          </div>
        </section>
      )}
      <div className="container-std">
        <Link to="/suche/arbeitgeber/profiles/update" className="link-block-10 w-inline-block">
          <img
            src="../../../../content/images/plus_1plus.png"
            loading="lazy"
            alt="Ein Symbol für Hinzufügen, dargestellt durch ein Pluszeichen. Das Symbol symbolisiert die Aktion, etwas hinzuzufügen, einzufügen oder zu erweitern."
            className="image-16"
          />
        </Link>
        <div className="padding-20"></div>
      </div>
    </div>
  );
};
