import React, { useEffect, useRef, useState } from 'react';
import { INotification } from 'app/shared/model/notification.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { toast } from 'react-toastify';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { getAngebot } from 'app/shared/reducers/angebot.reducer';
import { getArbeitgeber as getArbeitgeber } from 'app/shared/reducers/arbeitgeber.reducer';
import { getArbeitnehmerSuche as getarbeitnehmersuche } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { IAngebot } from 'app/shared/model/angebot.model';
import { getArbeitnehmer } from 'app/shared/reducers/arbeitnehmer.reducer';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { useNavigate } from 'react-router-dom';
import { markNotificationAsDone } from 'app/shared/reducers/notification.reducer';

interface NotificationProps {
  notification: INotification;
  closeModal?: () => void;
}

export const AngebotRejected: React.FC<NotificationProps> = ({ notification, closeModal }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const angebot: IAngebot = useAppSelector(state => state.angebot.entity);

  const arbeitgeber: IArbeitgeber = useAppSelector(state => state.arbeitgeber.entity);
  const arbeitnehmer: IArbeitnehmer = useAppSelector(state => state.arbeitnehmer.entity);
  const arbeitnehmersuche: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);
  const [reason, setReason] = useState<String>();

  useEffect(() => {
    if (notification) {
      console.log(notification);

      let angebotId = notification.keyValueMap['angebotId'];
      setReason(notification.keyValueMap['reason']);
      if (angebotId && angebotId !== 'undefined') {
        dispatch(getAngebot(angebotId));
      } else {
        toast.error('angebotId is not defined');
      }
    }
  }, [notification]);

  useEffect(() => {
    if (angebot.arbeitgeberId) {
      dispatch(getArbeitgeber(angebot.arbeitgeberId));
      dispatch(getarbeitnehmersuche(angebot.arbeitnehmerSucheId));
      dispatch(getArbeitnehmer(angebot.arbeitnehmerId));
    }
  }, [angebot]);

  function loaded() {
    return arbeitgeber.userID && arbeitnehmersuche.userID && arbeitnehmer.userID;
  }

  function handleChangeOffer() {
    closeModal();
    navigate('/suche/arbeitgeber/angebot/' + angebot.recommendationId);
  }

  function handleSearch() {
    dispatch(markNotificationAsDone(notification.id));
    navigate('/suche/arbeitgeber/' + arbeitnehmersuche.id);
  }

  return (
    <div>
      {loaded() && (
        <div>
          <div className="notification-pop-ups">
            <h1 className="heading-3">Abgelehntes Angebot</h1>
            <div className="paragraph-std">
              {arbeitnehmer.auswahlAnrede} {arbeitnehmer.vorname} {arbeitnehmer.name} hat Ihr Angebot für eine Anstellung als{' '}
              {arbeitnehmersuche.auswahlBeruf} leider abgelehnt.
            </div>
            <div className="padding-10"></div>
            <div className="field-label-std">Begründung</div>
            <div className="text-block-left">{reason}</div>
            <div className="padding-20" />
            <div className="w-layout-grid grid-cancel-send-215px">
              <div onClick={handleSearch} className="call-to-action-max w-button">
                zurück zur Suche
              </div>
              <div onClick={handleChangeOffer} className="call-to-action-max w-button">
                Angebot anpassen
              </div>
            </div>
          </div>
          <div className="padding-20"></div>

          <div className="padding-10"></div>
        </div>
      )}
    </div>
  );
};
