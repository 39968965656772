import React, { useEffect, FunctionComponent, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IUser } from 'app/shared/model/user.model';
import { deleteAccount, getAccount, updateAccount } from 'app/shared/reducers/authentication';
import { Modal, ModalBody } from 'reactstrap';

export const EinstellungenArbeitnehmer = () => {
  const dispatch = useAppDispatch();
  const account: IUser = useAppSelector(state => state.authentication.account);

  useEffect(() => {
    if (account.userSettings == null) {
      dispatch(getAccount());
    }
  }, []);

  function updateUserSettings(einstellung: string, enabled: boolean) {
    const updatedAccount = {
      ...account,
      userSettings: {
        ...account.userSettings,
        [einstellung]: enabled,
      },
    };
    dispatch(updateAccount(updatedAccount));
  }

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  function closeModal() {
    setModal(false);
  }

  function handleClickDelete() {
    dispatch(deleteAccount(account));
  }

  return (
    <div>
      <div>
        <Modal isOpen={modal} toggle={toggle} fullscreen>
          <ModalBody>
            <div className="container-std w-container">
              <div className="wrapper-735px">
                <div className="padding-30-speziale"></div>
                <div className="card-heading">
                  <h1 className="heading-2">Konto schließen</h1>
                  <div className="modal-body">
                    <p>Möchten Sie Ihr Konto wirklich schließen?</p>
                  </div>
                  <div className="flendly-button-bar">
                    <button type="button" className="btn btn-secondary" onClick={closeModal}>
                      Abbrechen
                    </button>
                    <button type="button" className="btn btn-danger" onClick={handleClickDelete}>
                      Konto schließen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
      <div className="section-workflow-steps">
        <div className="container-19 w-container">
          <h1 className="heading-2-left">Einstellungen</h1>
          <div className="padding-5"></div>
          <Einstellung
            einstellung="Alarm"
            einstellungEnabled={account.userSettings?.unternehmensAlarmDeactivated}
            title="Benachrichtigungen per E-Mail erhalten"
            updateUserSettings={updateUserSettings}
            property="unternehmensAlarmDeactivated"
          />
          <Einstellung
            einstellung="News & Info"
            einstellungEnabled={account.userSettings?.newsAndInfoAlarmDeactivated}
            title="Benachrichtigungen zu allgemeinen Neuigkeiten und Informationen zu Flendly erhalten?"
            updateUserSettings={updateUserSettings}
            property="newsAndInfoAlarmDeactivated"
          />
          <Einstellung
            einstellung="Suche deaktivieren"
            einstellungEnabled={account.userSettings?.searchDeactivated}
            title="Konto temporär deaktivieren und in den Suchergebnissen von Unternehmen nicht mehr erscheinen"
            updateUserSettings={updateUserSettings}
            property="searchDeactivated"
          />

          <div className="padding-10"></div>
          <div className="w-layout-grid grid-60">
            <div id="w-node-_0e6d95a2-4a38-46ae-fdae-ba64d9661310-1e49819f" className="paragraph-account">
              Konto schließen
            </div>
            <div id="w-node-f5bb00d9-7929-e347-946a-b7e2e9c575bc-1e49819f" className="text-block-button-grid-an-aus" onClick={toggle}>
              &gt;
            </div>
          </div>
          <div className="padding-30"></div>
        </div>
      </div>
    </div>
  );
};

interface EinstellungProps {
  einstellung: string;
  einstellungEnabled: boolean;
  title: string;
  updateUserSettings: (einstellung: string, enabled: boolean) => void;
  property: string;
}

const Einstellung: FunctionComponent<EinstellungProps> = ({ einstellung, einstellungEnabled, title, updateUserSettings, property }) => {
  return (
    <div id="19-single-select" className="w-layout-grid grid-60">
      <div id="w-node-_0e180dd5-32d6-d04c-1466-f175caf4f8be-1e49819f">
        <div id="w-node-f1403fe9-020c-0132-e0d3-1764745be01b-1e49819f" className="paragraph-account">
          {einstellung}
        </div>
        <p>{title}</p>
      </div>
      <div
        id="w-node-_3b6f222a-4bf3-42c8-9e0e-83a9fc5bb16a-1e49819f"
        className={einstellungEnabled ? 'text-block-button-grid-an-aus highlighted' : 'text-block-button-grid-an-aus'}
        onClick={() => updateUserSettings(property, true)}
      >
        An
      </div>
      <div
        id="w-node-_0a5248ff-1b85-6006-f5ed-7ee159e86716-1e49819f"
        className={einstellungEnabled ? 'text-block-button-grid-an-aus' : 'text-block-button-grid-an-aus highlighted'}
        onClick={() => updateUserSettings(property, false)}
      >
        Aus
      </div>
    </div>
  );
};

export default EinstellungenArbeitnehmer;
