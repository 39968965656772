import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FlendlyValidateField from 'app/modules/assets/FlendlyValidateField';
import { Card, CardBody } from 'reactstrap';

function SearchInput({ onAuswahlChange, initialValue, wrapperClass }) {
  const [inputValue, setInputValue] = useState('');
  const debouncedSearchTerm = useDebounce(inputValue, 500);
  const [searchMade, setSearchMade] = useState(false); // Neu hinzugefügt

  const [blockedCompany, setBlockedCompany] = useState<any>([]);
  const [suggestedCompany, setSuggestedCompany] = useState<any>([]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchData(debouncedSearchTerm).then(response => {
        setSuggestedCompany(response);
        setSearchMade(true); // Setze searchMade auf true, wenn eine Suche gemacht wurde
      });
    } else {
      setSuggestedCompany([]);
      setSearchMade(false); // Setze searchMade auf false, wenn der Text gelöscht wurde
    }
  }, [debouncedSearchTerm]);

  // initialisiere den Wert des Inputfeldes mit dem übergebenen Wert
  useEffect(() => {
    setBlockedCompany(initialValue);
  }, [initialValue]);

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const options = {
    method: 'GET',
    url: 'api/arbeitgebers/search/' + inputValue,
  };

  // Hier wird die API aufgerufen
  const fetchData = async query => {
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  function handleClickedCompany(value) {
    if (blockedCompany.includes(value)) {
      setBlockedCompany(blockedCompany.filter(item => item !== value));
      onAuswahlChange(blockedCompany.filter(item => item !== value));
    } else {
      setBlockedCompany([...blockedCompany, value]);
      onAuswahlChange([...blockedCompany, value]);
    }
  }

  return (
    <div>
      <div className="paragraph-workflow">
        <strong>Gibt es Unternehmen, von denen Sie hier nicht gesehen werden wollen?</strong>
      </div>

      <div className="padding-10" />

      {blockedCompany &&
        blockedCompany.map((company, index) => (
          <Card className="flendly-card-list-item" key={index}>
            <CardBody
              style={{
                backgroundColor: '#f5f7fa',
              }}
            >
              <div className="row">
                <div
                  className="col-11"
                  style={{
                    width: '-webkit-fill-available !important',
                  }}
                >
                  <div id="w-node-_5541b32b-e4d1-5e5b-603b-a5e6d34b6778-8d3b9927" style={{ width: '100%' }} className="search-profile">
                    <a href="#" className="link-block-15 w-inline-block"></a>
                    <div className="heading-searchprofile">{company.name}</div>

                    <div className="text-block-search-profile">
                      {company.name} | {company.address}| {company.zip} {company.ort}
                    </div>
                  </div>
                </div>

                <div className="col-1 d-flex align-items-center justify-content-center">
                  <a
                    id="w-node-a1bd1d02-9b8d-f561-886c-98bfb039018a-8d3b9927"
                    onClick={() => handleClickedCompany(company)}
                    className="btn-bar-card"
                  >
                    <img
                      src="../../../content/images/delete2.PNG"
                      loading="lazy"
                      alt="Ein Symbol eines Papierkorbs, das das Löschen von Inhalten symbolisiert. Das Symbol zeigt einen stilisierten Papierkorb, der darauf hinweist, dass der Inhalt endgültig entfernt wird."
                      className="image-15"
                    />
                  </a>
                </div>
              </div>
            </CardBody>
          </Card>
        ))}

      <div className="padding-20" />

      <FlendlyValidateField
        label="Unternehmensname (optional)"
        name="companyName"
        type="text"
        placeholder="Unternehmen suchen..."
        onChange={e => setInputValue(e.target.value)}
        value={inputValue}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className={wrapperClass}>
          {suggestedCompany && suggestedCompany.length > 0
            ? suggestedCompany.map((value, index) => (
                <div key={index}>
                  <div key={index} className="click-button-medium" onClick={() => handleClickedCompany(value)}>
                    {value.name} | {value.address}| {value.zip} {value.ort}
                  </div>
                  <div className="padding-3"></div>
                </div>
              ))
            : searchMade && <div className="alert alert-warning">Dieses Unternehmen wurde nicht gefunden.</div>}
        </div>
      </div>
    </div>
  );
}

export default SearchInput;
