import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRecommendation, IRecommendationStatus } from 'app/shared/model/recommendation.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { getArbeitnehmerSuche } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { getArbeitgeber as getArbeitgeber } from 'app/shared/reducers/arbeitgeber.reducer';
import { getSuchkriterienByUserId } from 'app/shared/reducers/suchkriterien.reducer';
import { getRecommendationsForArbeitnehmer, updateRecommendation } from 'app/modules/suche/recommendation/recommendation.reducer';
import { MitarbeiterAnzahl, Quereinsteiger } from 'app/shared/model/enumerations/mitarbeiterAnzahl.model';
import { sendAnfrage } from 'app/shared/reducers/anfrage.reducer';
import { IAnfrage } from 'app/shared/model/anfrage.model';
import arbeitnehmer from 'app/modules/arbeitnehmer/arbeitnehmer';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { getEigenschaftByKey } from 'app/shared/util/entity-utils';
import { IUser } from 'app/shared/model/user.model';

export const SucheArbeitnehmer = () => {
  const dispatch = useAppDispatch();
  useNavigate();
  const account: IUser = useAppSelector(state => state.authentication.account);

  const suchkriterien: ISuchkriterien = useAppSelector(state => state.suchkriterien.entity);

  const recommendations: IRecommendation[] = useAppSelector(state => state.recommendation.entities);
  const arbeitgeber: IArbeitgeber = useAppSelector(state => state.arbeitgeber.entity);
  const arbeitnehmersuche: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);

  const [recommendationsList, setRecommendationsList] = React.useState<IRecommendation[]>();
  const [recommendation, setRecommendation] = React.useState<IRecommendation>();

  useEffect(() => {
    if (account.id) {
      dispatch(getSuchkriterienByUserId(account.id));
      dispatch(getRecommendationsForArbeitnehmer({ userID: account.id, includeDeclined: false }));
    }
  }, [arbeitnehmer]);

  useEffect(() => {
    if (recommendations && recommendations.length > 0) {
      setRecommendationsList(recommendations);
    }
  }, [recommendations]);

  useEffect(() => {
    if (recommendationsList && recommendationsList.length > 0) {
      let iRecommendation = recommendationsList[0];
      setRecommendation(iRecommendation);
      dispatch(getArbeitgeber(iRecommendation.arbeitgeberId));
      dispatch(getArbeitnehmerSuche(iRecommendation.arbeitnehmerSucheId));
    } else {
      dispatch(getRecommendationsForArbeitnehmer({ userID: account.id, includeDeclined: false }));
    }
  }, [recommendationsList]);

  function loaded() {
    return account.id && suchkriterien.userID && arbeitgeber.userID && arbeitnehmersuche.userID && recommendation;
  }

  function handleShowDeclinedRecommendations() {
    dispatch(getRecommendationsForArbeitnehmer({ userID: account.id, includeDeclined: true }));
    /*window.location.reload();*/
  }

  function handleRecommendationDeclined() {
    const updatedRecommendation: IRecommendation = { ...recommendation, status: IRecommendationStatus.REJECTED };
    dispatch(updateRecommendation(updatedRecommendation)).then(() => {
      let iRecommendations = recommendationsList.filter(r => r.id !== recommendation.id);
      // Check if there are any recommendations left
      if (iRecommendations.length > 0) {
        setRecommendationsList(iRecommendations);
      } else {
        dispatch(getRecommendationsForArbeitnehmer({ userID: account.id, includeDeclined: false }));
        // If no recommendations left, set some state variable indicating that
        setRecommendation(null);
      }
    });
  }

  function handleRecommendationAccepted() {
    let anfrage: IAnfrage = {
      arbeitnehmerSucheId: recommendation.arbeitnehmerSucheId,
      arbeitgeberId: recommendation.arbeitgeberId,
      arbeitnehmerId: recommendation.arbeitnehmerId,
      recommendationId: recommendation.id,
    };

    dispatch(sendAnfrage(anfrage)).then(() => {
      let iRecommendations = recommendationsList.filter(r => r.id !== recommendation.id);
      // Check if there are any recommendations left
      if (iRecommendations.length > 0) {
        setRecommendationsList(iRecommendations);
      } else {
        dispatch(getRecommendationsForArbeitnehmer({ userID: account.id, includeDeclined: true }));
        // If no recommendations left, set some state variable indicating that
        setRecommendation(null);
      }
    });
  }

  return (
    <div>
      {loaded() ? (
        account.userSettings.searchDeactivated ? (
          <section className="container-std w-container">
            <div className="flex-content-cust card-edit-dark-grey-735px-1">
              <div>
                <h1 className="heading-3-copy-copy">Ihre Suche ist zurzeit deaktiviert</h1>
                <p className="paragraph-std">
                  Sie haben die Suche deaktiviert. Um die Suche nutzen zu können, aktivieren Sie diese in Ihrem Profil.
                  <br />
                </p>
              </div>
              <div id="8-single-select">
                <Link to={`/arbeitnehmer/einstellungen`} className="call-to-action-max w-button">
                  Jetzt Suche aktivieren
                </Link>
              </div>
            </div>
          </section>
        ) : (
          <section>
            <div className="container-std w-container">
              <div className="padding-30"></div>
              <div className="wrapper-930px">
                <div id="w-node-_97a12f1b-b230-aa8d-1fd9-9e20df1ca382-fc2aa609" className="card-ag-suche">
                  {/*LOGO*/}
                  <img
                    src="../../../../content/images/Maier-GbR.png"
                    loading="lazy"
                    id="w-node-_97a12f1b-b230-aa8d-1fd9-9e20df1ca387-fc2aa609"
                    height="242"
                    alt=""
                    srcSet="../../../../content/images/Maier-GbR-p-500.png 500w, ../../../../content/images/Maier-GbR.png 645w"
                    sizes="(max-width: 479px) 100px, 170px"
                    className="image-card-absolute"
                  />
                  {/* Unternehmens Daten*/}
                  <h1 className="card-heading">
                    <Link to={`/suche/arbeitnehmer/details/${recommendation.id}`}> {arbeitgeber.unternehmensname}</Link>
                  </h1>
                  <div className="padding-20-copy"></div>

                  {/*Standort*/}
                  <div className="w-layout-grid grid-top-search-card-1x3">
                    <div className="w-layout-grid grid-search-card-1x2-200px">
                      <img
                        src="../../../../content/images/location.png"
                        loading="lazy"
                        width="128"
                        id="w-node-_598433ad-a407-bf5f-d5b0-8b91149c21ec-fc2aa609"
                        alt="Ein Symbol für den Standort, dargestellt durch ein Pin-Symbol. Das Symbol symbolisiert den Firmenstandort."
                        className="image-company-card-icons"
                      />
                      <div id="w-node-_598433ad-a407-bf5f-d5b0-8b91149c21ed-fc2aa609" className="text-grid-white-backr">
                        {arbeitgeber.ort}
                      </div>
                    </div>

                    {/*Anzahl Mitarbeiter*/}
                    <div className="w-layout-grid grid-search-card-1x2-200px">
                      <img
                        src="../../../../content/images/people.png"
                        loading="lazy"
                        width="187"
                        id="w-node-_423edc67-cf80-beae-2862-7335fa1ec077-fc2aa609"
                        alt="Ein Symbol für die Unternehmensgröße,  dargestellt durch ein Manakin-Icon. "
                        className="image-company-card-icons-copy"
                      />
                      <div id="w-node-_423edc67-cf80-beae-2862-7335fa1ec078-fc2aa609" className="text-grid-white-backr">
                        {MitarbeiterAnzahl[arbeitgeber.auswahlMitarbeiterAnzahl[0]]} Mitarbeiter
                      </div>
                    </div>
                    <div className="w-layout-grid grid-search-card-1x2-200px">
                      <img
                        src="../../../../content/images/Quereinstieg.png"
                        loading="lazy"
                        width="128"
                        id="w-node-_1a400624-0de3-f035-7abe-f46b2302f4ed-fc2aa609"
                        alt="Ein Symbol für den Quereinstieg, dargestellt durch ein Umkehrpfeil. Das Symbol symbolisiert den Quereinstieg in ein Unternehmen."
                        className="image-company-card-icons-copy"
                      />
                      <div id="w-node-_1a400624-0de3-f035-7abe-f46b2302f4ee-fc2aa609" className="text-grid-white-backr">
                        Quereinstieg - {arbeitgeber.auswahlQuereinsteiger.map(value => Quereinsteiger[value]).join(', ')}
                      </div>
                    </div>
                  </div>

                  {/* Gesuchter Beruf */}
                  <div className="w-layout-grid _1x2-jobs">
                    <img
                      src="../../../../content/images/schraubenschlüssel_1schraubenschlüssel.webp"
                      loading="lazy"
                      width="512"
                      id="w-node-_3397d0cc-23bf-b965-4199-7100b37d478f-fc2aa609"
                      alt="Ein Symbol für Berufe, dargestellt durch ein Icon bestehend aus einem gekreuzten Schraubenschlüssel und Schraubendreher."
                      className="image-company-card-icons"
                    />
                    <div className="w-layout-grid _1x4-200px">
                      <div>
                        <div className="w-layout-grid">
                          <div id="w-node-_79361e00-97c8-7855-61e7-501debb2124a-1e498192" className="text-grid-white-backr-accent">
                            {arbeitnehmersuche.auswahlBeruf}
                          </div>
                        </div>
                        <div className="horizontal-padding-5"></div>
                        <div className="w-layout-grid grid-job-details">
                          <div id="w-node-_79361e00-97c8-7855-61e7-501debb2124e-1e498192" className="text-grid-white-backr">
                            {arbeitnehmersuche.auswahlAusbildungsstand.map(value => Ausbildungsstand[value] + ' ')}
                          </div>
                          <div id="w-node-_79361e00-97c8-7855-61e7-501debb21250-1e498192" className="text-grid-white-backr">
                            {arbeitnehmersuche.auswahlBerufserfahrung.map(value => Berufserfahrung[value] + ' ')}
                          </div>
                          <div id="w-node-_79361e00-97c8-7855-61e7-501debb21252-1e498192" className="text-grid-white-backr">
                            {arbeitnehmersuche.zusatzqualifikationen}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Benefits */}
                    <div className="w-layout-grid _1x2-jobs">
                      <img
                        src="../../../../content/images/checklist_1checklist.webp"
                        loading="lazy"
                        width="190"
                        id="w-node-_97a12f1b-b230-aa8d-1fd9-9e20df1ca39a-fc2aa609"
                        alt="Ein Symbol für Eigenschaften, dargestellt durch ein Klemmbrett mit einer angedeuteten Checkliste. Das Symbol repräsentiert die  Merkmalen oder Eigenschaften des Mitarbeiters oder des Unternehmens."
                        className="image-company-card-icons"
                      />
                    </div>
                    <div className="w-layout-grid">
                      {arbeitgeber.auswahlArbeitgeberEigenschaften.map((eigenschaft, index) => (
                        <div key={index} id="w-node-ae84fb6b-4d1e-1b74-9148-bd334c9d8863-fc2aa609" className="text-grid-white-backr">
                          {getEigenschaftByKey(eigenschaft)}
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* Button bar */}
                  <div id="104-single-select45" className="w-layout-grid company-card-buttons">
                    <div
                      id="w-node-_97a12f1b-b230-aa8d-1fd9-9e20df1ca3a8-fc2aa609"
                      className="call-to-action-red-max-100"
                      onClick={() => handleRecommendationDeclined()}
                    >
                      Nicht interessant
                    </div>
                    <div
                      id="w-node-_97a12f1b-b230-aa8d-1fd9-9e20df1ca3ac-fc2aa609"
                      className="call-to-action-max-100"
                      onClick={() => handleRecommendationAccepted()}
                    >
                      Anfrage senden
                    </div>
                  </div>
                </div>
                <div className="padding-20"></div>
              </div>
            </div>
          </section>
        )
      ) : (
        <section className="container-std w-container">
          <div className="flex-content-cust card-edit-dark-grey-735px-1">
            <div>
              <h1 className="heading-3-copy-copy">Keine weiteren Treffer für Ihre Suche</h1>
              <p className="paragraph-std">
                Im Moment gibt es keine weiteren passenden Unternehmen für Sie. Sie können folgendes tun:
                <br />
              </p>
            </div>
            <div className="padding-5"></div>
            <div>
              <p className="paragraph-std">
                Falls Sie in weiteren Berufen arbeiten können, ergänzen Sie diese in Ihrem Suchprofil, um mehr Unternehmen zu erreichen{' '}
              </p>
            </div>

            <div id="8-single-select">
              <Link to={`/profil/arbeitnehmer`} className="call-to-action-max w-button">
                jetzt profil bearbeiten
              </Link>
            </div>
            <div className={'wrapper-100'}>
              <p className="paragraph-std">
                ODER
                <br />‍<br />
                Schauen Sie die Liste der abgelehnten Unternehmen nochmals an. In der schnellen Durchsicht drückt man vielleicht einmal zu
                schnell auf den &quot;nicht interessant&quot; Button. <br />
              </p>
            </div>
            <div id="8-single-select">
              {/*TODO abgelehnte Unternehmen nochmal einblenden*/}
              <a className="call-to-action-max w-button" onClick={() => handleShowDeclinedRecommendations()}>
                Abgelehnte Unternehmen nochmals anschauen
              </a>
            </div>
            <div className="wrapper-100">
              <p className="paragraph-std">
                ODER
                <br />
              </p>
            </div>
            <div id="8-single-select">
              {/*TODO Option für Notification wenn weitere Unternehmen verfügbar sind*/}
              <a href="#" className="call-to-action-max w-button">
                Warten bis weitere Unternehmen verfügbar sind
              </a>
            </div>
            <div className="padding-10"></div>
          </div>
        </section>
      )}
    </div>
  );
};

export default SucheArbeitnehmer;
