import './home.scss';

import React, { useEffect } from 'react';

import { getEarlyBirdUrl, REDIRECT_URL } from 'app/shared/util/url-utils';
import { useAppSelector } from 'app/config/store';
import { getLoginUrl } from 'app/shared/util/url-utils';
import Testimonial from 'app/shared/slider/testimonial';

export const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useAppSelector(state => state.authentication.account);
  useEffect(() => {
    const redirectURL = localStorage.getItem(REDIRECT_URL);
    if (redirectURL) {
      localStorage.removeItem(REDIRECT_URL);
      location.href = `${location.origin}${redirectURL}`;
    }
  });

  return (
    <div className="body-with-image">
      <div className="section-hero-std non-landing">
        <div className="padding-section-30"></div>
        <div className="container-hero-std centered w-container">
          <h1 className="heading-float-1">Flendly ist die Jobplattform für Fachkräfte aus Handwerk & Industrie.</h1>
          <p className="paragraph-display-middle-white">
            Unsere Prozesse sind so simpel wie nur möglich und speziell auf die Bedürfnisse unserer Fokusbranchen ausgerichtet.
          </p>
          <div className="w-layout-grid grid-2">
            <h1 id="w-node-_057be05f-dd7c-ac90-f0d7-4c5898934d0e-1e4981a4" className="heading-3-backround transparence">
              Handwerk
            </h1>
            <h1 id="w-node-_6750273b-fce7-4de7-03ca-0f096794d555-1e4981a4" className="heading-3-backround transparence">
              Bau
            </h1>
            <h1 id="w-node-fa935d24-81b1-271a-1f6e-0bf3a5a5299f-1e4981a4" className="heading-3-backround transparence">
              Elektro
            </h1>
            <h1 id="w-node-_5ce98db9-6bb2-45b8-ed11-5076a88ec3fd-1e4981a4" className="heading-3-backround transparence">
              Produktion <br />
              &amp; Fertigung
            </h1>
            <h1 id="w-node-cdd403df-0f12-f5dc-eddf-44d7c93c5bd0-1e4981a4" className="heading-3-backround transparence">
              Maschinen- <br />
              &amp; Metallbau
            </h1>
            <h1 id="w-node-_0134b1ca-1962-ef08-3d4d-2d00c13fad33-1e4981a4" className="heading-3-backround transparence">
              Verkehr <br />
              &amp; Logistik
            </h1>
          </div>
        </div>
        <div className="padding-section-30"></div>
      </div>
      <div className="section-plain-white">
        <div className="padding-section-30"></div>
        <div className="container-std w-container">
          <h1 className="heading-1">
            Was macht Flendly so besonders
            <br />
          </h1>
          <div className="padding-5"></div>
          <div className="w-layout-grid grid-std">
            <div id="w-node-_533a5f97-2b23-f8dc-9f83-159a5e7b265f-1e4981a4" className="div-block-grid-std backround-green">
              <h1 className="heading-3 white">Bewerberansatz</h1>
              <p className="paragraph-std-white">
                Durch den <span className="text-span-3">umgekehrten Bewerbungsprozess</span> haben Unternehmen die Möglichkeit, aktiv nach
                Mitarbeitern zu suchen und müssen nicht, wie im klassischen Fall, passiv auf den Erfolg von teuren Stellenanzeigen hoffen.
                Arbeitnehmer wiederum erhalten durch die Angebotslogik ein <span className="text-span-3">angemessenes Gehalt</span> und
                Transparenz über ihren Marktwert.
              </p>
            </div>
            <div id="w-node-_326a2f06-3f06-55e4-852b-900892560134-1e4981a4" className="div-block-grid-std backround-green">
              <h1 className="heading-3 white">Prozesse</h1>
              <p id="w-node-_2d235be4-be17-d664-1a97-56a8ad66a43a-1e4981a4" className="paragraph-std-white">
                Durch die Spezialisierung auf unsere Fokusbranchen können wir einen Prozess darstellen, der sich kompromisslos an den{' '}
                <span className="text-span-3">Bedürfnissen unserer Kunden</span> orientiert. Dies ermöglicht es, unsere Plattform und
                Prozesse so <span className="text-span-3">simpel und schlank</span> wie nur möglich zu gestalten. Allen überflüssigen
                Schnick-Schnack lassen wir bei Flendly einfach weg.
              </p>
            </div>
            <div id="w-node-dd5e5ada-a2c6-6f08-55cf-4d433fbcf490-1e4981a4" className="div-block-grid-std backround-green">
              <h1 className="heading-3 white">Algorithmus</h1>
              <p id="w-node-_2d235be4-be17-d664-1a97-56a8ad66a43c-1e4981a4" className="paragraph-std-white">
                Nichts schlimmer, als einen neuen Job zu beginnen und nach wenigen Tagen festzustellen, dass die neue Firma leider ganz
                anders ist als gedacht. Für den Mitarbeiter als auch das Unternehmen! Durch unseren{' '}
                <span className="text-span-3">Matching Algorithmus</span> stellen wir sicher, dass auch{' '}
                <span className="text-span-3">Softfacts </span>wie Arbeitsatmosphäre, Teamalter uvm. passen.
              </p>
            </div>
            <div id="w-node-ed9ee88c-6007-d6fb-6dd2-c1623c83b34c-1e4981a4" className="div-block-grid-std backround-green">
              <h1 className="heading-3 white">Kosten</h1>
              <p id="w-node-_2d235be4-be17-d664-1a97-56a8ad66a43e-1e4981a4" className="paragraph-std-white">
                Unser Motto beim Thema Geld lautet: <span className="text-span-3">erst einstellen, dann bezahlen</span>. Damit sind wir in
                diesem Segment einzigartig. Erst wenn Sie als Unternehmen unsere wichtigste Leistung erhalten haben, nämlich einen neuen
                Mitarbeiter, fallen Kosten an. <br />
              </p>
              <p className="paragraph-std white">
                Für Arbeitnehmer ist unsere Plattform komplett <span className="text-span-3">kostenlos</span>.
              </p>
            </div>
          </div>
        </div>
        <div className="padding-section-30"></div>
      </div>
      <div className="section-ci-gradient">
        <div className="padding-section-60"></div>
        <div className="container-std w-container">
          <h1 className="heading-float-1-c">Jetzt kostenlos anmelden und loslegen</h1>
          <div className="padding-10"></div>
          <a href={getEarlyBirdUrl()} className="call-to-action-max w-button">
            Hier registrieren
          </a>
        </div>
        <div className="padding-section-60"></div>
      </div>

      {/*TODO after early bird wieder reinbauen, dann könnte es zumindest theoretisch wahr sein ^^*/}
      {/*<section className="section-image6">
        <div className="padding-section-60"></div>
        <div className="container-std">
          <h2 className="heading-2 white">Was unsere Kunden über uns sagen</h2>
          <Testimonial />
        </div>
        <div className="padding-section-60"></div>
      </section>*/}
    </div>
  );
};

export default About;
