export enum MitarbeiterAnzahl {
  BIS_5 = '0 - 5',
  _6_BIS_15 = '6 - 15',
  _16_BIS_30 = '16 - 30',
  MEHR_30 = '>30',
}

export enum Quereinsteiger {
  JA = 'Ja, möglich',
  NEIN = 'Nein, nicht möglich',
}
