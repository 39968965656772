import React from 'react';

import { NavbarBrand, NavItem } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { getEarlyBirdUrl } from 'app/shared/util/url-utils';
import { toast } from 'react-toastify';
import isActive = toast.isActive;

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="../../../../content/images/Logo_plain_letters_cuttet.png" alt="Logo" />
  </div>
);

export const Brand = () => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <BrandIcon />
  </NavbarBrand>
);

export const ARBEITNEHMER = () => (
  <Link to="arbeitnehmer" className={`headline-0 w-nav-link ${isActive('arbeitnehmer') ? 'w--current' : ''} `}>
    <b>
      <Translate contentKey="global.menu.arbeitnehmer">Arbeitnehmer</Translate>
    </b>
  </Link>
);
export const ARBEITGEBER = () => (
  <Link to="arbeitgeber" className="headline-0 w-nav-link">
    <Translate contentKey="global.menu.arbeitgeber">Arbeitgeber</Translate>
  </Link>
);
export const ABOUT = () => (
  <Link
    to="flendly"
    className="headline-0 w-nav-link"
    style={{
      wordWrap: 'revert',
      whiteSpace: 'nowrap',
    }}
  >
    <Translate contentKey="global.menu.about">About flendly</Translate>
  </Link>
);

export const BLOG = () => (
  <Link
    to="blog"
    className="headline-0 w-nav-link"
    style={{
      wordWrap: 'revert',
      whiteSpace: 'nowrap',
    }}
  >
    Blog
  </Link>
);

export const REGISTER = () => (
  <Link to={getEarlyBirdUrl()} className="headline-0 w-nav-link">
    Registrieren
  </Link>
);

export const SUCHE = ({ navlink }) => (
  <NavItem className="flendly-nav-item">
    <Link to={navlink} className="headline-0 w-nav-link" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img
        src="../../../../content/images/Lupe_selbstbau.png"
        loading="lazy"
        width="28"
        sizes="(max-width: 767px) 100vw, 28px"
        alt="Ein stilisiertes Such-Symbol, bestehend aus einer Lupe. Das Symbol repräsentiert die Suche nach Matches."
        srcSet="../../../../content/images/Lupe_selbstbau-p-500.png 500w, ../../../../content/images/Lupe_selbstbau.png 776w"
        className="image-icon-navbar"
      />
      SUCHE
    </Link>
  </NavItem>
);

export const MATCHES = ({ navlink }) => (
  <NavItem className="flendly-nav-item">
    <Link to={navlink} className="headline-0 w-nav-link" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img
        src="../../../../content/images/Puzzle-Block.svg"
        loading="lazy"
        width="27"
        alt="Ein stilisiertes EinstellungenArbeitnehmer-Symbol, bestehend aus vier im Quadrat angeordneten Puzzle Teilen. Das Symbol repräsentiert die Matches Übersicht ."
        className="image-icon-navbar"
      />
      MATCHES
    </Link>
  </NavItem>
);

export const ACCOUNT = ({ navlink }) => (
  <NavItem className="flendly-nav-item">
    <Link to={navlink} className="headline-0 w-nav-link" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img
        src="../../../../content/images/profile.png"
        loading="lazy"
        width="27"
        alt="Ein stilisiertes EinstellungenArbeitnehmer-Symbol, bestehend aus vier im Quadrat angeordneten Puzzle Teilen. Das Symbol repräsentiert die Matches Übersicht ."
        className="image-icon-navbar"
      />
      PROFIL
    </Link>
  </NavItem>
);
