import React, { useEffect } from 'react';

export const Datenschutz = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section className="section-margin">
        <div className="container-16 w-container">
          <div className="padding-40"></div>
          <p className="heading-8">Datenschutzerklärung</p>
          <p>
            <strong>1. Datenschutz auf einen Blick</strong>
            <br />
            <strong>
              <br />
              Allgemeine Hinweise
              <br />‍
            </strong>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere
            Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche
            Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
            <br />
            <br />
            <strong>
              Datenerfassung auf unserer Website
              <br />‍
            </strong>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website? <br />
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
            Website entnehmen.
            <br />
            <strong>
              ‍<br />‍
            </strong>
            Wie erfassen wir Ihre Daten?
            <strong>
              <br />‍
            </strong>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie
            in ein Kontaktformular eingeben. Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das
            sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser
            Daten erfolgt automatisch, sobald Sie unsere Website betreten.
            <br />
            <br />
            <strong>‍</strong>Wofür nutzen wir Ihre Daten?
            <br />
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur
            Analyse Ihres Nutzerverhaltens verwendet werden.
            <br />
            <br />
            Welche Rechte haben Sie bezüglich Ihrer Daten?
            <br />
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen
            Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu
            sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns
            wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Außerdem haben Sie das Recht,
            unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Details hierzu
            entnehmen Sie der Datenschutzerklärung unter „Recht auf Einschränkung der Verarbeitung“.
            <br />
            <br />
            <strong>Analyse-Tools und Tools von Drittanbietern</strong>
            <br />
            Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit
            sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu
            Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools
            verhindern. Detaillierte Informationen dazu finden Sie in der folgenden Datenschutzerklärung. Sie können dieser Analyse
            widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung informieren.
            <br />
            <br />
            <strong>
              2. Allgemeine Hinweise und Pflichtinformationen
              <br />
              <br />
              Datenschutz
              <br />‍
            </strong>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie diese Website
            benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
            identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen.
            Sie erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
            Dritte ist nicht möglich.
            <br />
            <br />
            <strong>
              Hinweis zur verantwortlichen Stelle
              <br />‍
            </strong>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist: <br />
            <br />
            Flendly UG (haftungsbeschränkt)
            <br />
            Schäftlarnstraße 99
            <br />
            81371 München
            <br />
            Deutschland
            <br />
            Amtsgericht München, HRB 290344 <br />
            <a href="mailto:info@carerockets.com">
              <strong>
                <br />‍
              </strong>
            </a>
            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und
            Mittel der Verarbeitung <br />
            von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
            <br />
            <br />
            <strong>
              Widerruf Ihrer Einwilligung zur Datenverarbeitung
              <br />‍
            </strong>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte
            Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
            Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
            <br />
            <br />
            <strong>
              Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)
              <br />‍
            </strong>
            Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus
            Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch
            einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine
            Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen
            personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung
            nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO). Werden Ihre personenbezogenen Daten verarbeitet, um
            Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender
            personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
            Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten anschließend nicht mehr zum
            Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).
            <br />
            <br />
            <strong>
              Beschwerderecht bei der zuständigen Aufsichtsbehörde
              <br />‍
            </strong>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat Ihres gewöhnlichen Aufenthalts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das
            Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
            <br />
            <br />
            <strong>
              Recht auf Datenübertragbarkeit
              <br />‍
            </strong>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten,
            an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte
            Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            <br />
            <br />
            <strong>
              SSL- bzw. TLS-Verschlüsselung
              <br />‍
            </strong>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
            erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von
            Dritten mitgelesen werden.
            <br />
            <br />
            <strong>
              Verschlüsselter Zahlungsverkehr auf dieser Website
              <br />‍
            </strong>
            Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine Verpflichtung, uns Ihre Zahlungsdaten (z.B. Kontonummer bei
            Einzugsermächtigung) zu übermitteln, werden diese Daten zur Zahlungsabwicklung benötigt. Der Zahlungsverkehr über die gängigen
            Zahlungsmittel (Visa/MasterCard, Lastschriftverfahren) erfolgt ausschließlich über eine verschlüsselte SSL- bzw. TLS-Verbindung.
            Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von &quot;http://&quot; auf
            &quot;https://&quot; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.Bei verschlüsselter Kommunikation können Ihre
            Zahlungsdaten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
            <br />
            <br />
            <strong>
              Auskunft, Sperrung, Löschung und Berichtigung
              <br />‍
            </strong>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht, auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie
            sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
            <br />
            <br />
            <strong>
              Recht auf Einschränkung der Verarbeitung
              <br />‍
            </strong>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
            folgenden Fällen:
            <br />
            <br />
          </p>
          <ul role="list">
            <li>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um
              dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
              Daten zu verlangen.
            </li>
            <li>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah / geschieht, können Sie statt der Löschung die
              Einschränkung der Datenverarbeitung verlangen.
            </li>
            <li>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen
              Daten zu verlangen.
            </li>
            <li>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              <br />
            </li>
          </ul>
          <p>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
            der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der
            Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
            <br />
            <br />
            <strong>
              Widerspruch gegen Werbe-E-Mails
              <br />‍
            </strong>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich
            angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich
            ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
            <br />
            <br />
            <span className="text-span-11"></span>
            <strong>
              3. Datenerfassung auf unserer Website
              <br />
            </strong>
            <br />
            <strong>Cookies</strong>
            <br />
            Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten
            keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine
            Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der von uns verwendeten Cookies
            sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem
            Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
            wiederzuerkennen. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur
            im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen
            der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website
            eingeschränkt sein.Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter,
            von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
            gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und
            optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert
            werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.
            <br />
            <br />
            <strong>
              Server-Log-Dateien
              <br />
            </strong>
            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind: Name der abgerufenen Webseite, bzw. URL, Zugriffsstatus/http-Statuscode, übertragene
            Datenmenge, Browsertyp und Browserversion, verwendetes Betriebssystem und dessen Oberfläche, Referrer URL, Hostname des
            zugreifenden Rechners, Datum und Uhrzeit des Abrufs/der Serveranfrage, IP-Adresse und Zufallsgenerierte Schlüsselnummer des
            Cookies bzw. der Sitzung. <br />
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
            <br />
            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
            Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu müssen die Server-Log-Files
            erfasst werden.
            <br />
            <br />
            <strong>
              Registrierung auf dieser Website für Unternehmen
              <br />
            </strong>
            Sie können sich auf unserer Website registrieren, um verifizierte Kontaktdaten von Kandidaten aus unserer Jobkartei zu erhalten.
            Die dazu eingegebenen Daten verwenden wir, um mit Ihnen gemeinsam Kampagen zur Kandidatensuche für unsere Plattform anzulegen.
            Ggf. werden wir Sie telefonisch kontaktieren, um Sie bei der Erstellung einer Kampagne zu unterstützen. Die bei der
            Registrierung abgefragten Pflichtangaben müssen vollständig angegeben werden. Anderenfalls werden wir die Registrierung
            ablehnen.
            <br />
            Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
            DSGVO). Sie können eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
            uns. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
            <br />
            <br />‍
            <strong>
              Speicherdauer
              <br />‍
            </strong>
            Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf unserer Website registriert sind und
            werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt. Ihr Profil wird gelöscht, wenn Sie selbst Ihr
            Profil löschen oder uns den Hinweis geben, dass Sie aus dem System gelöscht werden möchten. Wenn Sie auf unserer Plattform
            inaktiv sind (d.h. Sie haben sich länger als 12 Monate nicht in Ihrem Profil eingeloggt und keine aktiven Kampagenen) gehen wir
            davon aus, dass Sie unseren Service nicht benötigen. In diesem Fall werden wir Ihre Daten auf einen reinen Geschäftskontakt
            reduzieren, d.h. wir werden persönliche Daten wie Vorname, Nachname, Telefonnummer, E-Mail-Adresse, Kampagen, Zahlungsmittel,
            Kandidatenbewertung und Notizen aus unserem System löschen. Einen Monat vor Löschung werden wir Ihnen eine E-mail zusenden mit
            dem Hinweis, dass Ihre Daten in einem Monat automatisch aus unserem System gelöscht werden. Sie haben in dieser Email die
            Möglichkeit der Löschung zu widersprechen. Gesetzliche Aufbewahrungsfristen bleiben in jedem Fall unberührt.
            <br />
            <br />‍
            <strong>
              Sperrung
              <br />‍
            </strong>
            Wir sperren Ihr Profil, wenn Sie gegen die &quot;Care Rockets&quot;-Nutzungsbedingungen verstoßen. Einige Beispiele:Es werden
            User kontaktiert zum Zweck von Werbung, Reklame (die nicht der Findung eines Kandidaten oder Azubis dienen) oder anderem nicht
            erlaubtem Benehmen (z.B. Provokation, Belästigung oder Beleidigung). Es wird ein falscher Name oder andere Falschangaben im
            Profil getätigt oder eine falsche Identität vorgespiegelt. User, die bei Ihnen ein Vorstellungsgespräch hatten oder eine Stelle
            angeboten bekommen haben melden im Bezug auf Ihr Unternehmen oder Ihre Mitarbeiter schwerwiegende Probleme z.B. sexuelle
            Belästigung, Zahlungsausfall beim Gehalt, Verstöße gegen Arbeitsbedingungen.
            <br />
            <br />
            <strong>
              Verarbeiten von Daten (Kunden- und Vertragsdaten)
              <br />‍
            </strong>
            Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die Begründung, inhaltliche Ausgestaltung oder
            Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO,
            der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten
            über die Inanspruchnahme unserer Internetseiten (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies
            erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen. Die erhobenen Kundendaten
            werden nach Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche Aufbewahrungsfristen bleiben
            unberührt.
            <br />
            <br />
            <strong>
              Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitale Inhalte
              <br />‍
            </strong>
            Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung notwendig ist, etwa an das
            mit der Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann,
            wenn Sie der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche Einwilligung,
            etwa zu Zwecken der Werbung, erfolgt nicht. Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die
            Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
            <br />
            <br />
            <strong>
              Hubspot CRM
              <br />
            </strong>
            Wir nutzen Hubspot CRM auf dieser Website. Anbieter ist Hubspot Inc. 25 Street, Cambridge, MA 02141 USA (nachfolgend Hubspot
            CRM). Hubspot CRM ermöglicht es uns unter anderem, bestehende und potenzielle Kunden sowie Kundenkontakte zu verwalten. Mit
            Hilfe von Hubspot CRM sind wir in der Lage, Kundeninteraktionen per E-Mail, Social Media oder Telefon über verschiedene Kanäle
            hinweg zu erfassen, zu sortieren und zu analysieren. Die so erfassten personenbezogenen Daten können ausgewertet und für die
            Kommunikation mit dem potenziellen Kunden oder für Marketingmaßnahmen (z. B. Newslettermailings) verwendet werden. Mit Hubspot
            CRM sind wir ferner in der Lage, das Nutzerverhalten unserer Kontakte auf unserer Website zu erfassen und zu analysieren. Die
            Verwendung von Hubspot CRM erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
            Interesse an einer möglichst effizienten Kundenverwaltung und Kundenkommunikation. Sofern eine entsprechende Einwilligung
            abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG,
            soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
            Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar. Details entnehmen Sie der
            Datenschutzerklärung von Hubspot:{' '}
            <a href="https://legal.hubspot.com/de/privacy-policy" target="_blank">
              <strong>https://legal.hubspot.com/de/privacy-policy</strong>
            </a>
            . Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier:{' '}
            <a href="https://www.hubspot.de/data-privacy/privacy-shield" target="_blank">
              <strong>https://www.hubspot.de/data-privacy/privacy-shield</strong>
            </a>
            .<br />
            <br />
            <strong>
              4. Analyse-Tools und Werbung
              <br />
            </strong>
            <br />
            <strong>
              Google Tag Manager
              <br />
            </strong>
            Wir setzen den Google Tag Manager ein. Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.
            Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir Tracking- oder Statistik-Tools und andere Technologien auf unserer
            Website einbinden können. Der Google Tag Manager selbst erstellt keine Nutzerprofile, speichert keine Cookies und nimmt keine
            eigenständigen Analysen vor. Er dient lediglich der Verwaltung und Ausspielung der über ihn eingebundenen Tools. Der Google Tag
            Manager erfasst jedoch Ihre IP-Adresse, die auch an das Mutterunternehmen von Google in die Vereinigten Staaten übertragen
            werden kann. Der Einsatz des Google Tag Managers erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
            ein berechtigtes Interesse an einer schnellen und unkomplizierten Einbindung und Verwaltung verschiedener Tools auf seiner
            Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art.
            6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
            Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit
            widerrufbar.
            <br />
            <br />
            <strong>
              Google Analytics
              <br />
            </strong>
            Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Ireland Limited, Gordon House,
            Barrow Street, Dublin 4, Irland. Google Analytics verwendet so genannte &quot;Cookies&quot;. Das sind Textdateien, die auf Ihrem
            Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten
            Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort
            gespeichert. Die Speicherung von Google-Analytics-Cookies und die Nutzung dieses Analyse-Tools erfolgen auf Grundlage von Art. 6
            Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
            Webangebot als auch seine Werbung zu optimieren.
            <br />‍<br />‍
            <strong>
              IP Anonymisierung
              <br />
            </strong>
            Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
            Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor
            der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA
            übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung
            der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der
            Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von
            Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
            <br />
            <br />
            <strong>
              Browser Plugin
              <br />
            </strong>
            <strong>‍</strong>Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern;
            wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
            vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung
            der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
            indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:{' '}
            <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
              <strong>https://tools.google.com/dlpage/gaoptout?hl=de</strong>
            </a>
            .<br />
            <br />‍
            <strong>
              Widerspruch gegen Datenerfassung
              <br />‍
            </strong>
            Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein
            Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert: Google Analytics
            deaktivieren. Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von
            Google:{' '}
            <a href="https://support.google.com/analytics/answer/6004245?hl=de">
              <strong>https://support.google.com/analytics/answer/6004245?hl=de</strong>
            </a>
            .<br />
            <br />‍
            <strong>
              Auftragsverarbeitung
              <br />‍
            </strong>
            Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen
            Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
            <br />
            <br />‍
            <strong>
              Demografische Merkmale bei Google Analytics
              <br />‍
            </strong>
            Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte erstellt werden, die
            Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung
            von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können
            diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch
            Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen.
            <br />
            <br />
            <strong>
              Google Analytics Remarketing
              <br />‍
            </strong>
            Unsere Websites nutzen die Funktionen von Google Analytics Remarketing in Verbindung mit den geräteübergreifenden Funktionen von
            Google AdWords und Google DoubleClick. Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.
            Diese Funktion ermöglicht es die mit Google Analytics Remarketing erstellten Werbe-Zielgruppen mit den geräteübergreifenden
            Funktionen von Google AdWords und Google DoubleClick zu verknüpfen. Auf diese Weise können interessenbezogene, personalisierte
            Werbebotschaften, die in Abhängigkeit Ihres früheren Nutzungs- und Surfverhaltens auf einem Endgerät (z.B. Handy) an Sie
            angepasst wurden auch auf einem anderen Ihrer Endgeräte (z.B. Tablet oder PC) angezeigt werden. Haben Sie eine entsprechende
            Einwilligung erteilt, verknüpft Google zu diesem Zweck Ihren Web- und App-Browserverlauf mit Ihrem Google-Konto. Auf diese Weise
            können auf jedem Endgerät auf dem Sie sich mit Ihrem Google-Konto anmelden, dieselben personalisierten Werbebotschaften
            geschaltet werden. Zur Unterstützung dieser Funktion erfasst Google Analytics google-authentifizierte IDs der Nutzer, die
            vorübergehend mit unseren Google-Analytics-Daten verknüpft werden, um Zielgruppen für die geräteübergreifende Anzeigenwerbung zu
            definieren und zu erstellen. Sie können dem geräteübergreifenden Remarketing/Targeting dauerhaft widersprechen, indem Sie
            personalisierte Werbung in Ihrem Google-Konto deaktivieren; folgen Sie hierzu diesem Link:{' '}
            <a href="https://www.google.com/settings/ads/onweb/">
              <strong>https://www.google.com/settings/ads/onweb/</strong>
            </a>
            . Die Zusammenfassung der erfassten Daten in Ihrem Google-Konto erfolgt ausschließlich auf Grundlage Ihrer Einwilligung, die Sie
            bei Google abgeben oder widerrufen können (Art. 6 Abs. 1 lit. a DSGVO). Bei Datenerfassungsvorgängen, die nicht in Ihrem
            Google-Konto zusammengeführt werden (z.B. weil Sie kein Google-Konto haben oder der Zusammenführung widersprochen haben) beruht
            die Erfassung der Daten auf Art. 6 Abs. 1 lit. f DSGVO. Das berechtigte Interesse ergibt sich daraus, dass der Websitebetreiber
            ein Interesse an der anonymisierten Analyse der Websitebesucher zu Werbezwecken hat. Weitergehende Informationen und die
            Datenschutzbestimmungen finden Sie in der Datenschutzerklärung von Google unter:{' '}
            <a href="https://policies.google.com/technologies/ads?hl=de">
              <strong>https://policies.google.com/technologies/ads?hl=de</strong>
            </a>
            .<br />
            <br />
            <strong>
              Google AdWords und Google Conversion-Tracking
              <br />‍
            </strong>
            Diese Website verwendet Google AdWords. AdWords ist ein Online-Werbeprogramm der Google Ireland Limited, Gordon House, Barrow
            Street, Dublin 4, Irland (“Google”). Im Rahmen von Google AdWords nutzen wir das so genannte Conversion-Tracking. Wenn Sie auf
            eine von Google geschaltete Anzeige klicken wird ein Cookie für das Conversion-Tracking gesetzt. Bei Cookies handelt es sich um
            kleine Textdateien, die der Internet-Browser auf dem Computer des Nutzers ablegt. Diese Cookies verlieren nach 30 Tagen ihre
            Gültigkeit und dienen nicht der persönlichen Identifizierung der Nutzer. Besucht der Nutzer bestimmte Seiten dieser Website und
            das Cookie ist noch nicht abgelaufen, können Google und wir erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser
            Seite weitergeleitet wurde. Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies können nicht über die Websites von
            AdWords-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu,
            Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Kunden erfahren
            die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite
            weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen. Wenn Sie
            nicht am Tracking teilnehmen möchten, können Sie dieser Nutzung widersprechen, indem Sie das Cookie des Google
            Conversion-Trackings über ihren Internet-Browser unter Nutzereinstellungen leicht deaktivieren. Sie werden sodann nicht in die
            Conversion-Tracking Statistiken aufgenommen. Die Speicherung von “Conversion-Cookies” und die Nutzung dieses Tracking-Tools
            erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des
            Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren. Mehr Informationen zu Google AdWords und
            Google Conversion-Tracking finden Sie in den Datenschutzbestimmungen von Google:{' '}
            <a href="https://policies.google.com/privacy?hl=de">
              <strong>https://policies.google.com/privacy?hl=de</strong>
            </a>
            . Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall
            erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
            <br />
            <br />
            <strong>
              Meta-Pixel (ehemals Facebook Pixel)
              <br />‍
            </strong>
            Diese Website nutzt zur Konversionsmessung der Besucheraktions-Pixel von Facebook/Meta. Anbieter dieses Dienstes ist die Meta
            Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden nach Aussage von Facebook jedoch
            auch in die USA und in andere Drittländer übertragen. So kann das Verhalten der Seitenbesucher nachverfolgt werden, nachdem
            diese durch Klick auf eine Facebook-Werbeanzeige auf die Website des Anbieters weitergeleitet wurden. Dadurch können die
            Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke ausgewertet werden und zukünftige
            Werbemaßnahmen optimiert werden. Die erhobenen Daten sind für uns als Betreiber dieser Website anonym, wir können keine
            Rückschlüsse auf die Identität der Nutzer ziehen. Die Daten werden aber von Facebook gespeichert und verarbeitet, sodass eine
            Verbindung zum jeweiligen Nutzerprofil möglich ist und Facebook die Daten für eigene Werbezwecke, entsprechend der
            Facebook-Datenverwendungsrichtlinie (
            <a href="https://de-de.facebook.com/about/privacy/" target="_blank">
              <strong>https://de-de.facebook.com/about/privacy/</strong>
            </a>
            ) verwenden kann. Dadurch kann Facebook das Schalten von Werbeanzeigen auf Seiten von Facebook sowie außerhalb von Facebook
            ermöglichen. Diese Verwendung der Daten kann von uns als Seitenbetreiber nicht beeinflusst werden. Die Nutzung dieses Dienstes
            erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit
            widerrufbar. Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an Facebook
            weitergeleitet werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
            Irland gemeinsam für diese Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschränkt sich
            dabei ausschließlich auf die Erfassung der Daten und deren Weitergabe an Facebook. Die nach der Weiterleitung erfolgende
            Verarbeitung durch Facebook ist nicht Teil der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen wurden
            in einer Vereinbarung über gemeinsame Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie unter:{' '}
            <a href="https://www.facebook.com/legal/controller_addendum" target="_blank">
              <strong>https://www.facebook.com/legal/controller_addendum</strong>
            </a>
            . Laut dieser Vereinbarung sind wir für die Erteilung der Datenschutzinformationen beim Einsatz des Facebook-Tools und für die
            datenschutzrechtlich sichere Implementierung des Tools auf unserer Website verantwortlich. Für die Datensicherheit der
            Facebook-Produkte ist Facebook verantwortlich. Betroffenenrechte (z. B. Auskunftsersuchen) hinsichtlich der bei Facebook
            verarbeiteten Daten können Sie direkt bei Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind
            wir verpflichtet, diese an Facebook weiterzuleiten. Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
            EU-Kommission gestützt. Details finden Sie hier:{' '}
            <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank">
              <strong>https://www.facebook.com/legal/EU_data_transfer_addendum</strong>
            </a>{' '}
            und{' '}
            <a href="https://de-de.facebook.com/help/566994660333381" target="_blank">
              <strong>https://de-de.facebook.com/help/566994660333381</strong>
            </a>
            . In den Datenschutzhinweisen von Facebook finden Sie weitere Hinweise zum Schutz Ihrer Privatsphäre:{' '}
            <a href="https://de-de.facebook.com/about/privacy/" target="_blank">
              <strong>https://de-de.facebook.com/about/privacy/</strong>
            </a>
            . Sie können außerdem die Remarketing-Funktion „Custom Audiences” im Bereich Einstellungen für Werbeanzeigen unter{' '}
            <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen" target="_blank">
              <strong>https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</strong>
            </a>{' '}
            deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein. Wenn Sie kein Facebook Konto besitzen, können Sie nutzungsbasierte
            Werbung von Facebook auf der Website der European Interactive Digital Advertising Alliance deaktivieren:{' '}
            <a href="http://www.youronlinechoices.com/de/praferenzmanagement/" target="_blank">
              <strong>http://www.youronlinechoices.com/de/praferenzmanagement/</strong>
            </a>
            .<br />
            <br />
            <strong>
              LinkedIn Insight Tag
              <br />‍
            </strong>
            Diese Website nutzt das Insight-Tag von LinkedIn. Anbieter dieses Dienstes ist die LinkedIn Ireland Unlimited Company, Wilton
            Plaza, Wilton Place, Dublin 2, Irland.
            <br />
            <br />
            Datenverarbeitung durch LinkedIn Insight Tag
            <br />
            Mit Hilfe des LinkedIn Insight Tags erhalten wir Informationen über die Besucher unserer Website. Ist ein Websitebesucher bei
            LinkedIn registriert, können wir u. a. die beruflichen Eckdaten (z. B. Karrierestufe, Unternehmensgröße, Land, Standort, Branche
            und Berufsbezeichnung) unserer Websitebesucher analysieren und so unsere Seite besser auf die jeweiligen Zielgruppen ausrichten.
            Ferner können wir mit Hilfe von LinkedIn Insight Tags messen, ob die Besucher unserer Webseiten einen Kauf oder eine sonstige
            Aktion tätigen (Conversion-Messung). Die Conversion-Messung kann auch geräteübergreifend erfolgen (z. B. von PC zu Tablet).
            LinkedIn Insight Tag bietet außerdem eine Retargeting-Funktion an, mit deren Hilfe wir den Besuchern unserer Website
            zielgerichtete Werbung außerhalb der Website anzeigen lassen können, wobei laut LinkedIn keine Identifikation des
            Werbeadressaten stattfindet. LinkedIn selbst erfasst außerdem sogenannte Logfiles (URL, Referrer-URL, IP-Adresse, Geräte- und
            Browsereigenschaften und Zeitpunkt des Zugriffs). Die IP-Adressen werden gekürzt oder (sofern sie genutzt werden, um
            LinkedIn-Mitglieder geräteübergreifend zu erreichen) gehasht (pseudonymisiert). Die direkten Kennungen der LinkedIn-Mitglieder
            werden nach sieben Tagen von LinkedIn gelöscht. Die verbleibenden pseudonymisierten Daten werden dann innerhalb von 180 Tagen
            gelöscht. Die von LinkedIn erhobenen Daten können von uns als Websitebetreiber nicht bestimmten Einzelpersonen zugeordnet
            werden. LinkedIn wird die erfassten personenbezogenen Daten der Websitebesucher auf seinen Servern in den USA speichern und sie
            im Rahmen eigener Werbemaßnahmen nutzen. Details entnehmen Sie der Datenschutzerklärung von LinkedIn unter{' '}
            <a href="https://www.linkedin.com/legal/privacy-policy#choices-oblig" target="_blank">
              <strong>https://www.linkedin.com/legal/privacy-policy#choices-oblig</strong>
            </a>
            .<br />
            <br />
            Rechtsgrundlage
            <br />
            Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des o. g. Dienstes ausschließlich auf Grundlage von Art.
            6 Abs. 1 lit. a DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine Einwilligung eingeholt wurde,
            erfolgt die Verwendung dieses Dienstes auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO; der Websitebetreiber hat ein berechtigtes
            Interesse an effektiven Werbemaßnahmen unter Einschluss der sozialen Medien. Die Datenübertragung in die USA wird auf die
            Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier:{' '}
            <a href="https://www.linkedin.com/legal/l/dpa" target="_blank">
              <strong>https://www.linkedin.com/legal/l/dpa</strong>
            </a>{' '}
            und{' '}
            <a href="https://www.linkedin.com/legal/l/eu-sccs" target="_blank">
              <strong>https://www.linkedin.com/legal/l/eu-sccs</strong>
            </a>
            .<br />
            <br />
            Widerspruch gegen die Verwendung von LinkedIn Insight Tag
            <br />
            Der Analyse des Nutzungsverhaltens sowie zielgerichtete Werbung durch LinkedIn unter folgendem Link widersprechen:{' '}
            <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" target="_blank">
              <strong>https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</strong>
            </a>
            . Des Weiteren können Mitglieder von LinkedIn die Nutzung ihrer personenbezogenen Daten zu Werbezwecken in den
            Kontoeinstellungen steuern. Um einer Verknüpfung von auf unserer Website erhobenen Daten durch LinkedIn und Ihrem LinkedIn
            Account zu vermeiden, müssen Sie sich aus Ihrem LinkedIn-Account ausloggen bevor Sie unsere Website aufsuchen.
            <br />
            <br />
            <strong>
              5. Newsletter
              <br />
            </strong>
            <br />
            <strong>
              Newsletterdaten
              <br />‍
            </strong>
            Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie
            Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem
            Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten
            verwenden wir ausschließlich für den Versand der angeforderten Informationen und geben diese nicht an Dritte weiter. Die
            Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung
            (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum
            Versand des Newsletters können Sie jederzeit widerrufen, etwa über den &quot;Austragen&quot;-Link im Newsletter. Die
            Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die von Ihnen zum Zwecke des
            Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der
            Abbestellung des Newsletters gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden bleiben hiervon unberührt.
            <br />
            <br />
            <strong>
              MailChimp
              <br />‍
            </strong>
            Diese Website nutzt die Dienste von MailChimp für den Versand von Newslettern. Anbieter ist die Rocket Science Group LLC, 675
            Ponce De Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA. MailChimp ist ein Dienst, mit dem u.a. der Versand von Newslettern
            organisiert und analysiert werden kann. Wenn Sie Daten zum Zwecke des Newsletterbezugs eingeben (z.B. E-Mail-Adresse), werden
            diese auf den Servern von MailChimp in den USA gespeichert. MailChimp verfügt über eine Zertifizierung nach dem
            “EU-US-Privacy-Shield”. Der “Privacy-Shield” ist ein Übereinkommen zwischen der Europäischen Union (EU) und den USA, das die
            Einhaltung europäischer Datenschutzstandards in den USA gewährleisten soll. Mit Hilfe von MailChimp können wir unsere
            Newsletterkampagnen analysieren. Wenn Sie eine mit MailChimp versandte E-Mail öffnen, verbindet sich eine in der E-Mail
            enthaltene Datei (sog. web-beacon) mit den Servern von MailChimp in den USA. So kann festgestellt werden, ob eine
            Newsletter-Nachricht geöffnet und welche Links ggf. angeklickt wurden. Außerdem werden technische Informationen erfasst (z.B.
            Zeitpunkt des Abrufs, IP-Adresse, Browsertyp und Betriebssystem). Diese Informationen können nicht dem jeweiligen
            Newsletter-Empfänger zugeordnet werden. Sie dienen ausschließlich der statistischen Analyse von Newsletterkampagnen. Die
            Ergebnisse dieser Analysen können genutzt werden, um künftige Newsletter besser an die Interessen der Empfänger anzupassen. Wenn
            Sie keine Analyse durch MailChimp wollen, müssen Sie den Newsletter abbestellen. Hierfür stellen wir in jeder
            Newsletternachricht einen entsprechenden Link zur Verfügung. Des Weiteren können Sie den Newsletter auch direkt auf der Website
            abbestellen. Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese
            Einwilligung jederzeit widerrufen, indem Sie den Newsletter abbestellen. Die Rechtmäßigkeit der bereits erfolgten
            Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
            Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters sowohl von
            unseren Servern als auch von den Servern von MailChimp gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden
            bleiben hiervon unberührt. Näheres entnehmen Sie den Datenschutzbestimmungen von MailChimp unter:{' '}
            <a href="https://mailchimp.com/legal/terms/">
              <strong>https://mailchimp.com/legal/terms/</strong>
            </a>
            .<br />‍<br />‍
            <strong>
              Abschluss eines Data-Processing-Agreements
              <br />‍
            </strong>
            Wir haben ein sog. „Data-Processing-Agreement“ mit MailChimp abgeschlossen, in dem wir MailChimp verpflichten, die Daten unserer
            Kunden zu schützen und sie nicht an Dritte weiterzugeben. Dieser Vertrag kann unter folgendem Link eingesehen werden:{' '}
            <a href="https://mailchimp.com/legal/forms/data-processing-agreement/sample-agreement/">
              <strong>https://mailchimp.com/legal/forms/data-processing-agreement/sample-agreement/</strong>
            </a>
            .<br />
            <br />
            <strong>
              6. Plugins und Tools
              <br />
              <br />
              Google Maps
              <br />‍
            </strong>
            Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited, Gordon House, Barrow
            Street, Dublin 4, Irland. Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese
            Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser
            Seite hat keinen Einfluss auf diese Datenübertragung. Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
            Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
            stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Mehr Informationen zum Umgang mit Nutzerdaten
            finden Sie in der Datenschutzerklärung von Google:{' '}
            <a href="https://policies.google.com/privacy?hl=de">
              <strong>https://policies.google.com/privacy?hl=de</strong>
            </a>
            .<br />
            <br />
            <strong>
              Google reCAPTCHA
              <br />‍
            </strong>
            Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”) auf unseren Websites. Anbieter ist die Google Ireland Limited, Gordon
            House, Barrow Street, Dublin 4, Irland (“Google”). Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf unseren Websites
            (z.B. in einem Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert
            reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der
            Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z.B. IP-Adresse, Verweildauer
            des Websitebesuchers auf der Website oder vom Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten werden an
            Google weitergeleitet. Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf
            hingewiesen, dass eine Analyse stattfindet. Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
            Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher automatisierter Ausspähung und vor
            SPAM zu schützen. Weitere Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung von Google entnehmen Sie folgenden
            Links:{' '}
            <a href="https://policies.google.com/privacy?hl=de">
              <strong>https://policies.google.com/privacy?hl=de</strong>
            </a>{' '}
            und{' '}
            <a href="https://www.google.com/recaptcha/intro/android.html">
              <strong>https://www.google.com/recaptcha/intro/android.html</strong>
            </a>
            .<br />
            <br />
            <strong>
              Wistia
              <br />‍
            </strong>
            Unsere Website nutzt Plugins der Seite Wistia. Betreiber der Seiten ist die Wistia, Inc.17 Tudor Street, Cambridge,
            Massachusetts, 02139 USA. Wenn Sie eine unserer mit einem Wistia-Plugin ausgestatteten Seiten besuchen, wird eine Verbindung zu
            den Servern von Wistia hergestellt. Dabei wird dem Wistia-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Wenn Sie
            in Ihrem Wistia-Account eingeloggt sind, ermöglichen Sie Wistia, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen.
            Dies können Sie verhindern, indem Sie sich aus Ihrem Wistia-Account ausloggen. Die Nutzung von Wistia erfolgt im Interesse einer
            ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
            DSGVO dar. Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Wistia unter:{' '}
            <a href="https://wistia.com/privacy">
              <strong>
                https://wistia.com/privacy
                <br />
                <br />‍
              </strong>
            </a>
            <strong>
              7. Audio- und Videokonferenzen
              <br />
            </strong>
            <br />
            <strong>
              Datenverarbeitung
              <br />
            </strong>
            Für die Kommunikation mit unseren Kunden setzen wir unter anderen Online-Konferenz-Tools ein. Die im Einzelnen von uns genutzten
            Tools sind unten aufgelistet. Wenn Sie mit uns per Video- oder Audiokonferenz via Internet kommunizieren, werden Ihre
            personenbezogenen Daten von uns und dem Anbieter des jeweiligen Konferenz-Tools erfasst und verarbeitet. Die Konferenz-Tools
            erfassen dabei alle Daten, die Sie zur Nutzung der Tools bereitstellen/einsetzen (E-Mail-Adresse und/oder Ihre Telefonnummer).
            Ferner verarbeiten die Konferenz-Tools die Dauer der Konferenz, Beginn und Ende (Zeit) der Teilnahme an der Konferenz, Anzahl
            der Teilnehmer und sonstige „Kontextinformationen“ im Zusammenhang mit dem Kommunikationsvorgang (Metadaten). Des Weiteren
            verarbeitet der Anbieter des Tools alle technischen Daten, die zur Abwicklung der Online-Kommunikation erforderlich sind. Dies
            umfasst insbesondere IP-Adressen, MAC-Adressen, Geräte-IDs, Gerätetyp, Betriebssystemtyp und -version, Client-Version,
            Kameratyp, Mikrofon oder Lautsprecher sowie die Art der Verbindung. Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen
            oder in sonstiger Weise bereitgestellt werden, werden diese ebenfalls auf den Servern der Tool-Anbieter gespeichert. Zu solchen
            Inhalten zählen insbesondere Cloud-Aufzeichnungen, Chat-/ Sofortnachrichten, Voicemails hochgeladene Fotos und Videos, Dateien,
            Whiteboards und andere Informationen, die während der Nutzung des Dienstes geteilt werden.Bitte beachten Sie, dass wir nicht
            vollumfänglich Einfluss auf die Datenverarbeitungsvorgänge der verwendeten Tools haben. Unsere Möglichkeiten richten sich
            maßgeblich nach der Unternehmenspolitik des jeweiligen Anbieters. Weitere Hinweise zur Datenverarbeitung durch die
            Konferenztools entnehmen Sie den Datenschutzerklärungen der jeweils eingesetzten Tools, die wir unter diesem Text aufgeführt
            haben.
            <br />
            <br />
            <strong>
              Zweck und Rechtsgrundlagen
              <br />‍
            </strong>
            ie Konferenz-Tools werden genutzt, um mit angehenden oder bestehenden Vertragspartnern zu kommunizieren oder bestimmte
            Leistungen gegenüber unseren Kunden anzubieten (Art. 6 Abs. 1 lit. b DSGVO). Des Weiteren dient der Einsatz der Tools der
            allgemeinen Vereinfachung und Beschleunigung der Kommunikation mit uns bzw. unserem Unternehmen (berechtigtes Interesse im Sinne
            von Art. 6 Abs. 1 lit. f DSGVO). Soweit eine Einwilligung abgefragt wurde, erfolgt der Einsatz der betreffenden Tools auf
            Grundlage dieser Einwilligung; die Einwilligung ist jederzeit mit Wirkung für die Zukunft widerrufbar.
            <br />
            <br />
            <strong>
              Speicherdauer
              <br />‍
            </strong>
            Die unmittelbar von uns über die Video- und Konferenz-Tools erfassten Daten werden von unseren Systemen gelöscht, sobald Sie uns
            zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt.
            Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen. Zwingende gesetzliche Aufbewahrungsfristen bleiben
            unberührt. Auf die Speicherdauer Ihrer Daten, die von den Betreibern der Konferenz-Tools zu eigenen Zwecken gespeichert werden,
            haben wir keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte direkt bei den Betreibern der Konferenz-Tools.
            <br />
            <br />
            <strong>
              Eingesetzte Konferenz-Tools
              <br />‍
            </strong>
            Wir setzen folgende Konferenz-Tools ein:
            <br />
            <br />
            Zoom
            <br />
            Wir nutzen Zoom. Anbieter dieses Dienstes ist die Zoom Communications Inc., San Jose, 55 Almaden Boulevard, 6th Floor, San Jose,
            CA 95113, USA. Details zur Datenverarbeitung entnehmen Sie der Datenschutzerklärung von Zoom:{' '}
            <a href="https://zoom.us/de-de/privacy.html" target="_blank">
              <strong>https://zoom.us/de-de/privacy.html</strong>
            </a>
            . Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier:{' '}
            <a href="https://zoom.us/de-de/privacy.html" target="_blank">
              <strong>https://zoom.us/de-de/privacy.html</strong>
            </a>
            .<br />
            <br />
            Google Meet
            <br />
            Wir nutzen Google Meet. Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Details zur
            Datenverarbeitung entnehmen Sie der Datenschutzerklärung von Google:{' '}
            <a href="https://policies.google.com/privacy?hl=de" target="_blank">
              <strong>https://policies.google.com/privacy?hl=de</strong>
            </a>
            .
          </p>
          <div className="padding-30"></div>
        </div>
      </section>
    </div>
  );
};
