import React from 'react';

export enum Verguetung {
  FAIREBEZAHLUNG = 'Faire Bezahlung',
  BETRIEBLICHEALTERSVORSORGE = 'Betriebliche Altersvorsorge',
  WEIHNACHTSGELD = 'Weihnachtsgeld',
  URLAUBSGELD = 'Urlaubsgeld',
  SONDERZAHLUNGEN = 'Sonderzahlungen',
  GEWINNBETEILIGUNG = 'Gewinnbeteiligung',
}
