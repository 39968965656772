import React, { FunctionComponent, useEffect, useState } from 'react';
import { MontageArbeitnehmer } from 'app/shared/model/enumerations/montage.model';

interface IMontageAuswahlProps {
  title: string;
  onAuswahlChange: Function;
  initialValue: any;
  montageAuswahl: any;
}

export const MontageAuswahl: FunctionComponent<IMontageAuswahlProps> = ({ title, onAuswahlChange, initialValue, montageAuswahl }) => {
  const [selectMontage, setSelectMontage] = useState('');

  useEffect(() => {
    if (initialValue && initialValue.length > 0) {
      setSelectMontage(initialValue);
      onAuswahlChange(initialValue);
    } else {
      setSelectMontage('');
      onAuswahlChange('');
    }
  }, [initialValue]);

  const handleSelectMontage = (montage: string) => {
    setSelectMontage(montage);
    onAuswahlChange(montage);
  };

  return (
    <div id="employer_reg_03" className="wrapper-100">
      <p className="paragraph-workflow">
        <strong>{title}</strong>
      </p>
      <div className="padding-10"></div>
      <div className="w-layout-grid grid-skills-10">
        {montageAuswahl.map(montage => (
          <div
            key={montage}
            className={`click-button-medium ${selectMontage === montage ? 'highlighted' : ''}`}
            onClick={() => handleSelectMontage(montage)}
          >
            {montage}
          </div>
        ))}
      </div>
    </div>
  );
};
