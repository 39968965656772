import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IArbeitnehmersuche, defaultValue } from 'app/shared/model/arbeitnehmersuche.model';

const initialState: EntityState<IArbeitnehmersuche> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/arbeitnehmersuches';

// Actions

export const getEntities = createAsyncThunk('arbeitnehmersuche/fetch_entity_list', async ({}: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IArbeitnehmersuche[]>(requestUrl);
});

export const getArbeitnehmerSucheByIds = createAsyncThunk(
  'arbeitnehmersuche/fetch_entity_list_by_user_id',
  async (ids: string[]) => {
    /*make the ids as request body in a post request*/
    const requestUrl = `${apiUrl}/ids`;
    return axios.post<IArbeitnehmersuche[]>(requestUrl, ids);
  },
  { serializeError: serializeAxiosError }
);

export const getEntitiesByUserID = createAsyncThunk(
  'arbeitnehmersuche/fetch_entity_list_by_user_id',
  async (userID: string | number) => {
    const requestUrl = `${apiUrl}/user/${userID}`;
    return axios.get<IArbeitnehmersuche[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getArbeitnehmerSuche = createAsyncThunk(
  'arbeitnehmersuche/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IArbeitnehmersuche>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'arbeitnehmersuche/create_entity',
  async (entity: IArbeitnehmersuche, thunkAPI) => {
    const result = await axios.post<IArbeitnehmersuche>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'arbeitnehmersuche/update_entity',
  async (entity: IArbeitnehmersuche, thunkAPI) => {
    const result = await axios.put<IArbeitnehmersuche>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'arbeitnehmersuche/partial_update_entity',
  async (entity: IArbeitnehmersuche, thunkAPI) => {
    const result = await axios.patch<IArbeitnehmersuche>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'arbeitnehmersuche/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IArbeitnehmersuche>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ArbeitnehmersucheSlice = createEntitySlice({
  name: 'arbeitnehmersuche',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getArbeitnehmerSuche.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = defaultValue;
      })
      .addMatcher(isFulfilled(getEntities, getArbeitnehmerSucheByIds, getEntitiesByUserID), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getArbeitnehmerSuche), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, getArbeitnehmerSucheByIds), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

// Reducer
export default ArbeitnehmersucheSlice.reducer;
