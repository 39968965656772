import './home.scss';

import React, { useEffect } from 'react';

import { getEarlyBirdUrl, REDIRECT_URL } from 'app/shared/util/url-utils';
import Testimonial from 'app/shared/slider/testimonial';

export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const redirectURL = localStorage.getItem(REDIRECT_URL);
    if (redirectURL) {
      localStorage.removeItem(REDIRECT_URL);
      location.href = `${location.origin}${redirectURL}`;
    }
  });

  return (
    <div>
      <div className="body-with-image">
        <div className="section-hero-std">
          <div className="padding-section-30"></div>
          <div className="container-hero-std w-container">
            <h1 className="heading-float-1">Die Jobplattform für alle, die bei der Arbeit einen Blaumann tragen.</h1>
            <div className="padding-10"></div>
            <a href={getEarlyBirdUrl()} className="call-to-action-max w-button">
              jetzt registrieren
            </a>
          </div>
        </div>
      </div>
      <div className="section-plain-white">
        <div className="padding-section-30"></div>
        <div className="container-std w-container">
          <h1 className="heading-1">Jobsuche neu gedacht</h1>
          <div className="padding-10"></div>
          <div className="w-layout-grid grid-std-2">
            <div id="w-node-_63a1a6be-4d94-2ed2-f6ca-dcfff1773a9d-1e498168" className="div-block-grid-std-copy">
              <img
                src="../../../content/images/Rocket_1Rocket.webp"
                loading="lazy"
                width="120"
                id="w-node-_0762dc9f-d9ed-9ce6-4313-1f282196ee92-1e498168"
                alt="Ein Symbol für die schnelle Weiteretwicklung, dargestellt durch eine Rakete. Das Symbol symbolisiert schnellen Fortschritt oder optimale Konditionen."
                className="image-div-block"
              />
              <h2 id="w-node-c10535ea-de48-1360-11e8-72264b664f4d-1e498168" className="heading-3 white">
                <span className="text-span-3">Mitarbeiter</span> <br />
                lieben unseren Ansatz
              </h2>
              <p id="w-node-f98814c6-5c5b-dee2-f324-329018099ab9-1e498168" className="paragraph-std-white">
                Unternehmen bewerben sich künftig bei Ihnen, ohne dass Sie etwas dafür tun müssen! Sie erhalten qualifizierte Jobangebote
                und können sich stets sicher sein, zu den besten Konditionen zu arbeiten.
              </p>
            </div>
            <div id="w-node-_6baf4bb4-5285-d50b-1c2b-d0ebe1407289-1e498168" className="div-block-grid-std-copy">
              <img
                src="../../../content/images/Stopwatch_1Stopwatch.webp"
                loading="lazy"
                width="120"
                id="w-node-d5f26107-8660-c9be-4c58-c5f92edf5dc2-1e498168"
                alt="Ein Symbol für Effizienz, dargestellt durch eine Stoppuhr. Das Symbol symbolisiert hohe Geschwindigkeit bei der Mitarbeitersuche."
                className="image-div-block"
              />
              <h2 id="w-node-_14f9e051-1694-7461-33a4-5a1a6c94186b-1e498168" className="heading-3 white">
                <span className="text-span-12">Unternehmen</span>
                <span>
                  {' '}
                  <br />
                </span>
                lieben unsere Effizienz
              </h2>
              <p id="w-node-b4abd90e-dcbb-2da2-2713-47e21d0a4fb3-1e498168" className="paragraph-std-white">
                Langwierige und kostenintensive Mitarbeitersuche über Stellenanzeigen oder Headhunter sind Vergangenheit. Unsere Künstliche
                Intelligenz liefert Ihnen genau die Kandidaten, die Ihren Anforderungen entsprechen.
              </p>
            </div>
          </div>
          <div className="padding-20"></div>
          <a href="/flendly" className="call-to-action-max w-button">
            mehr erfahren
          </a>
        </div>
        <div className="padding-section-30"></div>
      </div>

      {/*TODO nach early bird wieder reinbauen*/}
      {/*      <section className="section-ci-gradient">
        <div className="padding-section-60"></div>
        <div className="container-std">
          <h2 className="heading-2 white">Was unsere Kunden über uns sagen</h2>
          <Testimonial />
        </div>
        <div className="padding-section-60"></div>
      </section>*/}

      <div className="section-ci-gradient">
        <div className="padding-section-30"></div>
        <div className="container-std w-container">
          <h1 className="heading-1">Was macht Flendly so besonders</h1>
          <h2 className="heading-float-3">Wir drehen den Bewerbungsprozess um und machen ihn so simpel wie tindern</h2>
          <div className="padding-10"></div>
          <img
            src="../../../content/images/Warum-Flendly.png"
            loading="lazy"
            width="771"
            sizes="(max-width: 991px) 90vw, 771px"
            alt="Grafik, die den Matching Prozess zwischen Arbeitnehmern und Arbeitgebern bei Flendly darstellt."
            srcSet="../../../content/images/Warum-Flendly-p-500.png 500w, ../../../content/images/Warum-Flendly-p-800.png 800w, ../../../content/images/Warum-Flendly-p-1080.png 1080w,../../../content/images/Warum-Flendly-p-1600.png 1600w,../../../content/images/Warum-Flendly-p-2000.png 2000w,../../../content/images/Warum-Flendly-p-2600.png 2600w,../../../content/images/Warum-Flendly-p-3200.png 3200w,../../../content/images/Warum-Flendly.png 3282w"
            className="image-std"
          />
          <div className="padding-20"></div>
          <a href={getEarlyBirdUrl()} className="call-to-action-max w-button">
            Jetzt registrieren
          </a>
        </div>
        <div className="padding-section-30"></div>
      </div>
      <div className="section-image">
        <div className="padding-section-100"></div>
        <div className="container-std w-container">
          <h2 className="heading-2 white">TraumJobs finden können wir am besten</h2>
          <h2 className="heading-float-2">Lass uns Dir dabei helfen, Deinen zu finden.</h2>
          <div className="padding-10"></div>
          <a href={getEarlyBirdUrl()} className="call-to-action-max w-button">
            jetzt Jobs finden
          </a>
        </div>
        <div className="padding-section-100"></div>
      </div>
    </div>
  );
};

export default Home;
