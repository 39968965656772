import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface GenericAuswahlProps {
  onAuswahlChange: (value: string[]) => void;
  initialValue: string[];
  col: Record<string, string>;
  title: string;
  label: string;
  minSelect?: number;
  maxSelect?: number;
  id?: string;
}

export const GenericAuswahl: React.FC<GenericAuswahlProps> = ({
  onAuswahlChange,
  initialValue,
  col,
  title,
  label,
  maxSelect = 8,
  id: string,
}) => {
  const [selectValue, setSelectValue] = useState<string[]>([]);

  useEffect(() => {
    if (initialValue) {
      setSelectValue(initialValue);
    }
  }, [initialValue]);

  const getKeyByValue = (value: string) => {
    return Object.keys(col).find(key => col[key] === value);
  };

  const handleSelectValue = (value: string) => {
    const selectedKey = getKeyByValue(value);
    if (!selectedKey) return;

    let updatedValues;

    if (maxSelect === 1) {
      updatedValues = [selectedKey];
    } else {
      if (selectValue.includes(selectedKey)) {
        updatedValues = selectValue.filter(item => item !== selectedKey);
      } else if (selectValue.length < maxSelect) {
        updatedValues = [...selectValue, selectedKey];
      } else {
        updatedValues = selectValue;
        toast.info(`Du kannst maximal ${maxSelect} auswählen.`);
      }
    }

    setSelectValue(updatedValues);
    onAuswahlChange(updatedValues);
  };

  return (
    <div id="employer_reg_04" className="div-block-workflow">
      <div className="padding-10"></div>

      <p className="paragraph-workflow">
        <strong>
          {title} {maxSelect > 1 && '(Mehrfachauswahl möglich)'}{' '}
        </strong>
      </p>

      <div className="padding-10"></div>

      <div className="field-label-std">
        <strong>{label}</strong>
      </div>

      <div className="w-layout-grid grid-skills-10">
        {col &&
          Object.entries(col).map(([key, value]) => (
            <div
              key={key}
              className={`click-button-medium ${selectValue.includes(key) || selectValue.includes(value) ? 'highlighted' : ''}`}
              onClick={() => handleSelectValue(value)}
            >
              {value}
            </div>
          ))}
      </div>
    </div>
  );
};
