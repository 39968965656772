import React from 'react';
import { Link } from 'react-router-dom';
import { getLoginUrl } from 'app/shared/util/url-utils';

export const WelcomeSite = () => {
  return (
    <body className="body-light-grey">
      <div className="section-workflow-progress-bar">
        <div className="container-workflow w-container">
          <div className="padding-5"></div>
          <a href="../index.html" className="link-block w-inline-block">
            <img
              src="../../../content/images/Logo_plain_letters_cuttet.png"
              loading="lazy"
              width="262"
              sizes="(max-width: 479px) 100vw, 262px"
              alt="Flendly Logo, das als Home Button fungiert. Beim Anklicken des Logos wird man zur Startseite geleitet."
              srcSet="../../../content/images/Logo_plain_letters_cuttet-p-500.png 500w, ../../../content/images/Logo_plain_letters_cuttet-p-800.png 800w, ../../../content/images/Logo_plain_letters_cuttet-p-1080.png 1080w, ../../../content/images/Logo_plain_letters_cuttet-p-1600.png 1600w, ../../../content/images/Logo_plain_letters_cuttet.png 1639w"
              className="image"
            />
          </a>
        </div>
      </div>
      <div>
        <div className="section-workflow-steps">
          <div className="container-std w-container">
            <div className="padding-40"></div>
            <h1 className="heading-float-2 black">
              Ihre Email-Adresse wurde erfolgreich bestätigt.
              <br />
            </h1>
            <div className="padding-10 padding-20"></div>
            <p className="paragraph-workflow">
              <strong>Vielen Dank dass Sie sich für Flendly entschieden haben.</strong>
            </p>
            <p>Sie sind nun startbereit. Zu beginn möchten wir Ihnen ein paar Fragen stellen, um Ihre Bedürfnisse zu klären.</p>
            <div className="padding-10"></div>
          </div>
        </div>
        <div
          className="wrapper-460px form-block-centered"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '20px',
          }}
        >
          <Link to={getLoginUrl()} className="click-button-medium">
            Interview starten
          </Link>
        </div>
      </div>
    </body>
  );
};
