import React from 'react';
import { useParams } from 'react-router-dom';
import {
  BlogArbeitgeberStandort,
  BlogBewerbungsgespräch,
  BlogDigitalisierungImHandwerk,
  BlogGehalt,
  BlogJobsucheImHandwerk,
  BlogKarriereImHandwerk,
  BlogMitarbeiterGewinnen,
  BlogMitarbeiterUeberzeugen,
} from 'app/modules/blog/blog-articles';

export const BlogPostComponent = () => {
  const { id } = useParams();

  // Fetch your blog post using the id
  let blogPost;
  switch (id) {
    case 'gehalt-verhandlung':
      blogPost = <BlogGehalt />;
      break;
    case 'josuche-im-handwerk':
      blogPost = <BlogJobsucheImHandwerk />;
      break;
    case 'bewerbungsgespräch':
      blogPost = <BlogBewerbungsgespräch />;
      break;
    case 'karriere-im-handwerk':
      blogPost = <BlogKarriereImHandwerk />;
      break;
    case 'mitarbeiter-gewinnen':
      blogPost = <BlogMitarbeiterGewinnen />;
      break;

    case 'mitarbeiter-ueberzeugen':
      blogPost = <BlogMitarbeiterUeberzeugen />;
      break;
    case 'arbeitgeber-standort':
      blogPost = <BlogArbeitgeberStandort />;
      break;
    case 'digitalisierung-im-handwerk':
      blogPost = <BlogDigitalisierungImHandwerk />;
      break;
    // Add more cases as needed...
    default:
      blogPost = <div>Post not found.</div>;
  }

  return <>{blogPost}</>;
};
