import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { Arbeitnehmerskills } from 'app/shared/model/enumerations/arbeitnehmerskills.model';
import { License } from 'app/shared/model/enumerations/license.model';
import { MontageArbeitnehmer } from 'app/shared/model/enumerations/montage.model';
import { ISprache } from 'app/shared/model/sprache.model';
import { IZeitpunkt } from 'app/shared/model/zeitpunkt.model';
import { IArbeitsort } from 'app/shared/model/arbeitsort.model';

export interface IArbeitnehmersuche {
  id?: string;
  userID: string;
  zusatzqualifikationen?: string;
  standort?: IArbeitsort;
  gehalt?: string;
  auswahlBerufserfahrung?: string[];
  auswahlMontage?: MontageArbeitnehmer;
  auswahlBeruf?: string;
  auswahlAusbildungsstand?: string[];
  auswahlArbeitsverhaeltnis?: Arbeitsverhaeltnis[];
  auswahlLicense?: License[];
  zeitpunkt?: IZeitpunkt;
  auswahlSprache?: ISprache[];
  auswahlArbeitnehmerskills?: Arbeitnehmerskills[];
  auswahlQuereinsteiger?: string | null;
}

export const defaultValue: Readonly<IArbeitnehmersuche> = {
  userID: null,
  zusatzqualifikationen: '',
  standort: {} as IArbeitsort,
  gehalt: '',
  auswahlBerufserfahrung: [],
  auswahlMontage: MontageArbeitnehmer.nein,
  auswahlBeruf: '',
  auswahlAusbildungsstand: [],
  auswahlArbeitsverhaeltnis: [],
  auswahlLicense: [],
  auswahlSprache: [],
  auswahlArbeitnehmerskills: [],
  auswahlQuereinsteiger: 'nein',
  zeitpunkt: {
    id: '',
    zeitpunkt: '',
    zeitpunktDatum: false,
  },
};
