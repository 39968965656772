// NotificationSlice.js

import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, INotification } from 'app/shared/model/notification.model';
import { createAsyncThunk, isPending } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState: EntityState<INotification> & { isProcessing: boolean } = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  isProcessing: false, // Hinzufügen von isProcessing
};

const apiUrl = 'api/notifications';

export const markNotificationAsDone = createAsyncThunk(
  'notifications/mark_as_done',
  async (id: string) => {
    const requestUrl = `${apiUrl}/done/${id}`;
    return await axios.get(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const loadNewNotifications = createAsyncThunk(
  'notifications/load_new_notifications',
  async (id: string) => {
    const requestUrl = `${apiUrl}/load/${id}`;
    return await axios.get(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

const NotificationSlice = createEntitySlice({
  name: 'notification',
  initialState,
  reducers: {
    setProcessing(state, action) {
      state.isProcessing = true;
      state.entity = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(markNotificationAsDone.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = defaultValue;
        state.isProcessing = false;
      })
      .addMatcher(isPending(markNotificationAsDone), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { setProcessing } = NotificationSlice.actions;

export default NotificationSlice.reducer;
