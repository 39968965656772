import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FormGroup, Input, Label } from 'reactstrap';
import { translate } from 'react-jhipster';
import { FlendlyProgressBar } from 'app/modules/register/progressbar';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ArbeitnehmerEigenschaftenAuswahl } from 'app/shared/components/arbeitgeber/arbeitgeberAuswahl';
import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { BerufAuswahlMultiSelect } from 'app/shared/components/berufsauswahl-multiselect';
import { GenericAuswahl } from 'app/shared/components/generic-auswahl';
import FlendlyValidateField from 'app/modules/assets/FlendlyValidateField';
import { createEntityFromInterview } from 'app/shared/reducers/arbeitnehmer.reducer';

import SearchInput from 'app/modules/assets/FlendlyCompanySearchField';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { getAccount } from 'app/shared/reducers/authentication';
import { ZeitpunktAuswahl } from 'app/shared/components/zeitpunkt-auswahl';
import { ArbeitgeberEigenschaften } from 'app/shared/components/arbeitgeber-eigenschaften-auswahl';
import { SprachenAuswahl } from 'app/shared/components/language-auswahl';
import { FuehrerscheinAuswahl } from 'app/shared/components/license-auswahl';
import { ArbeitsortAuswahl } from 'app/shared/components/arbeitsort-auswahl';
import { MontageAuswahl } from 'app/shared/components/montage-auswahl';
import { GehaltAuswahl } from 'app/shared/components/gehalt-auswahl';
import { MontageArbeitnehmer } from 'app/shared/model/enumerations/montage.model';

// Define your steps as an array of strings
const steps = [
  'AnredeNameVorname',
  'Beruf',
  // 'BlockedUnternehmen',
  'ArbeitgeberEigenschaften',
  'ArbeitnehmerEigenschaften',
  'Sprachen',
  'Fuehrerschein',
  'Arbeitsverhaeltnis',
  'Arbeitsort',
  'Montage',
  'Gehalt',
  'Zeitpunkt',
  'Adresse',
];

export const InitialInterviewArbeitnehmer = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.authentication.account);

  // steps
  const [currentStep, setCurrentStep] = useState(0);
  const [totalSteps, seTtotalSteps] = useState(12);

  const goToNextStep = () => {
    if (isCurrentStepValid) {
      setCurrentStep(currentStep + 1);
      window.scrollTo(0, 0);
    } else {
      toast.error(getValidationMessage());
    }
  };

  const goToPreviousStep = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  // entities and consts
  const [interview, setInterview] = useState({
    auswahlAnrede: null,
    name: null,
    vorname: null,
    adresse: null,
    plz: null,
    telefon: null,
    zusatz: '',
    geburtsdatum: null,
    auswahlArbeitnehmerEigenschaften: [],
    auswahlArbeitgeberEigenschaften: [],
    auswahlSprache: [],
    auswahlFuehrerschein: [],
    auswahlVerhaeltnis: [],
    stadt: null,
    auswahlMontage: [],
    gehalt: null,
    auswahlZeitpunkt: null,
    auswahlBeruf: [],
    auswahlArbeitsort: null,
    wunschBeruf: [],
    auswahlBlockedCompanies: [],
  });

  // Funktionen zum Aktualisieren des Arbeitgeber-Entity
  const updateEntity = (field, value) => {
    setInterview({ ...interview, [field]: value });
  };

  function finishInterview() {
    let validationMessage = validateLastStep();

    if (validationMessage == null) {
      const arbeitnehmerData: IArbeitnehmer = {
        userID: account.id,
        name: interview.name,
        vorname: interview.vorname,
        adresse: interview.adresse,
        plz: interview.plz,
        telefon: interview.telefon,
        stadt: interview.stadt,
        zusatz: interview.zusatz,
        geburtsdatum: interview.geburtsdatum,
        auswahlAnrede: interview.auswahlAnrede,
        auswahlArbeitnehmerEigenschaften: interview.auswahlArbeitnehmerEigenschaften,
        auswahlBlockedCompanies: interview.auswahlBlockedCompanies.map(company => company.id),
      };

      const suchkriterienData: ISuchkriterien = {
        userID: account.id,
        auswahlMontage: interview.auswahlMontage,
        auswahlArbeitsverhaeltnis: interview.auswahlVerhaeltnis,
        auswahlEigenschaften: interview.auswahlArbeitgeberEigenschaften,
        auswahlBeruf: interview.auswahlBeruf,
        auswahlSprache: interview.auswahlSprache,
        auswahlLicense: interview.auswahlFuehrerschein,
        arbeitsort: interview.auswahlArbeitsort,
        auswahlGehalt: interview.gehalt,
        auswahlZeitpunkt: interview.auswahlZeitpunkt,
      };

      const interviewData: any = {
        arbeitnehmer: arbeitnehmerData,
        suchkriterien: suchkriterienData,
      };

      dispatch(createEntityFromInterview(interviewData))
        .then(() => {
          dispatch(getAccount());
          navigate('/register/arbeitnehmer/early-bird');
        })
        .catch(error => {
          console.error('Error creating entities:', error);
          toast.error('Error creating entities', error);
        });
    } else {
      toast.error(validationMessage);
    }
  }

  function isUserOlderThan14(geburtsdatum) {
    return new Date(geburtsdatum).getFullYear() < new Date().getFullYear() - 14;
  }

  function validateLastStep() {
    if (
      interview.adresse === null ||
      interview.plz == null ||
      interview.plz.match(/(^\d{5}$)/) == null ||
      interview.stadt === null ||
      interview.telefon === null
    ) {
      return 'Bitte füllen Sie alle Pflichtfelder Ihrer Adresse aus';
    }

    if (interview.geburtsdatum == null) {
      return 'Bitte geben Sie ein Geburtstdatum ein.';
    }
    if (!isUserOlderThan14(interview.geburtsdatum)) {
      return ' Ihr Geburtstdatum muss mindestens 14 Jahre zurückliegen.';
    }

    return null;
  }

  const validateStep = stepNumber => {
    switch (stepNumber) {
      case 'AnredeNameVorname':
        return interview.auswahlAnrede && interview.name !== '' && interview.vorname !== '';
      case 'ArbeitnehmerEigenschaften':
        return interview.auswahlArbeitnehmerEigenschaften.length > 0;
      case 'Beruf':
        return interview.auswahlBeruf.length > 0;
      case 'BlockedUnternehmen':
        return true;
      case 'ArbeitgeberEigenschaften':
        return interview.auswahlArbeitgeberEigenschaften.length > 0;
      case 'Sprachen':
        return interview.auswahlSprache.length > 0;
      case 'Fuehrerschein':
        return interview.auswahlFuehrerschein.length > 0;
      case 'Arbeitsverhaeltnis':
        return interview.auswahlVerhaeltnis.length > 0;
      case 'Arbeitsort':
        return (
          interview.auswahlArbeitsort !== null &&
          ((interview.auswahlArbeitsort['flexibel'] === false && interview.auswahlArbeitsort['arbeitsort'].length === 5) ||
            interview.auswahlArbeitsort.flexibel)
        );
      case 'Montage':
        return interview.auswahlMontage.length > 0;
      case 'Gehalt':
        return interview.gehalt != null ? interview.gehalt !== '' && interview.gehalt.match(/(^\d{2,4}$)/) != null : false;
      case 'Zeitpunkt':
        return (
          interview.auswahlZeitpunkt !== null &&
          ((interview.auswahlZeitpunkt['zeitpunktDatum'] && interview.auswahlZeitpunkt['zeitpunkt'] != null) ||
            !interview.auswahlZeitpunkt['zeitpunktDatum'])
        );
      default:
        return false;
    }
  };

  // Funktionen zum Vor- und Zurückgehen zwischen den Schritten und validieren der Schritte
  const getValidationMessage = () => {
    let validationMessage = '';
    switch (steps[currentStep]) {
      case 'AnredeNameVorname':
        validationMessage = 'Bitte geben Sie eine Anrede sowie Ihren Namen und Vornamen ein';
        return validationMessage;
      case 'Adresse':
        validationMessage = 'Bitte füllen Sie alle Pflichtfelder Ihrer Adresse aus und geben Sie ein Geburtstdatum an';
        return validationMessage;
      case 'ArbeitnehmerEigenschaften':
        validationMessage = 'Bitte wählen Sie mindestens eine Eigenschaft aus';
        return validationMessage;
      case 'Beruf':
        validationMessage = 'Bitte fügen Sie mindestens einen Beruf hinzu';
        return validationMessage;
      case 'BlockedUnternehmen':
        validationMessage = 'Bitte wählen Sie mindestens ein Unternehmen aus';
        return validationMessage;
      case 'ArbeitgeberEigenschaften':
        validationMessage = 'Bitte wählen Sie mindestens eine Eigenschaft aus';
        return validationMessage;
      case 'Sprachen':
        validationMessage = 'Bitte wählen Sie mindestens eine Sprache aus';
        return validationMessage;
      case 'Fuehrerschein':
        validationMessage = 'Bitte wählen Sie mindestens einen Führerschein aus';
        return validationMessage;
      case 'Arbeitsverhaeltnis':
        validationMessage = 'Bitte wählen Sie mindestens ein Arbeitsverhältnis aus';
        return validationMessage;
      case 'Arbeitsort':
        validationMessage = 'Bitte wählen Sie mindestens einen Arbeitsort aus';
        return validationMessage;
      case 'Montage':
        validationMessage = 'Bitte geben Sie ihre Montagebereitschaft an';
        return validationMessage;
      case 'Gehalt':
        validationMessage = 'Bitte geben Sie ein realistisches Wunschgehalt ein.';
        return validationMessage;
      case 'Zeitpunkt':
        validationMessage = 'Bitte wählen Sie mindestens einen Zeitpunkt aus';
        return validationMessage;
      default:
        return null;
    }
  };

  const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);

  useEffect(() => {
    setIsCurrentStepValid(validateStep(steps[currentStep]));
  }, [currentStep, interview]);

  function setBerufsValues(value: any[]) {
    setInterview({ ...interview, auswahlBeruf: value });
    updateEntity('auswahlBeruf', value);
  }

  const renderCurrentStep = () => {
    switch (currentStep) {
      // START ARBEITNEHMER
      case steps.indexOf('AnredeNameVorname'):
        return (
          <div className="div-block-workflow">
            <p className="paragraph-workflow">
              <strong>Wie heißen Sie?</strong>
            </p>
            <div className="padding-10"></div>

            <FormGroup className="form-group-full">
              <Label for="sprachenSelect" className="field-label-std">
                Anrede
              </Label>
              <Input
                className="text-field-45px w-input"
                id="sprachenSelect"
                placeholder="Bitte wählen Sie eine Anrede"
                name="select"
                type="select"
                onChange={e => updateEntity('auswahlAnrede', e.target.value)}
                value={interview.auswahlAnrede}
                style={{
                  color: interview.auswahlAnrede ? 'black' : 'gray',
                }}
              >
                <option value="" disabled selected hidden>
                  Bitte wählen Sie eine Anrede
                </option>
                <option value="Herr">Herr</option>
                <option value="Frau">Frau</option>
              </Input>
            </FormGroup>
            <FlendlyValidateField
              label={translate('flendlyApp.arbeitnehmer.vorname')}
              id="arbeitnehmer-vorname"
              name="vorname"
              data-cy="vorname"
              value={interview.vorname}
              onChange={e => updateEntity('vorname', e.target.value)}
            />
            <FlendlyValidateField
              label="Nachname"
              id="arbeitnehmer-name"
              name="name"
              data-cy="name"
              onChange={e => updateEntity('name', e.target.value)}
              value={interview.name}
            />
          </div>
        );
      case steps.indexOf('Adresse'):
        return (
          <div className="div-block-workflow">
            <p className="paragraph-workflow">
              <strong>Wie lauten Ihre Kontaktdaten?</strong>
            </p>

            <FlendlyValidateField
              label="Straße und Hausnummer"
              id="arbeitnehmer-adresse"
              name="adresse"
              data-cy="adresse"
              onChange={e => updateEntity('adresse', e.target.value)}
              value={interview.adresse}
            />
            <FlendlyValidateField
              label="Adresszusatz (optional)"
              id="arbeitnehmer-zusatz"
              name="zusatz"
              data-cy="zusatz"
              onChange={e => updateEntity('zusatz', e.target.value)}
              value={interview.zusatz}
            />

            <div className="w-layout-grid grid-6">
              <FlendlyValidateField
                wrapperClass={'wrapper-100'}
                labelClass="field-label-std"
                inputClass="text-field-45px w-input"
                label="PLZ"
                id="arbeitgeber-plz"
                name="plz"
                data-cy="plz"
                onChange={value => updateEntity('plz', value.target.value)}
                value={interview.plz}
              />
              <FlendlyValidateField
                wrapperClass={'wrapper-100'}
                labelClass="field-label-std"
                inputClass="text-field-45px w-input"
                label="stadt"
                id="arbeitgeber-stadt"
                name="stadt"
                data-cy="stadt"
                value={interview.stadt}
                onChange={value => updateEntity('stadt', value.target.value)}
              />
            </div>
            <FlendlyValidateField
              label={translate('flendlyApp.arbeitnehmer.telefon')}
              id="arbeitnehmer-telefon"
              name="telefon"
              data-cy="telefon"
              onChange={e => updateEntity('telefon', e.target.value)}
              value={interview.telefon}
            />

            <FlendlyValidateField
              label="Geburtsdatum (mind. 14 Jahre)"
              id="arbeitnehmer-geburtsdatum"
              name="geburtsdatum"
              data-cy="geburtsdatum"
              type="date"
              onChange={e => updateEntity('geburtsdatum', e.target.value)}
              value={interview.geburtsdatum}
            />
          </div>
        );
      case steps.indexOf('ArbeitnehmerEigenschaften'):
        return (
          <div>
            <ArbeitnehmerEigenschaftenAuswahl
              title="Welche Eigenschaften beschreiben Sie am besten? (max. 4 Punkte)"
              maxSelect={4}
              onAuswahlChange={value => updateEntity('auswahlArbeitnehmerEigenschaften', value)}
              initialValue={interview.auswahlArbeitnehmerEigenschaften}
            />
          </div>
        );
      case steps.indexOf('Beruf'):
        // berufe
        return (
          <div>
            <BerufAuswahlMultiSelect
              formEnabled={true}
              onAuswahlChange={value => setBerufsValues(value)}
              initialValue={interview.auswahlBeruf}
            />
          </div>
        );
      case steps.indexOf('BlockedUnternehmen'):
        return (
          <SearchInput
            wrapperClass="wrapper-480"
            onAuswahlChange={value => updateEntity('auswahlBlockedCompanies', value)}
            initialValue={interview.auswahlBlockedCompanies}
          />
        );
      case steps.indexOf('ArbeitgeberEigenschaften'):
        return (
          <div>
            <ArbeitgeberEigenschaften
              title="Was ist Ihnen bei einem Arbeitgeber wichtig? (max. 8 Punkte)"
              onAuswahlChange={value => updateEntity('auswahlArbeitgeberEigenschaften', value)}
              initialValue={interview.auswahlArbeitgeberEigenschaften}
            />
          </div>
        );
      case steps.indexOf('Sprachen'):
        return (
          <div>
            <SprachenAuswahl
              title="Welche Sprache(n) sprechen Sie?"
              onAuswahlChange={value => updateEntity('auswahlSprache', value)}
              initialValue={interview.auswahlSprache}
            />
          </div>
        );
      case steps.indexOf('Fuehrerschein'):
        return (
          <div>
            <FuehrerscheinAuswahl
              title={'Welche Führerscheine besitzen Sie?'}
              onAuswahlChange={value => updateEntity('auswahlFuehrerschein', value)}
              initialValue={interview.auswahlFuehrerschein}
            />
          </div>
        );
      case steps.indexOf('Arbeitsverhaeltnis'):
        return (
          <div>
            <GenericAuswahl
              label="Arbeitszeitmodell"
              minSelect={1}
              maxSelect={4}
              initialValue={interview.auswahlVerhaeltnis}
              onAuswahlChange={value => updateEntity('auswahlVerhaeltnis', value)}
              col={Arbeitsverhaeltnis}
              title={'Wie möchten Sie arbeiten?'}
            />
          </div>
        );
      case steps.indexOf('Arbeitsort'):
        return (
          <div>
            <ArbeitsortAuswahl
              title={'Wo möchten Sie arbeiten?'}
              onAuswahlChange={value => updateEntity('auswahlArbeitsort', value)}
              initialValue={interview.auswahlArbeitsort}
            />
          </div>
        );
      case steps.indexOf('Montage'):
        return (
          <div>
            <MontageAuswahl
              title={'Sind Sie bereit für Montage?'}
              montageAuswahl={Object.values(MontageArbeitnehmer)}
              onAuswahlChange={value => updateEntity('auswahlMontage', value)}
              initialValue={interview.auswahlMontage}
            />
          </div>
        );
      case steps.indexOf('Gehalt'):
        return (
          <GehaltAuswahl
            wrapperClass={'wrapper-480'}
            onAuswahlChange={value => updateEntity('gehalt', value)}
            initialValue={interview.gehalt}
          />
        );
      case steps.indexOf('Zeitpunkt'):
        return (
          <div>
            <ZeitpunktAuswahl
              title={'Ab wann sind Sie verfügbar?'}
              onAuswahlChange={value => updateEntity('auswahlZeitpunkt', value)}
              initialValue={interview.auswahlZeitpunkt}
            />
          </div>
        );
      default:
        return;
    }
  };

  return (
    <div>
      <div className="section-workflow-progress-bar">
        <div className="container-workflow w-container">
          <div className="padding-5"></div>
          <div className="link-block w-inline-block">
            <img
              src="../../../content/images/Logo_plain_letters_cuttet.png"
              loading="lazy"
              width="262"
              sizes="(max-width: 479px) 100vw, 262px"
              alt="Flendly Logo, das als Home Button fungiert. Beim Anklicken des Logos wird man zur Startseite geleitet."
              srcSet="../../../content/images/Logo_plain_letters_cuttet-p-500.png 500w, ../../../content/images/Logo_plain_letters_cuttet-p-800.png 800w, ../../../content/images/Logo_plain_letters_cuttet-p-1080.png 1080w, ../../../content/images/Logo_plain_letters_cuttet-p-1600.png 1600w, ../../../content/images/Logo_plain_letters_cuttet.png 1639w"
            />
          </div>
        </div>

        <FlendlyProgressBar cssClassSteps={'grid-progress-bar-an'} totalSteps={totalSteps} currentStep={currentStep} />
      </div>
      <div className="section-workflow-steps">
        <div className="container-std w-container">
          <div className="w-form">
            <div className="card-edit-body">{renderCurrentStep()}</div>

            <div className="padding-30" />

            <div className={currentStep > 0 ? 'flendly-button-bar' : 'flendly-button-bar-end'}>
              {currentStep > 0 && (
                <button
                  className="button-workflow-forward w-node-c34aae07-d4ae-83b6-6df1-74d77b4e246a-1e4981b7 w-button"
                  onClick={goToPreviousStep}
                  disabled={currentStep === 0}
                >
                  Zurück
                </button>
              )}

              {currentStep === totalSteps - 1 && (
                <button className="call-to-action-max w-button" onClick={finishInterview}>
                  Registrierung abschließen
                </button>
              )}

              {/* Dirty hack, should be refactored */}
              {currentStep < totalSteps - 1 && (
                <div>
                  <div className="d-inline-block" data-toggle="popover" data-content="Disabled popover">
                    <button
                      type="button"
                      id="buttonweiter"
                      className="button-workflow-forward w-button"
                      onClick={goToNextStep}
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                    >
                      Weiter
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialInterviewArbeitnehmer;
