import { useEffect } from 'react';
import { REDIRECT_URL } from 'app/shared/util/url-utils';
import { useLocation } from 'react-router';

// Definieren Sie zuerst eine Schnittstelle für den Standortzustand:
interface LocationState {
  from: {
    pathname: string;
  };
}

export const LoginRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    // Da wir dem 'useLocation' Hook den 'LocationState' Typ gegeben haben,
    // können wir nun sicher auf 'location.state.from.pathname' zugreifen
    if ((location.state as LocationState)?.from?.pathname) {
      localStorage.setItem(REDIRECT_URL, (location.state as LocationState).from.pathname);
    }
    window.location.reload();
  });

  return null;
};

export default LoginRedirect;
