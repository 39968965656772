import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { IBeruf } from 'app/shared/model/beruf.model';
import { Arbeitnehmerskills } from 'app/shared/model/enumerations/arbeitnehmerskills.model';

export interface IArbeitnehmer {
  id?: string;
  userID: string | null;
  name: string | '';
  vorname: string | '';
  status?: string | '';
  auswahlAnrede: string | '';
  adresse: string | '';
  geburtsdatum: string | '';
  zusatz?: string | '';
  plz: string | null;
  stadt: string | '';
  telefon: string | '';
  auswahlArbeitnehmerEigenschaften: string[] | null;
  auswahlBlockedCompanies?: string[] | null;
}

export const defaultValue: Readonly<IArbeitnehmer> = {
  userID: null,
  name: '',
  vorname: '',
  adresse: '',
  zusatz: '',
  geburtsdatum: '',
  auswahlAnrede: '',
  plz: null,
  stadt: '',
  telefon: '',
  auswahlArbeitnehmerEigenschaften: [],
  auswahlBlockedCompanies: [],
};
