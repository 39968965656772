import React, { useEffect } from 'react';

export const Impressum = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section className="section">
        <div className="container-14 w-container">
          <div className="padding-40"></div>
          <h1 className="heading-8">Impressum</h1>
          <p>
            <strong>
              Angaben gemäß § 5 TGM
              <br />
            </strong>
            <br />
            Flendly UG (haftungsbeschränkt)
            <br />
            Schäftlarnstraße 99
            <br />
            81371 München
            <br />
            Deutschland
            <br />
            Amtsgericht München, HRB 290344
            <br />
            <br />
            <strong>Vertreten durch den Geschäftsführer</strong>
            <br />
            Andre Nonnenmacher
            <br />
            <br />
            <strong>Kontakt</strong>
            <br />
            info@flendly.de
            <br />
            <br />
            <strong>Haftungsausschluss (DISCLAIMER)</strong>
            <br />‍<br />
            <strong>Haftung für Inhalte</strong>
            <br />
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
            können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
            nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
            übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die aufxn entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            <br />
            <br />
            <strong>Haftung für Links</strong>
            <br />
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
            diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
            Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
            überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
            verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            <br />
            <br />
            <strong>Urheberrecht</strong>
            <br />
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
            nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
            Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
          </p>
          <div className="padding-30"></div>
        </div>
      </section>
    </div>
  );
};
