import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ValidatedField } from 'react-jhipster';
import { updateUserPassword } from 'app/shared/reducers/authentication';
import { IUser } from 'app/shared/model/user.model';
import { toast } from 'react-toastify';

export const SicherheitArbeitgeber = () => {
  const dispatch = useAppDispatch();

  const account: IUser = useAppSelector(state => state.authentication.account);

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

  function handleSubmit() {
    if (newPassword !== newPasswordRepeat) {
      toast.error('Passwörter stimmen nicht überein');
    } else {
      dispatch(updateUserPassword({ user: account, currentPassword: password, newPassword: newPassword }));
    }
  }

  return (
    <div>
      <div className="section-workflow-steps">
        <div className="container-21 w-container">
          <h1 className="heading-2-left">Sicherheit</h1>
          <p className="paragraph-display">
            <strong>Passwort ändern</strong>
          </p>
          <p>Erstellen Sie ein neues Passwort mit mindestens acht Zeichen.</p>
          <div className="padding-5"></div>
          <div className="form-block-2 w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              data-wf-page-id="650ddce7975549021e4981b2"
              data-wf-element-id="29ae4824-7384-9aa9-a050-d40d81c4e119"
            >
              <label htmlFor="name" className="field-label-std">
                Aktuelles Passwort
              </label>
              <ValidatedField
                onChange={e => setPassword(e.target.value)}
                value={password}
                className="text-field-45px w-input"
                maxLength={256}
                name="name"
                data-name="Name"
                placeholder=""
                type="text"
                id="name"
              />
              <label htmlFor="name-2" className="field-label-std">
                Neues Passwort
              </label>
              <ValidatedField
                onChange={e => setNewPassword(e.target.value)}
                value={newPassword}
                className="text-field-45px w-input"
                maxLength={256}
                name="name-3"
                data-name="Name 3"
                placeholder=""
                type="text"
                id="name-3"
              />
              <label htmlFor="name-2" className="field-label-std">
                Wiederholung neues Passwort
              </label>
              <ValidatedField
                onChange={e => setNewPasswordRepeat(e.target.value)}
                value={newPasswordRepeat}
                className="text-field-45px w-input"
                maxLength={256}
                name="name-2"
                data-name="Name 2"
                placeholder=""
                type="text"
                id="name-2"
              />
              <div className="padding-10"></div>
              <div className="padding-5"></div>
              <div onClick={handleSubmit} data-wait="Please wait..." className="cta-grey-workflow-save w-button">
                Passwort speichern
              </div>
            </form>
          </div>
          <div className="padding-30"></div>
        </div>
      </div>
    </div>
  );
};

export default SicherheitArbeitgeber;
