import React from 'react';

export enum Benefits {
  KANTINE = 'Kantine',
  DIENSTHANDY = 'Diensthandy',
  TEAMEVENTS = 'Teamevents',
  PAUSENRÄUME = 'Pausenräume',
  PARKPLATZ = 'Parkplatz',
  GESCHÄFTSWAGEN = 'Geschäftswagen',
}
