import React from 'react';

export const WelcomeArbeitnehmer = () => {
  return (
    <div className="p-5">
      <div className="notification-pop-ups-copy">
        <h1 className="heading-3">
          Herzlich willkommen bei flendly
          <strong>
            <br />
          </strong>
        </h1>
        <img src="../../../content/images/12101114581637728866.svg" loading="lazy" width="74" alt="" />
        <h1 className="heading-float-3">
          Wir danken Ihnen für Ihr Vertrauen und freuen uns sehr, Sie als Early Bird Kunden begrüßen zu dürfen.
        </h1>
        <div className="heading-float-3">
          Nach unserem Produkt Launch am 26.09.2024 erhalten Sie Zugang zu unserer Jobplattform und profitieren von all unseren Funktionen.
          Bis dahin halten wir Sie über Neuigkeiten auf dem Laufenden.
        </div>
        <div className="padding-10"></div>
        <a href="logout" className="call-to-action-max w-button">
          Bis bald
        </a>
        <div className="padding-10"></div>
      </div>
    </div>
  );
};

export default WelcomeArbeitnehmer;
