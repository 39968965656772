import React, { useEffect } from 'react';

export const Agb = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section className="section">
        <div className="container-15 w-container">
          <div className="padding-40"></div>

          <p className="heading-8">Allgemeine Geschäftsbedingungen (AGB) der Flendly UG (haftungsbeschränkt)</p>

          <p>
            <strong>Geltungsbereich</strong>
            <br />
            Die folgenden Geschäftsbedingungen gelten für die Nutzung der Jobplattform Flendly UG (haftungsbeschränkt) (im Folgenden
            "Plattform" genannt). durch Arbeitnehmer und Arbeitgeber (nachfolgend "Nutzer" genannt). Durch die Nutzung der Plattform erklärt
            sich der Nutzer mit diesen AGB einverstanden.
            <br />
            <br />
            <strong>
              Registrierung
              <br />‍
            </strong>
            Die Registrierung auf der Plattform ist kostenlos. Um die Plattform nutzen zu können, müssen Nutzer sich registrieren und ein
            Benutzerkonto erstellen. Bei der Registrierung müssen Nutzer Ihre persönlichen Daten wahrheitsgemäß angeben und akzeptieren,
            dass die Plattform diese Datenspeichert und verarbeitet. Nutzer sind verantwortlich für die Sicherheit Ihrer Zugangsdaten und
            sollten diese nicht an Dritte weitergeben.
            <br />
            <strong>
              <br />
              Leistungsbeschreibung
              <br />‍
            </strong>
            Die Plattform bietet Arbeitgebern die Möglichkeit, basierend auf deren Personalanforderungen, Arbeitnehmerprofile zu suchen und
            so passenden Arbeitnehmern unverbindliche Jobangebote zu machen. Bei Annahme der Angebotedurch die Arbeitnehmer starte der
            jeweils unternehmenseigene Bewerbungsprozess der Unternehmen. Umgekehrt können Arbeitnehmer basierend auf deren Anforderungen
            Arbeitgeberprofilefinden und diese auf sich aufmerksam machen. Die Plattform stellt die technische Infrastruktur für diese
            Vorgänge zur Verfügung. Die Plattform ist keine Personalvermittlungsagentur und übernimmt keine Verantwortung für die Eignung
            oder Qualität der Bewerber.
            <br />
            <br />
            <strong>
              Gebühren
              <br />‍
            </strong>
            Die Nutzung der Plattform ist für Arbeitnehmer kostenlos.
            <br />
            Für Arbeitgeber entstehen dann Gebühren, wenn ein auf der Plattform ausgesprochenes unverbindliches Angebot an einen
            Arbeitnehmer zu einer anschließenden Anstellung des Arbeitnehmers im Unternehmen des Arbeitgebers führt. Wann diese Anstellung
            nach der Abgabe des unverbindlichen Angebots zustande kommt, ist für die entstehende Gebühr nicht relevant. Die Gebühr beträgt
            immer 499 Euro pro erfolgreichem Angebot, d.h. pro erfolgter Einstellung eines über die Jobplattform vermittelten Arbeitnehmers.
            Treffen Arbeitgeber und Arbeitnehmer, welche über die Jobplattform vermittelt wurden, Absprachen um die Vermittlungsgebühren für
            den Arbeitgeber zu umgehen, wird eine Vertragsstrafe von 3.500€ für den Arbeitgeber fällig. Weiter behält sich die Plattform
            vor, das Kundenkonto sowohl des Arbeitnehmers als auch des Arbeitgebers ggf. zu deaktivieren.
            <br />
            <br />
            <strong>
              Haftungsausschluss
              <br />‍
            </strong>
            Die Plattform haftet nicht für Schäden, die durch die Nutzung der Plattform entstehen, es sei denn, sie wurden vorsätzlich oder
            grobfahrlässig verursacht. Die Plattformübernimmt keine Haftung für die Richtigkeit und Vollständigkeit der auf der Plattform
            veröffentlichten Informationen. Die Plattform haftet nicht für den Ausfall oder die Nichtverfügbarkeit der Plattform. Die
            Plattform übernimmt keine Verantwortung für die Eignung oder Qualität von Arbeitgebern bzw. Arbeitnehmers.
            <br />
            <br />
            <strong>
              Änderungen der Geschäftsbedingungen
              <br />‍
            </strong>
            Die Plattform behält sich das Recht vor, die AGB jederzeit und ohne Vorankündigung zu ändern. Die geänderten AGB werden auf der
            Plattformveröffentlicht und sind ab dem Zeitpunkt der
            <br />
            Veröffentlichung gültig. Der Nutzer ist verpflichtet, die AGB regelmäßig zu überprüfen und sicherzustellen, dass er mit den
            Änderungen einverstanden ist.
            <br />
            <br />
            <strong>
              Preisänderungen
              <br />‍
            </strong>
            Die auf unserer Plattform ausgewiesenen Preise für die Nutzung unserer Dienstleistungen und sonstigen Angebote können sich
            ändern. Wir behalten uns das Recht vor, unsere Preise jederzeit zu adjustieren. Dies betrifft sowohl die Einführung neuer Preise
            für bisher kostenfreie Dienstleistungen als auch die Anpassung bestehender Preise. Preisänderungen können aus verschiedenen
            Gründen erfolgen, einschließlich, aber nicht beschränkt auf Veränderungen im Markt, bei den Kosten, bei gesetzlichen
            Anforderungen, oder um Verbesserungen und Erweiterungen der angebotenen Dienstleistungen zu reflektieren. Kunden werden über
            geplante Preisänderungen mindestens 30 Tage vor dem Inkrafttreten der neuen Preise informiert. Die Benachrichtigung erfolgt über
            die auf der Plattform bereitgestellten Kommunikationskanäle, wie zum Beispiel per E-Mail oder über eine Ankündigung direkt auf
            der Plattform. Kunden, die mit den geänderten Preisen nicht einverstanden sind, haben das Recht, ihre Abonnements oder die
            Nutzung kostenpflichtiger Dienstleistungen vor dem Inkrafttreten der Preisänderungen zu kündigen. Die Fortsetzung der Nutzung
            der Plattform oder der Dienstleistungen nach Inkrafttreten der Preisänderung gilt als Zustimmung zu den neuen Preisen. Wir
            verpflichten uns, die Transparenz und Fairness der Preisgestaltung zu gewährleisten und werden sicherstellen, dass alle
            Preisänderungen klar und verständlich kommuniziert werden.
            <br />
            <br />
            <strong>
              Datenschutz <br />‍
            </strong>
            Die Plattform beachtet die geltenden Datenschutzbestimmungenund erhebt, speichert und verarbeitet personenbezogene Daten nur im
            Rahmendieser Bestimmungen. Der Nutzer erklärt sich damit einverstanden, dass die aufder Plattform angegebenen Daten von
            Unternehmen eingesehen werden können. DerNutzer hat das Recht, jederzeit Auskunft über die zu seiner Person gespeichertenDaten
            zu verlangen und diese gegebenenfalls berichtigen oder löschen zu lassen.
            <br />
            <br />
            <strong>
              Schlussbestimmungen
              <br />‍
            </strong>
            Sollte eine Bestimmung dieser AGB ganz oder teilweiseunwirksam sein oder werden, so bleibt die Wirksamkeit der übrigen
            Bestimmungenunberührt. Es gilt das Recht der Bundesrepublik Deutschland unter Ausschlussdes UN-Kaufrechts. Gerichtsstand für
            alle Streitigkeiten aus oder im Zusammenhang mit diesen AGB ist, soweit gesetzlich zulässig, der Sitz des Betreibers der
            Plattform.
          </p>
          <div className="padding-30"></div>
        </div>
      </section>
    </div>
  );
};
