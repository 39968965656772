import React from 'react';

export const WelcomeArbeitgeber = () => {
  return (
    <div className="p-5">
      <div className="notification-pop-ups-copy">
        <h1 className="heading-3">
          Herzlich willkommen bei flendly
          <strong>
            <br />
          </strong>
        </h1>
        <img src="../../../content/images/12101114581637728866.svg" loading="lazy" width="74" alt="" />
        <h1 className="heading-float-3">
          Wir danken Ihnen für Ihr Vertrauen und freuen uns sehr, Sie als Early Bird Kunden begrüßen zu dürfen.
        </h1>
        <div className="heading-float-3">
          Nach unserem Produkt Launch am 26.09.2024 erhalten Sie Zugang zu unserer Jobplattform und profitieren von Ihrem Early Bird <br />
          <span className="text-span-16">Rabatt von 80%</span>
          <br /> auf Ihre erste Einstellung. Bis dahin halten wir Sie über Neuigkeiten auf dem Laufenden.
        </div>
        <div className="padding-10"></div>
        <a href="/logout" className="call-to-action-max w-button">
          Bis bald
        </a>
        <div className="padding-10"></div>
      </div>
      <div className="w-layout-blockcontainer container-std w-container">
        <img
          src="../../../content/images/Logo_plain_letters_cuttet.png"
          loading="lazy"
          width="171"
          sizes="171px"
          alt="Flendly Logo, das als Home Button fungiert. Beim Anklicken des Logos wird man zur Startseite geleitet."
          srcSet="../../../content/images/Logo_plain_letters_cuttet-p-500.png 500w, ../../../content/images/Logo_plain_letters_cuttet-p-800.png 800w, ../../../content/images/Logo_plain_letters_cuttet-p-1080.png 1080w, ../../../content/images/Logo_plain_letters_cuttet-p-1600.png 1600w, ../../../content/images/Logo_plain_letters_cuttet.png 1639w"
          className="header-logo"
        />
      </div>
    </div>
  );
};

export default WelcomeArbeitgeber;
