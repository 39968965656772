import React, { FunctionComponent, useEffect, useState } from 'react';
import { License } from 'app/shared/model/enumerations/license.model';

interface IFuehrerscheinAuswahlProps {
  title: string;
  onAuswahlChange: Function;
  initialValue?: License[];
}

export const FuehrerscheinAuswahl: FunctionComponent<IFuehrerscheinAuswahlProps> = ({ title, onAuswahlChange, initialValue }) => {
  const [selectLicense, setSelectLicense] = useState([]);

  const licenseValues = Object.keys(License);

  useEffect(() => {
    if (initialValue && initialValue.length > 0) {
      setSelectLicense(initialValue);
      onAuswahlChange(initialValue);
    } else {
      setSelectLicense([]);
      onAuswahlChange([]);
    }
  }, []);

  function handleSelectLicense(license: string) {
    let neueAuswahl = selectLicense.includes(license) ? selectLicense.filter(item => item !== license) : [...selectLicense, license];

    if (license === 'KEINEN') {
      neueAuswahl = neueAuswahl.filter(item => item === 'KEINEN');
      setSelectLicense(['KEINEN']);
    } else {
      neueAuswahl = neueAuswahl.filter(item => item !== 'KEINEN');
      setSelectLicense(neueAuswahl);
    }

    onAuswahlChange(neueAuswahl);
  }

  return (
    <div id="employer_reg_11" className="div-block-workflow">
      <p className="paragraph-workflow">
        <strong>{title} (Mehrfachauswahl möglich)</strong>
      </p>
      <div className="padding-10"></div>

      <div className="field-label-std">
        <strong>Führerscheine</strong>
      </div>

      <div className="w-layout-grid grid-skills-10">
        {licenseValues.map(license => (
          <div
            key={license}
            className={`click-button-medium ${selectLicense.includes(license) ? 'highlighted' : ''}`}
            onClick={() => handleSelectLicense(license)}
          >
            {License[license]}
          </div>
        ))}
      </div>
    </div>
  );
};
