import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs.ts';

import React, { useEffect } from 'react';
import { Card } from 'reactstrap';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import RoleMapperDialog from 'app/modules/register/emailVerify';
import InitialInterviewArbeitnehmer from 'app/modules/register/register-arbeitnehmer';
import Loading from 'app/modules/login/loading';

import NotificationComponent from 'app/modules/notifications/notification-component';
import WelcomeArbeitnehmer from 'app/modules/register/WelcomeArbeitnehmer';
import WelcomeArbeitgeber from 'app/modules/register/WelcomeArbeitgeber';
import { InitialInterviewArbeitgeber } from 'app/modules/register/register-arbeitgeber';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const dispatch = useAppDispatch();
  const hasRoleAssigned = useAppSelector(state => state.authentication.hasRoleAssigned);
  const account = useAppSelector(state => state.authentication.account);
  const isInRegistrationFlow = useAppSelector(state => state.authentication.isInRegistrationFlow);

  const isEarlyBird = true;

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
    account.isInRegistrationFlow;
  }, [hasRoleAssigned, isInRegistrationFlow]);

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  const isAngestellter = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ANGESTELLTER]));
  const isArbeitgeber = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ARBEITGEBER]));

  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const loading = useAppSelector(state => state.authentication.loading);

  // Option for Role Mapper Dialog
  if (isAuthenticated && !hasRoleAssigned) {
    return (
      <BrowserRouter basename={baseHref}>
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        <RoleMapperDialog />
      </BrowserRouter>
    );
  }
  // Option for Registration Flow
  else if (isAuthenticated && isInRegistrationFlow) {
    if (isArbeitgeber) {
      return (
        <BrowserRouter basename={baseHref}>
          <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
          <InitialInterviewArbeitgeber />
        </BrowserRouter>
      );
    } else if (isAngestellter) {
      return (
        <BrowserRouter basename={baseHref}>
          <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
          <InitialInterviewArbeitnehmer />
        </BrowserRouter>
      );
    }
  }

  /*EARLY BIRD SWITCH*/
  if (isAuthenticated && isEarlyBird) {
    if (isArbeitgeber) {
      return (
        <BrowserRouter basename={baseHref}>
          <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
          <WelcomeArbeitgeber />
        </BrowserRouter>
      );
    } else if (isAngestellter) {
      return (
        <BrowserRouter basename={baseHref}>
          <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
          <WelcomeArbeitnehmer />
        </BrowserRouter>
      );
    }
  }

  // Option for Loading
  if (loading) {
    return (
      <BrowserRouter basename={baseHref}>
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        <Loading />
      </BrowserRouter>
    );
  }

  // Option for normal App
  return (
    <BrowserRouter basename={baseHref}>
      <div className="app-container padding">
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        <ErrorBoundary>
          <Header
            isInRegistrationFlow={isInRegistrationFlow}
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
            isAngestellter={isAngestellter}
            isArbeitgeber={isArbeitgeber}
            currentLocale={currentLocale}
            ribbonEnv={ribbonEnv}
            isInProduction={isInProduction}
            isOpenAPIEnabled={isOpenAPIEnabled}
          />
        </ErrorBoundary>

        <NotificationComponent />

        <div className="container-fluid view-container" id="app-view-container">
          <Card className="jh-card">
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
          </Card>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
