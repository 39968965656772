import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { Berufe } from 'app/shared/model/enumerations/berufe.model';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';

export interface IBeruf {
  id?: string;
  userID: string;
  auswahlBeruf: Berufe | [];
  auswahlAusbildungsstand: Ausbildungsstand[] | [];
  auswahlBerufserfahrung: Berufserfahrung[] | [];
  zusatzqualifikationen?: string | null;
}

export const defaultValue: Readonly<IBeruf> = {
  id: '',
  userID: '',
  auswahlBeruf: [],
  auswahlAusbildungsstand: [],
  auswahlBerufserfahrung: [],
  zusatzqualifikationen: '',
};
